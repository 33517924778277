import axios from "axios"

class AuthService {
  api
  constructor() {
    this.api = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}`,
    })
    this.api.interceptors.request.use((config) => {
      const token = localStorage.getItem("Token")

      if (token) {
        config.headers = {
          Authorization: ` Token ${token}`,
          withCredentials: false,
          "Content-Type": "application/json; charset=UTF-8",
        }
      }

      return config
    })
  }

  login = (user) => {
    return this.api.post("/auth/token-admin-auth/", user)
  }
  

  verify = (token) => {
    return this.api.post("/auth/token-verify/", { Token: token })
  }
}

const authService = new AuthService()

export default authService
