import { Card, Button, Dropdown, Badge } from "react-bootstrap"
import { Link, useNavigate } from "react-router-dom"
import {
  Icon,
  CustomDropdownToggle,
  CustomDropdownMenu,
  LinkList,
} from "../../../components"
import Layout from "../../../layout/default"
import Block from "../../../components/Block/Block"
import DataTable from "../../../components/DataTable/DataTable.js"
import React, { useEffect, useState } from "react"
import campaignService from "../services/campaignService"

const CampaignList = () => {
  const navigate = useNavigate()
  const [campaignData, setCampaign] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    fetchCampaignList()
  }, [])

  const fetchCampaignList = async () => {
    try {
      setLoading(true)
      const response = await campaignService.getCampaignListCrm()
      setCampaign(response)
    } catch (error) {
      console.log("Error fetching campaign list:", error)
    } finally {
      setLoading(false)
    }
  }

  const handleDeleteCampaign = async (campaignId) => {
    try {
      setLoading(true)
      await campaignService.deleteCampaign(campaignId)
      const data = campaignData.filter((rows) => rows.id != campaignId)
      setCampaign(data)
      setLoading(false)
    } catch (error) {
      console.log("Error deleting campaign:", error)
      setLoading(false)
    }
  }

  const handleCreateCampaign = () => {
    navigate("/campaign/new")
  }

  const campaignColumns = [
    {
      name: "id",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Abogado",
      selector: (row) => row?.lawyer?.business_name,
      sortable: true,
      cell: (row) => row?.lawyer?.business_name,
    },
    {
      name: "ID Abogado",
      selector: (row) => row?.lawyer?.id,
      sortable: true,
      cell: (row) => row?.lawyer?.id,
    },
    {
      name: "specialty",
      selector: (row) => row?.specialty?.child_specialty,
      sortable: true,
      cell: (row) => row?.specialty?.child_specialty,
    },
    {
      name: "province",
      selector: (row) => row?.province?.name || "Todo España",
      sortable: true,
      cell: (row) => row?.province?.name || "Todo España",
    },

    {
      name: "Leads estimados",
      selector: (row) => row?.lead_amount,
      sortable: true,
      cell: (row) => row?.lead_amount,
    },
    {
      name: "Leads asignados",
      selector: (row) => row?.assigned_leads,
      sortable: true,
      cell: (row) => row?.assigned_leads,
    },
    {
      name: "Leads pendientes",
      selector: (row) => row?.remaining_leads,
      sortable: true,
      cell: (row) => row?.remaining_leads,
    },
    {
      name: "Presupuesto mensual",
      selector: (row) => row?.total_price,
      sortable: true,
      cell: (row) => row?.total_price,
    },
    {
      name: "Presupuesto consumido",
      selector: (row) => row?.consumed_budget,
      sortable: true,
      cell: (row) => row?.consumed_budget,
    },
    {
      name: "Presupuesto disponible",
      selector: (row) => row?.remaining_budget,
      sortable: true,
      cell: (row) => row?.remaining_budget,
    },
    {
      name: "Leads exclusivos",
      sortable: true,
      selector: (row) => {
        if (row?.unique_leads) {
          return <Badge bg="success">Yes</Badge>
        } else {
          return <Badge bg="warning">No</Badge>
        }
      },
    },
    {
      name: "Inicio",
      selector: (row) => row.start_date,
      sortable: true,
    },
    {
      name: "Proxima facturación",
      selector: (row) => row.next_billing_period,
      sortable: true,
    },
    {
      name: "Estado",
      selector: (row) => {
        if (row.enable) {
          return <Badge bg="success">Activa</Badge>
        } else {
          return <Badge bg="warning">Inactiva</Badge>
        }
      },
      sortable: true,
    },
    {
      name: "actions",
      cell: (row) => (
        <div className="text-end w-100">
          <Dropdown>
            <Dropdown.Toggle
              size="sm"
              as={CustomDropdownToggle}
              className="btn btn-sm btn-icon btn-zoom me-n1"
            >
              <Icon name="more-v" />
            </Dropdown.Toggle>
            <Dropdown.Menu
              className="dropdown-menu-sm"
              as={CustomDropdownMenu}
              align="end"
            >
              <div className="dropdown-content py-1">
                <LinkList className="link-list-hover-bg-primary link-list-md">
                  <Link
                    to={`/campaign/edit/${row.id}`}
                    className="dropdown-item"
                  >
                    <Icon name="edit" />
                    <span>Edit</span>
                  </Link>
                  <button
                    className="dropdown-item"
                    onClick={() => handleDeleteCampaign(row.id)}
                  >
                    <Icon name="trash" />
                    <span>Delete</span>
                  </button>
                </LinkList>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ),
      sortable: false,
    },
  ]

  return (
    <Layout title="Campañas" content="container" loading={loading}>
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            <Block.Title tag="h2">Campañas</Block.Title>
            <nav>
              <ol className="breadcrumb breadcrumb-arrow mb-0">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Campañas
                </li>
              </ol>
            </nav>
          </Block.HeadContent>
          <Block.HeadContent>
            <ul className="d-flex">
              <li>
                <Button
                  className="d-md-none"
                  size="md"
                  variant="primary"
                  onClick={handleCreateCampaign}
                >
                  <Icon name="plus" />
                  <span>Añadir</span>
                </Button>
              </li>
              <li>
                <Button
                  className="d-none d-md-inline-flex"
                  variant="primary"
                  onClick={handleCreateCampaign}
                >
                  <Icon name="plus" />
                  <span>Añadir Campaña</span>
                </Button>
              </li>
            </ul>
          </Block.HeadContent>
        </Block.HeadBetween>
      </Block.Head>

      <Block>
        <Card>
          {!loading && (
            <DataTable
              tableClassName="data-table-head-light table-responsive"
              data={campaignData}
              columns={campaignColumns}
              expandableRows
              actions
              searchByList={[
                "id",
                ["specialty", "child_specialty", []],
                ["province", "name", []],
                ["lawyer", "business_name", []],
              ]}
            />
          )}
        </Card>
      </Block>
    </Layout>
  )
}

export default CampaignList
