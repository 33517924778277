import { Card, Button, Dropdown } from "react-bootstrap"
import { Link, useNavigate } from "react-router-dom"
import {
  Icon,
  CustomDropdownToggle,
  CustomDropdownMenu,
  LinkList,
} from "../../../components"
import Layout from "../../../layout/default"
import Block from "../../../components/Block/Block"
import DataTable from "../../../components/DataTable/DataTable.js"

import React, { useEffect, useState } from "react"
import lawyerSpecialtyService from "../services/lawyer_specialtyService"

const Lawyer_specialtyList = () => {
  const navigate = useNavigate()
  const [lawyer_specialtyData, setLawyer_specialty] = useState([])
  const [loading, setLoading] = useState(false)
  const [lawyerData, setLawyer] = useState([])
  const [specialtyData, setSpecialty] = useState([])

  useEffect(() => {
    setLoading(true)
    fetchLawyer_specialtyList()
    fetchLawyerList()
    fetchSpecialtyList()
  }, [])

  const fetchLawyerList = async () => {
    try {
      const lawyer = await lawyerSpecialtyService.getLawyerList()
      setLawyer(lawyer)
    } catch (error) {
      console.log("Error fetching lawyer list:", error)
    }
  }

  const fetchSpecialtyList = async () => {
    try {
      const specialty = await lawyerSpecialtyService.getSpecialtyList()
      setSpecialty(specialty)
    } catch (error) {
      console.log("Error fetching specialty list:", error)
    }
  }

  const fetchLawyer_specialtyList = async () => {
    try {
      const response = await lawyerSpecialtyService.getLawyer_specialtyList()
      setLawyer_specialty(response)
      setLoading(false)
    } catch (error) {
      console.log("Error fetching lawyer_specialty list:", error)
      setLoading(false)
    }
  }

  const handleDeleteLawyer_specialty = async (lawyer_specialtyId) => {
    try {
      setLoading(true)
      await lawyerSpecialtyService.deleteLawyer_specialty(lawyer_specialtyId)
      const data = lawyer_specialtyData.filter(
        (rows) => rows.id != lawyer_specialtyId
      )
      setLawyer_specialty(data)
      setLoading(false)
    } catch (error) {
      console.log("Error deleting lawyer_specialty:", error)
      setLoading(false)
    }
  }

  const handleCreateLawyer_specialty = () => {
    navigate("/lawyer_specialty/new")
  }

  const lawyer_specialtyColumns = [
    {
      name: "id",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "lawyer",
      selector: (row) => {
        const lawyer = lawyerData.find((lawyer) => lawyer.id === row.lawyer)
        return lawyer ? lawyer.email : ""
      },
      sortable: true,
      cell: (row) => {
        const lawyer = lawyerData.find((lawyer) => lawyer.id === row.lawyer)
        return lawyer ? lawyer.email : ""
      },
    },
    {
      name: "specialty",
      selector: (row) => {
        const specialty = specialtyData.find(
          (specialty) => specialty.id === row.specialty
        )
        return specialty ? specialty.child_specialty : ""
      },
      sortable: true,
      cell: (row) => {
        const specialty = specialtyData.find(
          (specialty) => specialty.id === row.specialty
        )
        return specialty ? specialty.child_specialty : ""
      },
    },
    {
      name: "actions",
      cell: (row) => (
        <div className="text-end w-100">
          <Dropdown>
            <Dropdown.Toggle
              size="sm"
              as={CustomDropdownToggle}
              className="btn btn-sm btn-icon btn-zoom me-n1"
            >
              <Icon name="more-v" />
            </Dropdown.Toggle>
            <Dropdown.Menu
              className="dropdown-menu-sm"
              as={CustomDropdownMenu}
              align="end"
            >
              <div className="dropdown-content py-1">
                <LinkList className="link-list-hover-bg-primary link-list-md">
                  <Link
                    to={`/lawyer_specialty/edit/${row.id}`}
                    className="dropdown-item"
                  >
                    <Icon name="edit" />
                    <span>Edit</span>
                  </Link>
                  <button
                    className="dropdown-item"
                    onClick={() => handleDeleteLawyer_specialty(row.id)}
                  >
                    <Icon name="trash" />
                    <span>Delete</span>
                  </button>
                </LinkList>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ),
      sortable: false,
    },
  ]

  return (
    <Layout title="Lawyer_specialty List" content="container">
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            <Block.Title tag="h2">Lawyer_specialty List</Block.Title>
            <nav>
              <ol className="breadcrumb breadcrumb-arrow mb-0">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Lawyer_specialty
                </li>
              </ol>
            </nav>
          </Block.HeadContent>
          <Block.HeadContent>
            <ul className="d-flex">
              <li>
                <Button
                  className="d-md-none"
                  size="md"
                  variant="primary"
                  onClick={handleCreateLawyer_specialty}
                >
                  <Icon name="plus" />
                  <span>Add</span>
                </Button>
              </li>
              <li>
                <Button
                  className="d-none d-md-inline-flex"
                  variant="primary"
                  onClick={handleCreateLawyer_specialty}
                >
                  <Icon name="plus" />
                  <span>Add Lawyer_specialty</span>
                </Button>
              </li>
            </ul>
          </Block.HeadContent>
        </Block.HeadBetween>
      </Block.Head>

      <Block>
        <Card>
          {!loading ? (
            <DataTable
              tableClassName="data-table-head-light table-responsive"
              data={lawyer_specialtyData}
              columns={lawyer_specialtyColumns}
              expandableRows
              actions
            />
          ) : (
            <p>Loading...</p>
          )}
        </Card>
      </Block>
    </Layout>
  )
}

export default Lawyer_specialtyList
