import { useState, useEffect } from "react"
import { Card, Button, Row, Col, Form } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import DatePicker from "../../../components/Form/DatePicker"
import postsPlanService from "../services/vip_planService"
import Layout from "../../../layout/default"
import Block from "../../../components/Block/Block"

function Vip_planForm() {
  const navigate = useNavigate()
  const { id } = useParams()

  const [vip_planData, setVip_planData] = useState({
    name: "",
    lastname: "",
  })

  useEffect(() => {
    if (id) {
      fetchVip_plan()
    }
  }, [id])

  const fetchVip_plan = async () => {
    try {
      const vip_plan = await postsPlanService.getVip_planDetails(id)
      setVip_planData(vip_plan)
    } catch (error) {
      console.log("Error fetching vip_plan:", error)
    }
  }

  const handleChange = (event) => {
    setVip_planData({
      ...vip_planData,
      [event.target.name]: event.target.value,
    })
  }

  const handleCreateOrUpdateVip_plan = async (event) => {
    event.preventDefault()

    try {
      if (id) {
        await postsPlanService.updateVip_plan(id, vip_planData)
      } else {
        await postsPlanService.createVip_plan(vip_planData)
      }
      navigate("/vip_plan")
    } catch (error) {
      console.log("Error creating/updating vip_plan:", error)
    }
  }

  const pageTitle = id ? "Edit Vip_plan" : "Add Vip_plan"
  const submitButtonText = id ? "Update" : "Save"

  return (
    <Layout title={pageTitle} content="container">
      <Block>
        <Card className="card-gutter-md">
          <Card.Body>
            <div className="bio-block">
              <h4 className="bio-block-title mb-4">{pageTitle}</h4>
              <Form onSubmit={handleCreateOrUpdateVip_plan}>
                <Row className="g-3">
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="billing_period">
                        billing_period
                      </Form.Label>
                      <div className="form-control-wrap">
                        <Form.Select
                          id="billing_period"
                          name="billing_period"
                          value={vip_planData.billing_period}
                          onChange={handleChange}
                          className="custom-select"
                        >
                          <option value="">Select billing_period</option>
                          <option value="anual">anual</option>
                          <option value="monthly">monthly</option>
                        </Form.Select>
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="price">price</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="price"
                          placeholder="price"
                          name="price"
                          value={vip_planData.price}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Button variant="primary" type="submit">
                      {submitButtonText}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Card.Body>
        </Card>
      </Block>
    </Layout>
  )
}

export default Vip_planForm
