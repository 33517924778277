import axios from "axios"

class InvoiceService {
  api
  constructor() {
    this.api = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}`,
    })
    this.api.interceptors.request.use((config) => {
      const token = localStorage.getItem("Token")

      if (token) {
        config.headers = {
          Authorization: `Token ${token}`,
          withCredentials: false,
          "Content-Type": "application/json; charset=UTF-8",
        }
      }

      return config
    })
  }
  getInvoiceList = async () => {
    try {
      const response = await this.api.get(`/invoice/`)
      return response.data
    } catch (error) {
      throw error
    }
  }

  getInvoiceDetails = async (invoiceId) => {
    try {
      const response = await this.api.get(`/invoice/${invoiceId}/`)
      return response.data
    } catch (error) {
      throw error
    }
  }

  createInvoice = async (invoiceData) => {
    try {
      const response = await this.api.post(`/invoice/`, invoiceData)
      return response.data
    } catch (error) {
      throw error
    }
  }

  updateInvoice = async (invoiceId, invoiceData) => {
    try {
      const response = await this.api.put(`/invoice/${invoiceId}/`, invoiceData)
      return response.data
    } catch (error) {
      throw error
    }
  }

  deleteInvoice = async (invoiceId) => {
    try {
      const response = await this.api.delete(`/invoice/${invoiceId}/`)
      return response.data
    } catch (error) {
      throw error
    }
  }

  getUserList = async () => {
    try {
      const response = await this.api.get("/user/")
      return response.data
    } catch (error) {
      throw error
    }
  }
}

const invoiceService = new InvoiceService()

export default invoiceService
