import axios from "axios"

class LawyerLanguageService {
  api
  constructor() {
    this.api = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}`,
    })
    this.api.interceptors.request.use((config) => {
      const token = localStorage.getItem("Token")

      if (token) {
        config.headers = {
          Authorization: `Token ${token}`,
        }
      }

      return config
    })
  }
  getLawyer_languagesList = async () => {
    try {
      const response = await this.api.get(`/lawyer-languages/`)
      return response.data
    } catch (error) {
      throw error
    }
  }

  getLawyer_languagesDetails = async (lawyer_languagesId) => {
    try {
      const response = await this.api.get(
        `/lawyer-languages/${lawyer_languagesId}/`
      )
      return response.data
    } catch (error) {
      throw error
    }
  }

  createLawyer_languages = async (lawyer_languagesData) => {
    try {
      const response = await this.api.post(
        `/lawyer-languages/`,
        lawyer_languagesData
      )
      return response.data
    } catch (error) {
      throw error
    }
  }

  updateLawyer_languages = async (lawyer_languagesId, lawyer_languagesData) => {
    try {
      const response = await this.api.put(
        `/lawyer-languages/${lawyer_languagesId}/`,
        lawyer_languagesData
      )
      return response.data
    } catch (error) {
      throw error
    }
  }

  deleteLawyer_languages = async (lawyer_languagesId) => {
    try {
      const response = await this.api.delete(
        `/lawyer-languages/${lawyer_languagesId}/`
      )
      return response.data
    } catch (error) {
      throw error
    }
  }
  getLanguageList = async () => {
    try {
      const response = await this.api.get("/language/")
      return response.data
    } catch (error) {
      throw error
    }
  }

  getLawyerList = async () => {
    try {
      const response = await this.api.get("/lawyer/")
      return response.data
    } catch (error) {
      throw error
    }
  }
}

const lawyerLanguageService = new LawyerLanguageService()

export default lawyerLanguageService
