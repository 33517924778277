import { Card, Button, Dropdown, Badge } from "react-bootstrap"
import { Link, useNavigate } from "react-router-dom"
import {
  Icon,
  CustomDropdownToggle,
  CustomDropdownMenu,
  LinkList,
} from "../../../components"
import Layout from "../../../layout/default"
import Block from "../../../components/Block/Block"
import DataTable from "../../../components/DataTable/DataTable.js"

import React, { useEffect, useState } from "react"
import userService from "../services/userService"

const UserList = () => {
  const navigate = useNavigate()
  const [userData, setUser] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    fetchUserList()
  }, [])

  const fetchUserList = async () => {
    try {
      const response = await userService.getUserList()
      setUser(response)
      setLoading(false)
    } catch (error) {
      console.log("Error fetching user list:", error)
      setLoading(false)
    }
  }

  const handleDeleteUser = async (userId) => {
    try {
      setLoading(true)
      await userService.deleteUser(userId)
      const data = userData.filter((rows) => rows.id != userId)
      setUser(data)
      setLoading(false)
    } catch (error) {
      console.log("Error deleting user:", error)
      setLoading(false)
    }
  }

  const handleCreateUser = () => {
    navigate("/user/new")
  }

  const userColumns = [
    {
      name: "id",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "last_name",
      selector: (row) => row.last_name,
      sortable: true,
    },
    {
      name: "role",
      selector: (row) => {
        if (row.role === "admin") {
          return <Badge bg="success">Admin</Badge>
        } else {
          return <Badge bg="warning">Lawyer</Badge>
        }
      },
      sortable: true,
    },
    {
      name: "email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "phone_number",
      selector: (row) => row.phone_number,
      sortable: true,
    },
    {
      name: "stripe_customer_id",
      selector: (row) => row.stripe_customer_id,
      sortable: true,
    },
    {
      name: "actions",
      cell: (row) => (
        <div className="text-end w-100">
          <Dropdown>
            <Dropdown.Toggle
              size="sm"
              as={CustomDropdownToggle}
              className="btn btn-sm btn-icon btn-zoom me-n1"
            >
              <Icon name="more-v" />
            </Dropdown.Toggle>
            <Dropdown.Menu
              className="dropdown-menu-sm"
              as={CustomDropdownMenu}
              align="end"
            >
              <div className="dropdown-content py-1">
                <LinkList className="link-list-hover-bg-primary link-list-md">
                  <Link to={`/user/edit/${row.id}`} className="dropdown-item">
                    <Icon name="edit" />
                    <span>Edit</span>
                  </Link>
                  <button
                    className="dropdown-item"
                    onClick={() => handleDeleteUser(row.id)}
                  >
                    <Icon name="trash" />
                    <span>Delete</span>
                  </button>
                </LinkList>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ),
      sortable: false,
    },
  ]

  return (
    <Layout title="Listado de usuarios" content="container" loading={loading}>
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            <Block.Title tag="h2">Usuarios</Block.Title>
            <nav>
              <ol className="breadcrumb breadcrumb-arrow mb-0">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Usuario
                </li>
              </ol>
            </nav>
          </Block.HeadContent>
          <Block.HeadContent>
            <ul className="d-flex">
              <li>
                <Button
                  className="d-md-none"
                  size="md"
                  variant="primary"
                  onClick={handleCreateUser}
                >
                  <Icon name="plus" />
                  <span>Añadir</span>
                </Button>
              </li>
              <li>
                <Button
                  className="d-none d-md-inline-flex"
                  variant="primary"
                  onClick={handleCreateUser}
                >
                  <Icon name="plus" />
                  <span>Añadir usuario</span>
                </Button>
              </li>
            </ul>
          </Block.HeadContent>
        </Block.HeadBetween>
      </Block.Head>

      <Block>
        <Card>
          {!loading && (
            <DataTable
              tableClassName="data-table-head-light table-responsive"
              data={userData}
              columns={userColumns}
              expandableRows
              actions
              searchByList={["name", "last_name", "email"]}
            />
          )}
        </Card>
      </Block>
    </Layout>
  )
}

export default UserList
