import { useState, useEffect } from "react"
import { Card, Button, Row, Col, Form } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import campaignService from "../services/campaignService"
import Layout from "../../../layout/default"
import Block from "../../../components/Block/Block"
import lawyerService from "../../lawyer/services/lawyerService"
import cost_per_leadListService from "../../cost_per_lead/services/cost_per_leadService"

function CampaignForm() {
  const navigate = useNavigate()
  const { id } = useParams()
  const [campaignData, setCampaignData] = useState({
    cpl: 0,
  })
  const [SpecialtyList, setSpecialtyList] = useState([])
  const [ProvinceList, setProvinceList] = useState([])
  const [LawyerList, setLawyerList] = useState([])
  const [CplList, setCplList] = useState([])
  const isSpecialtyUndefined = campaignData.specialty === undefined
  const isProvinceUndefined = campaignData.province === undefined
  const isBothFieldsSelected =
    (!isSpecialtyUndefined && !isProvinceUndefined) ||
    (!isSpecialtyUndefined && campaignData.is_countrywide)
  const [loading, setLoading] = useState(false)
  const [initialLoading, setInitialLoading] = useState(false)

  useEffect(() => {
    fetchAll()
  }, [id])

  const fetchAll = async () => {
    try {
      setInitialLoading(true)
      Promise.all([
        id && fetchCampaign(),
        fetchSpecialty(),
        fetchProvince(),
        fetchLawyer(),
      ])
    } catch (error) {
      console.error(error)
    } finally {
      setInitialLoading(false)
    }
  }

  useEffect(() => {
    if (
      campaignData.specialty !== undefined &&
      campaignData.province !== undefined
    ) {
      findCpl(campaignData.specialty, campaignData.province)
    }
  }, [campaignData.specialty, campaignData.province])
  useEffect(() => {
    if (
      campaignData.specialty !== undefined &&
      campaignData.is_countrywide &&
      !campaignData.province
    ) {
      findCpl(campaignData.specialty, 0, true)
    }
  }, [campaignData.specialty, campaignData.is_countrywide])

  const fetchCampaign = async () => {
    try {
      const campaign = await campaignService.getCampaignDetails(id)
      setCampaignData(campaign)
    } catch (error) {
      console.log("Error fetching campaign:", error)
    }
  }

  const fetchSpecialty = async () => {
    try {
      const dataSpecialty = await campaignService.getSpecialtyList()
      setSpecialtyList(dataSpecialty)
    } catch (error) {
      console.error("Error fetching Specialty:", error)
    }
  }

  const fetchProvince = async () => {
    try {
      const dataProvince = await campaignService.getProvinceList()
      setProvinceList(dataProvince)
    } catch (error) {
      console.error("Error fetching Province:", error)
    }
  }

  const fetchLawyer = async () => {
    try {
      const response = await lawyerService.getRealLawyerList()
      setLawyerList(response)
    } catch (error) {
      console.log("Error fetching list:", error)
    }
  }

  const findCpl = async (specialty_id, province_id, isAllCountry = false) => {
    try {
      let response
      if (isAllCountry) {
        response = await cost_per_leadListService.getCostPerLeadAllCountry(
          specialty_id
        )
      } else {
        response = await cost_per_leadListService.findCost_per_leadList(
          specialty_id,
          province_id
        )
      }
      setCampaignData((prev) => ({ ...prev, cpl: response.id }))
      setCplList([response])
    } catch (error) {
      console.log("Error fetching list:", error)
    }
  }

  const handleChange = (event) => {
    if (event?.target?.name === "lead_amount") {
      setCampaignData({
        ...campaignData,
        [event.target.name]: event.target.value,
        ["total_price"]: event.target.value * CplList[0]?.cpl,
      })
    } else {
      setCampaignData({
        ...campaignData,
        [event.target.name]: event.target.value,
      })
    }
  }

  const handleCheck = (event) => {
    setCampaignData({
      ...campaignData,
      [event.target.name]: event.target.checked,
    })
  }

  const handleCreateOrUpdateCampaign = async (event) => {
    event.preventDefault()

    try {
      setLoading(true)
      if (id) {
        await campaignService.updateCampaign(id, campaignData)
      } else {
        await campaignService.createCampaign(campaignData)
      }
      navigate("/campaign")
    } catch (error) {
      console.log("Error creating/updating campaign:", error)
    } finally {
      setLoading(false)
    }
  }

  const pageTitle = id ? "Editar Campaña" : "Añadir Campaña"
  const submitButtonText = id ? "Actualizar" : "Guardar"

  return (
    <Layout title={pageTitle} content="container" loading={initialLoading}>
      <Block>
        <Card className="card-gutter-md">
          <Card.Body>
            <div className="bio-block">
              <h4 className="bio-block-title mb-4">{pageTitle}</h4>
              <Form onSubmit={handleCreateOrUpdateCampaign}>
                <Row className="g-3">
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="Specialty">
                        Subespecialidad
                      </Form.Label>
                      <div className="form-control-wrap">
                        <Form.Select
                          id="specialty"
                          name="specialty"
                          value={campaignData.specialty}
                          onChange={handleChange}
                          className="custom-select"
                          required
                        >
                          <option value="">Selecciona Subespecialidad</option>
                          {SpecialtyList?.map((specialty, idx) => {
                            return (
                              <option key={idx} value={specialty.id}>
                                {specialty.child_specialty}
                              </option>
                            )
                          })}
                        </Form.Select>
                      </div>
                    </Form.Group>
                  </Col>

                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="Province">Provincia</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Select
                          id="province"
                          name="province"
                          value={campaignData.province}
                          onChange={handleChange}
                          className="custom-select"
                          disabled={campaignData.is_countrywide}
                        >
                          <option value="">Selecciona Provincia</option>
                          {ProvinceList?.map((province, idx) => {
                            return (
                              <option key={idx} value={province.id}>
                                {province.name}
                              </option>
                            )
                          })}
                        </Form.Select>
                      </div>
                    </Form.Group>
                  </Col>

                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Check
                        type="checkbox"
                        id="is_countrywide"
                        name="is_countrywide"
                        value={campaignData.is_countrywide}
                        onChange={(e) => {
                          handleCheck(e)
                          if (e.target.checked && campaignData.province) {
                            setCampaignData((prev) => {
                              const { province, ...rest } = prev
                              return rest
                            })
                          }
                        }}
                        label="Toda España"
                      />
                    </Form.Group>
                  </Col>

                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="Province">Abogado</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Select
                          id="lawyer"
                          name="lawyer"
                          value={campaignData.lawyer}
                          onChange={handleChange}
                          className="custom-select"
                          required
                        >
                          <option value="">Selecciona Abogado</option>
                          {LawyerList?.map((l, idx) => {
                            return (
                              <option key={idx} value={l.id}>
                                {l.id}, {l.name}, {l.last_name}, {l.email}
                              </option>
                            )
                          })}
                        </Form.Select>
                      </div>
                    </Form.Group>
                  </Col>

                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="email">Numero de leads</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="number"
                          id="lead_amount"
                          placeholder="lead_amount"
                          name="lead_amount"
                          value={campaignData.lead_amount}
                          onChange={handleChange}
                          disabled={!isBothFieldsSelected}
                          required
                        />
                      </div>
                    </Form.Group>
                  </Col>

                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="Province">Cpl</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Select
                          id="cpl"
                          name="cpl"
                          value={campaignData.cpl}
                          onChange={handleChange}
                          className="custom-select"
                          disabled
                        >
                          <option value="">Selecciona Cpl</option>
                          {CplList?.map((c, idx) => {
                            return (
                              <option key={idx} value={c.id}>
                                {c.cpl}
                              </option>
                            )
                          })}
                        </Form.Select>
                      </div>
                    </Form.Group>
                  </Col>

                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="email">Coste</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="number"
                          id="total_price"
                          placeholder="total_price"
                          name="total_price"
                          value={campaignData.total_price}
                          onChange={handleChange}
                          readOnly
                        />
                      </div>
                    </Form.Group>
                  </Col>

                  <Col xs="12" sm="6" md="6" lg="6">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="subscription_item_id">
                        Stripe Data
                      </Form.Label>
                    </Form.Group>
                  </Col>

                  <Row>
                    <Col xs="12" sm="6" md="6" lg="6">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="subscription_item_id">
                          subscription_item_id
                        </Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            type="text"
                            id="subscription_item_id"
                            placeholder="subscription_item_id"
                            name="subscription_item_id"
                            value={campaignData.subscription_item_id}
                            onChange={handleChange}
                          />
                        </div>
                      </Form.Group>
                    </Col>

                    <Col xs="12" sm="6" md="6" lg="6">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="email">subscription_id</Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            type="text"
                            id="subscription_id"
                            placeholder="subscription_id"
                            name="subscription_id"
                            value={campaignData.subscription_id}
                            onChange={handleChange}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Check
                        type="checkbox"
                        id="unique_leads"
                        name="unique_leads"
                        value={campaignData.unique_leads}
                        onChange={handleCheck}
                        label="Leads únicos"
                      />
                    </Form.Group>
                  </Col>

                  <Col lg="12">
                    <Button variant="primary" type="submit">
                      {submitButtonText}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Card.Body>
        </Card>
      </Block>
    </Layout>
  )
}

export default CampaignForm
