import { useState, useEffect } from "react"
import { Card, Button, Row, Col, Form } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import leadService from "../services/leadService"
import Layout from "../../../layout/default"
import Block from "../../../components/Block/Block"
import mainSpecialtyService from "../../main_specialty/services/main_specialtyService"

function LeadForm() {
  const [loading, setLoading] = useState(false)
  const [initialLoading, setInitialLoading] = useState(false)
  const navigate = useNavigate()
  const { id } = useParams()
  const [leadData, setLeadData] = useState({
    name: "",
    last_name: "",
    online_service: false,
    sms_verified: false,
    email_verified: false,
  })
  const [Specialty, setSpecialty] = useState([])
  const [mainSpecialty, setMainSpecialty] = useState([])
  const [Province, setProvince] = useState([])

  useEffect(() => {
    fetchAll()
  }, [id])

  const fetchAll = async () => {
    try {
      setInitialLoading(true)
      await Promise.all([
        id && fetchLead(),
        fetchSpecialtyList(),
        fetchMain_specialty(),
        fetchProvinceList(),
      ])
    } catch (error) {
      console.error("Error fetching lead list:", error)
    } finally {
      setInitialLoading(false)
    }
  }
  const fetchMain_specialty = async () => {
    try {
      const specialty = await mainSpecialtyService.getMain_specialtyList()
      setMainSpecialty(specialty)
    } catch (error) {
      console.log("Error fetching specialty list:", error)
    }
  }

  const fetchSpecialtyList = async () => {
    try {
      const specialty = await leadService.getSpecialtyList()
      setSpecialty(specialty)
    } catch (error) {
      console.log("Error fetching specialty list:", error)
    }
  }

  const fetchProvinceList = async () => {
    try {
      const province = await leadService.getProvinceList()
      setProvince(province)
    } catch (error) {
      console.log("Error fetching province list:", error)
    }
  }

  const fetchLead = async () => {
    try {
      const lead = await leadService.getLeadDetails(id)
      setLeadData({ ...lead, specialty: lead.specialty.id })
    } catch (error) {
      console.log("Error fetching lead:", error)
    }
  }

  const handleChange = (event) => {
    setLeadData({ ...leadData, [event.target.name]: event.target.value })
  }
  const handleCheck = (event) => {
    setLeadData({ ...leadData, [event.target.name]: event.target.checked })
  }

  const handleCreateOrUpdateLead = async (event) => {
    event.preventDefault()

    try {
      setLoading(true)
      if (id) {
        await leadService.updateLead(id, leadData)
      } else {
        await leadService.createLead(leadData)
      }
      navigate("/lead")
    } catch (error) {
      console.log("Error creating/updating lead:", error)
    } finally {
      setLoading(false)
    }
  }

  const pageTitle = id ? "Editar Lead" : "Añadir Lead"
  const submitButtonText = id ? "Actualizar" : "Guardar"

  return (
    <Layout title={pageTitle} content="container" loading={initialLoading}>
      <Block>
        <Card className="card-gutter-md">
          <Card.Body>
            <div className="bio-block">
              <h4 className="bio-block-title mb-4">{pageTitle}</h4>

              <Form onSubmit={handleCreateOrUpdateLead}>
                <Row className="g-3">
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="name">name</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="name"
                          placeholder="name"
                          name="name"
                          value={leadData.name}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="last_name">last_name</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="last_name"
                          placeholder="last_name"
                          name="last_name"
                          value={leadData.last_name}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="email">email</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="email"
                          placeholder="email"
                          name="email"
                          value={leadData.email}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="phone_number">
                        phone_number
                      </Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="phone_number"
                          placeholder="phone_number"
                          name="phone_number"
                          value={leadData.phone_number}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="commentary">commentary</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="commentary"
                          placeholder="commentary"
                          name="commentary"
                          value={leadData.commentary}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="main_specialty">
                        Especialidad
                      </Form.Label>
                      <div className="form-control-wrap">
                        <Form.Select
                          id="main_specialty"
                          name="main_specialty"
                          value={leadData.main_specialty}
                          onChange={handleChange}
                          className="custom-select"
                        >
                          <option value="">Select specialty</option>
                          {mainSpecialty?.map((specialty, idx) => {
                            return (
                              <option key={idx} value={specialty.id}>
                                {specialty.name}
                              </option>
                            )
                          })}
                        </Form.Select>
                      </div>
                    </Form.Group>
                  </Col>

                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="specialty">
                        Subespecialidad
                      </Form.Label>
                      <div className="form-control-wrap">
                        <Form.Select
                          id="specialty"
                          name="specialty"
                          value={leadData.specialty}
                          onChange={handleChange}
                          className="custom-select"
                        >
                          <option value="">Select specialty</option>
                          {Specialty?.map((specialty, idx) => {
                            return (
                              <option key={idx} value={specialty.id}>
                                {specialty.child_specialty}
                              </option>
                            )
                          })}
                        </Form.Select>
                      </div>
                    </Form.Group>
                  </Col>

                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="province">province</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Select
                          id="province"
                          name="province"
                          value={leadData.province}
                          onChange={handleChange}
                          className="custom-select"
                        >
                          <option value="">Select province</option>
                          {Province?.map((province, idx) => {
                            return (
                              <option key={idx} value={province.id}>
                                {province.name}
                              </option>
                            )
                          })}
                        </Form.Select>
                      </div>
                    </Form.Group>
                  </Col>

                  {/* <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="Lead_state">Lead_state</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Select
                          id="state"
                          name="state"
                          value={leadData.state}
                          onChange={handleChange}
                          className="custom-select"
                        >
                          <option value="">Select Lead_state</option>
                          {Lead_state?.map((lead_state, idx) => {
                            return (
                              <option key={idx} value={lead_state.id}>
                                {lead_state.state}
                              </option>
                            )
                          })}
                        </Form.Select>
                      </div>
                    </Form.Group>
                  </Col> */}

                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Check
                        type="checkbox"
                        id="online_service"
                        name="online_service"
                        value={leadData.online_service}
                        label="online_service"
                        onChange={handleCheck}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Check
                        type="checkbox"
                        id="sms_verified"
                        name="sms_verified"
                        value={leadData.sms_verified}
                        label="sms_verified"
                        onChange={handleCheck}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Check
                        type="checkbox"
                        id="email_verified"
                        name="email_verified"
                        value={leadData.email_verified}
                        label="email_verified"
                        onChange={handleCheck}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Button variant="primary" type="submit">
                      {loading ? "loading..." : submitButtonText}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Card.Body>
        </Card>
      </Block>
    </Layout>
  )
}

export default LeadForm
