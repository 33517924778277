import axios from "axios"

class LawyerSchoolService {
  api
  constructor() {
    this.api = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}`,
    })
    this.api.interceptors.request.use((config) => {
      const token = localStorage.getItem("Token")

      if (token) {
        config.headers = {
          Authorization: `Token ${token}`,
          withCredentials: false,
          "Content-Type": "application/json; charset=UTF-8",
        }
      }

      return config
    })
  }

  getLawyer_schoolList = async () => {
    try {
      const response = await this.api.get(`/lawyer-school/`)
      return response.data
    } catch (error) {
      throw error
    }
  }

  getLawyer_schoolDetails = async (lawyer_schoolId) => {
    try {
      const response = await this.api.get(`/lawyer-school/${lawyer_schoolId}/`)
      return response.data
    } catch (error) {
      throw error
    }
  }

  createLawyer_school = async (lawyer_schoolData) => {
    try {
      const response = await this.api.post(`/lawyer-school/`, lawyer_schoolData)
      return response.data
    } catch (error) {
      throw error
    }
  }

  updateLawyer_school = async (lawyer_schoolId, lawyer_schoolData) => {
    try {
      const response = await this.api.put(
        `/lawyer-school/${lawyer_schoolId}/`,
        lawyer_schoolData
      )
      return response.data
    } catch (error) {
      throw error
    }
  }

  deleteLawyer_school = async (lawyer_schoolId) => {
    try {
      const response = await this.api.delete(
        `/lawyer-school/${lawyer_schoolId}/`
      )
      return response.data
    } catch (error) {
      throw error
    }
  }
}

const lawyerSchoolService = new LawyerSchoolService()

export default lawyerSchoolService
