import { useState, useEffect } from "react"
import { Card, Button, Row, Col, Form } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import DatePicker from "../../../components/Form/DatePicker"
import leadStateService from "../services/lead_stateService"
import Layout from "../../../layout/default"
import Block from "../../../components/Block/Block"

function Lead_stateForm() {
  const navigate = useNavigate()
  const { id } = useParams()

  const [lead_stateData, setLead_stateData] = useState({
    name: "",
    lastname: "",
  })

  useEffect(() => {
    if (id) {
      fetchLead_state()
    }
  }, [id])

  const fetchLead_state = async () => {
    try {
      const lead_state = await leadStateService.getLead_stateDetails(id)
      setLead_stateData(lead_state)
    } catch (error) {
      console.log("Error fetching lead_state:", error)
    }
  }

  const handleChange = (event) => {
    setLead_stateData({
      ...lead_stateData,
      [event.target.name]: event.target.value,
    })
  }

  const handleCreateOrUpdateLead_state = async (event) => {
    event.preventDefault()

    try {
      if (id) {
        await leadStateService.updateLead_state(id, lead_stateData)
      } else {
        await leadStateService.createLead_state(lead_stateData)
      }
      navigate("/lead_state")
    } catch (error) {
      console.log("Error creating/updating lead_state:", error)
    }
  }

  const pageTitle = id ? "Edit Lead_state" : "Add Lead_state"
  const submitButtonText = id ? "Update" : "Save"

  return (
    <Layout title={pageTitle} content="container">
      <Block>
        <Card className="card-gutter-md">
          <Card.Body>
            <div className="bio-block">
              <h4 className="bio-block-title mb-4">{pageTitle}</h4>
              <Form onSubmit={handleCreateOrUpdateLead_state}>
                <Row className="g-3">
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="state">state</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="state"
                          placeholder="state"
                          name="state"
                          value={lead_stateData.state}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Button variant="primary" type="submit">
                      {submitButtonText}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Card.Body>
        </Card>
      </Block>
    </Layout>
  )
}

export default Lead_stateForm
