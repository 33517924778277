import { Card, Button, Dropdown } from "react-bootstrap"
import { Link, useNavigate } from "react-router-dom"
import {
  Icon,
  CustomDropdownToggle,
  CustomDropdownMenu,
  LinkList,
} from "../../../components"
import Layout from "../../../layout/default"
import Block from "../../../components/Block/Block"
import DataTable from "../../../components/DataTable/DataTable.js"

import React, { useEffect, useState } from "react"
import lawyerSchoolService from "../services/lawyer_schoolService"

const Lawyer_schoolList = () => {
  const navigate = useNavigate()
  const [lawyer_schoolData, setLawyer_school] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    fetchLawyer_schoolList()
  }, [])

  const fetchLawyer_schoolList = async () => {
    try {
      const response = await lawyerSchoolService.getLawyer_schoolList()
      setLawyer_school(response)
      setLoading(false)
    } catch (error) {
      console.log("Error fetching lawyer_school list:", error)
      setLoading(false)
    }
  }

  const handleDeleteLawyer_school = async (lawyer_schoolId) => {
    try {
      setLoading(true)
      await lawyerSchoolService.deleteLawyer_school(lawyer_schoolId)
      const data = lawyer_schoolData.filter(
        (rows) => rows.id != lawyer_schoolId
      )
      setLawyer_school(data)
      setLoading(false)
    } catch (error) {
      console.log("Error deleting lawyer_school:", error)
      setLoading(false)
    }
  }

  const handleCreateLawyer_school = () => {
    navigate("/lawyer_school/new")
  }

  const lawyer_schoolColumns = [
    {
      name: "id",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "location",
      selector: (row) => row.location,
      sortable: true,
    },
    {
      name: "acronym",
      selector: (row) => row.acronym,
      sortable: true,
    },
    {
      name: "actions",
      cell: (row) => (
        <div className="text-end w-100">
          <Dropdown>
            <Dropdown.Toggle
              size="sm"
              as={CustomDropdownToggle}
              className="btn btn-sm btn-icon btn-zoom me-n1"
            >
              <Icon name="more-v" />
            </Dropdown.Toggle>
            <Dropdown.Menu
              className="dropdown-menu-sm"
              as={CustomDropdownMenu}
              align="end"
            >
              <div className="dropdown-content py-1">
                <LinkList className="link-list-hover-bg-primary link-list-md">
                  <Link
                    to={`/lawyer_school/edit/${row.id}`}
                    className="dropdown-item"
                  >
                    <Icon name="edit" />
                    <span>Edit</span>
                  </Link>
                  <button
                    className="dropdown-item"
                    onClick={() => handleDeleteLawyer_school(row.id)}
                  >
                    <Icon name="trash" />
                    <span>Delete</span>
                  </button>
                </LinkList>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ),
      sortable: false,
    },
  ]

  return (
    <Layout title="Lawyer_school List" content="container">
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            <Block.Title tag="h2">Lawyer_school List</Block.Title>
            <nav>
              <ol className="breadcrumb breadcrumb-arrow mb-0">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Lawyer_school
                </li>
              </ol>
            </nav>
          </Block.HeadContent>
          <Block.HeadContent>
            <ul className="d-flex">
              <li>
                <Button
                  className="d-md-none"
                  size="md"
                  variant="primary"
                  onClick={handleCreateLawyer_school}
                >
                  <Icon name="plus" />
                  <span>Add</span>
                </Button>
              </li>
              <li>
                <Button
                  className="d-none d-md-inline-flex"
                  variant="primary"
                  onClick={handleCreateLawyer_school}
                >
                  <Icon name="plus" />
                  <span>Add Lawyer_school</span>
                </Button>
              </li>
            </ul>
          </Block.HeadContent>
        </Block.HeadBetween>
      </Block.Head>

      <Block>
        <Card>
          {!loading ? (
            <DataTable
              tableClassName="data-table-head-light table-responsive"
              data={lawyer_schoolData}
              columns={lawyer_schoolColumns}
              expandableRows
              actions
            />
          ) : (
            <p>Loading...</p>
          )}
        </Card>
      </Block>
    </Layout>
  )
}

export default Lawyer_schoolList
