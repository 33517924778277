import { useState, useEffect } from "react"
import { Card, Button, Row, Col, Form } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import postsPlanService from "../services/posts_planService"
import Layout from "../../../layout/default"
import Block from "../../../components/Block/Block"
import postsChoicesService from "../../posts_choices/services/posts_choicesService"

function Posts_planForm() {
  const navigate = useNavigate()
  const { id } = useParams()

  const [posts_planData, setPosts_planData] = useState({
    posts_choices: "",
    themes: "",
    price: "",
    enable: false,
  })
  const [postsChoicesList, setPostsChoicesList] = useState([])

  useEffect(() => {
    if (id) {
      fetchPosts_plan()
    }
    fetchPostsChoicesList()
  }, [id])

  const fetchPosts_plan = async () => {
    try {
      const posts_plan = await postsPlanService.getPosts_planDetails(id)
      setPosts_planData(posts_plan)
    } catch (error) {
      console.log("Error fetching posts_plan:", error)
    }
  }
  const fetchPostsChoicesList = async () => {
    try {
      const res = await postsChoicesService.getPosts_choicesList()
      setPostsChoicesList(res)
    } catch (error) {
      console.log("Error fetching posts_plan:", error)
    }
  }
  const handleChange = (event) => {
    setPosts_planData({
      ...posts_planData,
      [event.target.name]: event.target.value,
    })
  }
  const handleCheck = (event) => {
    setPosts_planData({
      ...posts_planData,
      [event.target.name]: event.target.checked,
    })
  }

  const handleCreateOrUpdatePosts_plan = async (event) => {
    event.preventDefault()

    try {
      if (id) {
        await postsPlanService.updatePosts_plan(id, posts_planData)
      } else {
        await postsPlanService.createPosts_plan(posts_planData)
      }
      navigate("/posts_plan")
    } catch (error) {
      console.log("Error creating/updating posts_plan:", error)
    }
  }

  const pageTitle = id ? "Edit Posts_plan" : "Add Posts_plan"
  const submitButtonText = id ? "Update" : "Save"

  return (
    <Layout title={pageTitle} content="container">
      <Block>
        <Card className="card-gutter-md">
          <Card.Body>
            <div className="bio-block">
              <h4 className="bio-block-title mb-4">{pageTitle}</h4>
              <Form onSubmit={handleCreateOrUpdatePosts_plan}>
                <Row className="g-3">
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="posts_choices">
                        posts_choices
                      </Form.Label>
                      <div className="form-control-wrap">
                        <Form.Select
                          id="posts_choices"
                          name="posts_choices"
                          value={posts_planData.posts_choices}
                          onChange={handleChange}
                          className="custom-select"
                        >
                          <option value="">Select posts_choices</option>
                          {postsChoicesList?.map((posts_choices, idx) => {
                            return (
                              <option key={idx} value={posts_choices.id}>
                                {posts_choices.name}
                              </option>
                            )
                          })}
                        </Form.Select>
                      </div>
                    </Form.Group>
                  </Col>

                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="price">price</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="price"
                          placeholder="price"
                          name="price"
                          value={posts_planData.price}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="themes">themes</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          as="textarea"
                          row={4}
                          id="themes"
                          placeholder="themes"
                          name="themes"
                          value={posts_planData.themes}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Check
                        type="checkbox"
                        id="enable"
                        name="enable"
                        value={posts_planData.enable}
                        onChange={handleCheck}
                        label="enable"
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Button variant="primary" type="submit">
                      {submitButtonText}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Card.Body>
        </Card>
      </Block>
    </Layout>
  )
}

export default Posts_planForm
