import { useState, useEffect } from "react"
import { Card, Button, Row, Col, Form } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import DatePicker from "../../../components/Form/DatePicker"
import provinceService, {
  createProvince,
  getProvinceDetails,
  updateProvince,
} from "../services/provinceService"
import Layout from "../../../layout/default"
import Block from "../../../components/Block/Block"

function ProvinceForm() {
  const navigate = useNavigate()
  const { id } = useParams()

  const [provinceData, setProvinceData] = useState({
    name: "",
    lastname: "",
  })

  useEffect(() => {
    if (id) {
      fetchProvince()
    }
  }, [id])

  const fetchProvince = async () => {
    try {
      const province = await provinceService.getProvinceDetails(id)
      setProvinceData(province)
    } catch (error) {
      console.log("Error fetching province:", error)
    }
  }

  const handleChange = (event) => {
    setProvinceData({
      ...provinceData,
      [event.target.name]: event.target.value,
    })
  }

  const handleCreateOrUpdateProvince = async (event) => {
    event.preventDefault()

    try {
      if (id) {
        await provinceService.updateProvince(id, provinceData)
      } else {
        await provinceService.createProvince(provinceData)
      }
      navigate("/province")
    } catch (error) {
      console.log("Error creating/updating province:", error)
    }
  }

  const pageTitle = id ? "Edit Province" : "Add Province"
  const submitButtonText = id ? "Update" : "Save"

  return (
    <Layout title={pageTitle} content="container">
      <Block>
        <Card className="card-gutter-md">
          <Card.Body>
            <div className="bio-block">
              <h4 className="bio-block-title mb-4">{pageTitle}</h4>
              <Form onSubmit={handleCreateOrUpdateProvince}>
                <Row className="g-3">
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="name">name</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="name"
                          placeholder="name"
                          name="name"
                          value={provinceData.name}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Button variant="primary" type="submit">
                      {submitButtonText}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Card.Body>
        </Card>
      </Block>
    </Layout>
  )
}

export default ProvinceForm
