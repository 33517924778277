import axios from "axios"

class CampaignService {
  api
  constructor() {
    this.api = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}`,
    })
    this.api.interceptors.request.use((config) => {
      const token = localStorage.getItem("Token")

      if (token) {
        config.headers = {
          Authorization: `Token ${token}`,
          withCredentials: false,
          "Content-Type": "application/json; charset=UTF-8",
        }
      }

      return config
    })
  }
  
  getCampaignList = async () => {
    try {
      const response = await this.api.get(`/campaign/`)
      return response.data
    } catch (error) {
      throw error
    }
  }

  getCampaignListCrm = async () => {
    try {
      const response = await this.api.get(`/campaign/crm/`)
      return response.data
    } catch (error) {
      throw error
    }
  }

  getAggregateCampaigns = async () => {
    try {
      const response = await this.api.get(`/aggregate/campaign/`)
      return response.data
    } catch (error) {
      throw error
    }
  }

  getCampaignDetails = async (campaignId) => {
    try {
      const response = await this.api.get(`/campaign/${campaignId}/`)
      return response.data
    } catch (error) {
      throw error
    }
  }

  createCampaign = async (campaignData) => {
    try {
      const response = await this.api.post(`/campaign/`, campaignData)
      return response.data
    } catch (error) {
      throw error
    }
  }

  updateCampaign = async (campaignId, campaignData) => {
    try {
      const response = await this.api.put(
        `/campaign/${campaignId}/`,
        campaignData
      )
      return response.data
    } catch (error) {
      throw error
    }
  }

  deleteCampaign = async (campaignId) => {
    try {
      const response = await this.api.delete(`/campaign/${campaignId}/`)
      return response.data
    } catch (error) {
      throw error
    }
  }

  getSpecialtyList = async () => {
    try {
      const response = await this.api.get("/specialty/")
      return response.data
    } catch (error) {
      throw error
    }
  }

  getProvinceList = async () => {
    try {
      const response = await this.api.get("/province/")
      return response.data
    } catch (error) {
      throw error
    }
  }

  getLocationList = async () => {
    try {
      const response = await this.api.get("/location/")
      return response.data
    } catch (error) {
      throw error
    }
  }
}

const campaignService = new CampaignService()

export default campaignService
