import React, { useContext, useState } from "react"
import { Row, Col, Card, Form, Button } from "react-bootstrap"
import { Link, useNavigate } from "react-router-dom"
import { Image, OverlineTitle, Logo } from "../../components"
import { message } from "antd"
import authService from "../../services/auth.service"
import { AuthContext } from "../../context/auth.context"
import Layout from "../../layout/fullpage"

const AuthLoginPage = () => {
  const [loginData, setLoginData] = useState({
    password: "",
    email: "",
  })
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const { storeToken, authenticateUser, setIsLoggedIn } =
    useContext(AuthContext)

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      setLoading(true)
      const { data } = await authService.login(loginData)
      storeToken(data.token)
      setIsLoggedIn(true)
      message.success("Welcome back")
      authenticateUser()
      setLoading(false)
      navigate("/")
    } catch (error) {
      setLoading(false)
      console.log(error?.response)
      if (
        error?.response?.status === 401 &&
        error?.response?.data.detail === "User not allowed!"
      ) {
        message.error("Acceso no permitido!")
      } else if (
        error?.response?.status === 403 ||
        error?.response?.status === 401
      ) {
        message.error("Contraseña o usuario incorrecto")
      } else {
        message.error(
          "Error" + error?.response?.status
            ? error?.response?.status
            : `${error.code}`
        )
      }
      console.error(error)
    }
  }

  const handleInputChange = (e) => {
    const { value, name } = e.currentTarget
    setLoginData({ ...loginData, [name]: value })
  }

  const { password, email } = loginData

  return (
    <>
      <Layout title="Login" centered>
        <div className="container p-2 p-sm-4">
          <Card className="overflow-hidden card-gutter-lg rounded-4 card-auth card-auth-mh">
            <Row className="g-0 flex-lg-row-reverse">
              <Col lg="5">
                <Card.Body className="h-100 d-flex flex-column justify-content-center">
                  <div className="nk-block-head text-center">
                    <div className="nk-block-head-content">
                      <h3 className="nk-block-title mb-1">Login to Account</h3>
                      <p className="small">
                        Please sign-in to your account and start the adventure.
                      </p>
                    </div>
                  </div>
                  <Form onSubmit={handleSubmit}>
                    <Row className="gy-3">
                      <Col className="col-12">
                        <Form.Group className="form-group">
                          <Form.Label htmlFor="email">Email</Form.Label>
                          <div className="form-control-wrap">
                            <Form.Control
                              type="email"
                              id="email"
                              placeholder="Enter email"
                              name="email"
                              value={email}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                        </Form.Group>
                      </Col>
                      <Col className="col-12">
                        <Form.Group className="form-group">
                          <Form.Label htmlFor="password">Password</Form.Label>
                          <div className="form-control-wrap">
                            <Form.Control
                              type="password"
                              id="password"
                              placeholder="Enter password"
                              name="password"
                              value={password}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                        </Form.Group>
                      </Col>
                      <Col className="col-12">
                        <div className="d-flex flex-wrap justify-content-between">
                          <Form.Check
                            className="form-check-sm"
                            type="checkbox"
                            id="rememberMe"
                            label="Remember Me"
                          />
                          <Link to="/auths/auth-reset" className="small">
                            Forgot Password?
                          </Link>
                        </div>
                      </Col>
                      <Col className="col-12">
                        <div className="d-grid">
                          <Button type="submit">
                            {loading ? "Loading..." : "Login to account"}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                  <div className="my-3 text-center">
                    <OverlineTitle className="overline-title-sep">
                      <span>OR</span>
                    </OverlineTitle>
                  </div>
                  <Row className="g-2">
                    <Col xxl="6">
                      <Button
                        href="#auth"
                        variant="outline-light"
                        className="w-100"
                      >
                        <Image
                          src="/images/icon/d.png"
                          alt=""
                          className="icon"
                        />
                        <span className="fw-medium">Continue with Google</span>
                      </Button>
                    </Col>
                    <Col xxl="6">
                      <Button
                        href="#auth"
                        variant="outline-light"
                        className="w-100"
                      >
                        <Image
                          src="/images/icon/b.png"
                          alt=""
                          className="icon"
                        />
                        <span className="fw-medium">
                          Continue with Facebook
                        </span>
                      </Button>
                    </Col>
                  </Row>
                  <div className="text-center mt-4">
                    <p className="small">
                      Don't have an account?{" "}
                      <Link to="/auths/auth-register">Register</Link>
                    </p>
                  </div>
                </Card.Body>
              </Col>
              <Col lg="7">
                <Card.Body className="bg-darker is-theme has-mask has-mask-1 h-100 d-flex flex-column justify-content-start">
                  <div className="mask mask-1"></div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "start",
                    }}
                  >
                    <Logo />
                  </div>
                  <div className="row">
                    <div className="col-sm-11">
                      <div className="mt-4">
                        <div className="h1 title mb-3">
                          Bienvenido a <br /> CRM Abogueo
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />
                  <br />
                </Card.Body>
              </Col>
            </Row>
          </Card>
        </div>
      </Layout>
    </>
  )
}

export default AuthLoginPage
