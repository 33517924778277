import axios, { Axios } from "axios"

class NotificationSettingsService {
  api
  constructor() {
    this.api = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}`,
    })
    this.api.interceptors.request.use((config) => {
      const token = localStorage.getItem("Token")

      if (token) {
        config.headers = {
          Authorization: `Token ${token}`,
        }
      }

      return config
    })
  }
  getNotification_settingsList = async () => {
    try {
      const response = await this.api.get(`/notification-settings/`)
      return response.data
    } catch (error) {
      throw error
    }
  }

  getNotification_settingsDetails = async (notification_settingsId) => {
    try {
      const response = await this.api.get(
        `/notification-settings/${notification_settingsId}/`
      )
      return response.data
    } catch (error) {
      throw error
    }
  }

  createNotification_settings = async (notification_settingsData) => {
    try {
      const response = await this.api.post(
        `/notification-settings/`,
        notification_settingsData
      )
      return response.data
    } catch (error) {
      throw error
    }
  }

  updateNotification_settings = async (
    notification_settingsId,
    notification_settingsData
  ) => {
    try {
      const response = await this.api.put(
        `/notification-settings/${notification_settingsId}/`,
        notification_settingsData
      )
      return response.data
    } catch (error) {
      throw error
    }
  }

  deleteNotification_settings = async (notification_settingsId) => {
    try {
      const response = await this.api.delete(
        `/notification-settings/${notification_settingsId}/`
      )
      return response.data
    } catch (error) {
      throw error
    }
  }
}

const notificationSettingsService = new NotificationSettingsService()

export default notificationSettingsService
