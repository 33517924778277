import { useState, useEffect } from "react"
import { Card, Button, Row, Col, Form, Dropdown } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import campaignCustomService from "../services/campaignCustomService"
import Layout from "../../../layout/default"
import Block from "../../../components/Block/Block"
import lawyerService from "../../lawyer/services/lawyerService"

function CampaignCustomForm() {
  const navigate = useNavigate()
  const { id } = useParams()
  const [campaignData, setCampaignData] = useState({
    cpl: 0,
    specialties: [],
    province: [],
  })
  const [mainSpecialtyList, setMainSpecialtyList] = useState([])
  const [ProvinceList, setProvinceList] = useState([])
  const [LawyerList, setLawyerList] = useState([])
  const isSpecialtyUndefined = campaignData.specialties === undefined
  const isProvinceUndefined = campaignData.province === undefined
  const isBothFieldsSelected =
    (!isSpecialtyUndefined && !isProvinceUndefined) ||
    (!isSpecialtyUndefined && campaignData.is_countrywide)
  const [loading, setLoading] = useState(false)
  const [initialLoading, setInitialLoading] = useState(false)

  useEffect(() => {
    fetchAll()
  }, [id])

  const fetchAll = async () => {
    try {
      setInitialLoading(true)
      Promise.all([
        id && fetchCampaign(),
        fetchSpecialty(),
        fetchProvince(),
        fetchLawyer(),
      ])
    } catch (error) {
      console.error(error)
    } finally {
      setInitialLoading(false)
    }
  }

  const fetchCampaign = async () => {
    try {
      const campaign = await campaignCustomService.getCampaignDetails(id)
      setCampaignData(campaign)
    } catch (error) {
      console.log("Error fetching campaign:", error)
    }
  }

  const fetchSpecialty = async () => {
    try {
      const dataMainSpecialty =
        await campaignCustomService.getMainSpecialtyList()
      setMainSpecialtyList(dataMainSpecialty)
    } catch (error) {
      console.error("Error fetchingspecialties:", error)
    }
  }

  const fetchProvince = async () => {
    try {
      const dataProvince = await campaignCustomService.getProvinceList()
      setProvinceList(dataProvince)
    } catch (error) {
      console.error("Error fetching Province:", error)
    }
  }

  const fetchLawyer = async () => {
    try {
      const response = await lawyerService.getRealLawyerList()
      setLawyerList(response)
    } catch (error) {
      console.log("Error fetching list:", error)
    }
  }

  const handleCheckboxChange = (id) => {
    let selectedSpecialties = [...campaignData.specialties]
    if (selectedSpecialties.includes(id)) {
      selectedSpecialties = selectedSpecialties.filter((item) => item !== id)
    } else {
      selectedSpecialties.push(id)
    }
    setCampaignData({
      ...campaignData,
     specialties: selectedSpecialties,
    })
  }

  const handleCheckboxProvinceChange = (id) => {
    let selectedProvinces = [...campaignData.province]
    if (selectedProvinces.includes(id)) {
      selectedProvinces = selectedProvinces.filter((item) => item !== id)
    } else {
      selectedProvinces.push(id)
    }
    setCampaignData({
      ...campaignData,
      province: selectedProvinces,
    })
  }

  const handleChange = (event) => {
    const { name, value } = event.target

    setCampaignData((prevData) => {
      const updatedData = {
        ...prevData,
        [name]: value,
      }

      if (name === "lead_amount" || name === "cpl") {
        const leadAmount = name === "lead_amount" ? value : prevData.lead_amount
        const cpl = name === "cpl" ? value : prevData.cpl

        updatedData.total_price = leadAmount * cpl
      }
      return updatedData
    })
  }

  const handleCreateOrUpdateCampaign = async (event) => {
    event.preventDefault()

    try {
      setLoading(true)
      if (id) {
        await campaignCustomService.updateCampaign(id, campaignData)
      } else {
        await campaignCustomService.createCampaign(campaignData)
      }
      navigate("/campaign-custom")
    } catch (error) {
      console.log("Error creating/updating campaign:", error)
    } finally {
      setLoading(false)
    }
  }

  const pageTitle = id ? "Editar Campaña" : "Añadir Campaña"
  const submitButtonText = id ? "Actualizar" : "Guardar"

  return (
    <Layout title={pageTitle} content="container" loading={initialLoading}>
      <Block>
        <Card className="card-gutter-md">
          <Card.Body>
            <div className="bio-block">
              <h4 className="bio-block-title mb-4">{pageTitle}</h4>
              <Form onSubmit={handleCreateOrUpdateCampaign}>
                <Row className="g-3">
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="Specialty">Especialidades</Form.Label>
                      <div className="form-control-wrap">
                        <Dropdown className="form-group">
                          <Dropdown.Toggle
                            variant="outline-secondary"
                            id="dropdown-basic"
                            className="w-100"
                          >
                            {campaignData.specialties.length === 0
                              ? "Selecciona especialidades"
                              : mainSpecialtyList
                                  .filter((specialty) =>
                                    campaignData.specialties.includes(
                                     specialty.id
                                    )
                                  )
                                  .map((specialty) =>specialty.name)
                                  .join(", ")}
                          </Dropdown.Toggle>

                          <Dropdown.Menu
                            className="w-100"
                            style={{ maxHeight: "500px", overflowY: "auto" }}
                          >
                            <Form.Group>
                              {mainSpecialtyList?.map((mainSpecialty, idx) => (
                                <Form.Check
                                  key={idx}
                                  type="checkbox"
                                  id={`specialty-${mainSpecialty.id}`}
                                  label={mainSpecialty.name}
                                  value={mainSpecialty.id}
                                  checked={campaignData.specialties.includes(
                                    mainSpecialty.id
                                  )}
                                  onChange={() =>
                                    handleCheckboxChange(mainSpecialty.id)
                                  }
                                />
                              ))}
                            </Form.Group>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </Form.Group>
                  </Col>

                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="Specialty">Provincias</Form.Label>
                      <div>
                        <Dropdown className="form-group">
                          <Dropdown.Toggle
                            variant="outline-secondary"
                            id="dropdown-basic"
                            className="w-100"
                          >
                            {campaignData.province.length === 0
                              ? "Selecciona provincias"
                              : ProvinceList.filter((province) =>
                                  campaignData.province.includes(province.id)
                                )
                                  .map((province) => province.name)
                                  .join(", ")}
                          </Dropdown.Toggle>

                          <Dropdown.Menu
                            className="w-100"
                            style={{ maxHeight: "500px", overflowY: "auto" }}
                          >
                            {" "}
                            {/* Make dropdown scrollable */}
                            <Form.Group>
                              {ProvinceList?.map((province, idx) => (
                                <Form.Check
                                  key={idx}
                                  type="checkbox"
                                  id={`province-${province.id}`}
                                  label={province.name}
                                  value={province.id}
                                  checked={campaignData.province.includes(
                                    province.id
                                  )}
                                  onChange={() =>
                                    handleCheckboxProvinceChange(province.id)
                                  }
                                />
                              ))}
                            </Form.Group>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </Form.Group>
                  </Col>

                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="Province">Abogado</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Select
                          id="lawyer"
                          name="lawyer"
                          value={campaignData.lawyer}
                          onChange={handleChange}
                          className="custom-select"
                          required
                        >
                          <option value="">Selecciona Abogado</option>
                          {LawyerList?.map((l, idx) => {
                            return (
                              <option key={idx} value={l.id}>
                                {l.id}, {l.name}, {l.last_name}, {l.email}
                              </option>
                            )
                          })}
                        </Form.Select>
                      </div>
                    </Form.Group>
                  </Col>

                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="email">Número de leads</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="number"
                          id="lead_amount"
                          placeholder="lead_amount"
                          name="lead_amount"
                          value={campaignData.lead_amount}
                          onChange={handleChange}
                          disabled={!isBothFieldsSelected}
                          required
                        />
                      </div>
                    </Form.Group>
                  </Col>

                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="email">Coste por lead</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="number"
                          id="cpl"
                          placeholder="cpl"
                          name="cpl"
                          value={campaignData.cpl}
                          onChange={handleChange}
                          disabled={!isBothFieldsSelected}
                          required
                        />
                      </div>
                    </Form.Group>
                  </Col>

                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="email">Coste</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="number"
                          id="total_price"
                          placeholder="total_price"
                          name="total_price"
                          value={campaignData.total_price}
                          onChange={handleChange}
                          readOnly
                        />
                      </div>
                    </Form.Group>
                  </Col>

                  <Col lg="12">
                    <Button variant="primary" type="submit">
                      {submitButtonText}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Card.Body>
        </Card>
      </Block>
    </Layout>
  )
}

export default CampaignCustomForm
