import React from "react"
import App from "./App"
import ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import reportWebVitals from "./reportWebVitals"
import { AuthProviderWrapper } from "./context/auth.context"
import "./assets/scss/style.scss"
import { CommonsDataProviderWrapper } from "./context/commons.context"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <BrowserRouter>
    <CommonsDataProviderWrapper>
      <AuthProviderWrapper>
        <App />
      </AuthProviderWrapper>
    </CommonsDataProviderWrapper>
  </BrowserRouter>
)

reportWebVitals()
