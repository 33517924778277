import { Card, Button, Dropdown, Badge } from "react-bootstrap"
import { Link, useNavigate } from "react-router-dom"
import {
  Icon,
  CustomDropdownToggle,
  CustomDropdownMenu,
  LinkList,
} from "../../../components"
import Layout from "../../../layout/default"
import Block from "../../../components/Block/Block"
import DataTable from "../../../components/DataTable/DataTable.js"
import React, { useEffect, useState } from "react"
import postsPlanService from "../services/posts_planService"
import postsChoicesService from "../../posts_choices/services/posts_choicesService"

const Posts_planList = () => {
  const navigate = useNavigate()
  const [posts_planData, setPosts_plan] = useState([])
  const [loading, setLoading] = useState(false)
  const [posts_choicesData, setPosts_choices] = useState([])

  useEffect(() => {
    setLoading(true)
    fetchPosts_planList()
    fetchPosts_choicesList()
  }, [])

  const fetchPosts_choicesList = async () => {
    try {
      const posts_choices = await postsChoicesService.getPosts_choicesList()
      setPosts_choices(posts_choices)
    } catch (error) {
      console.log("Error fetching posts_choices list:", error)
    }
  }

  const fetchPosts_planList = async () => {
    try {
      const response = await postsPlanService.getPosts_planList()
      setPosts_plan(response)
      setLoading(false)
    } catch (error) {
      console.log("Error fetching posts_plan list:", error)
      setLoading(false)
    }
  }

  const handleDeletePosts_plan = async (posts_planId) => {
    try {
      setLoading(true)
      await postsPlanService.deletePosts_plan(posts_planId)
      const data = posts_planData.filter((rows) => rows.id != posts_planId)
      setPosts_plan(data)
      setLoading(false)
    } catch (error) {
      console.log("Error deleting posts_plan:", error)
      setLoading(false)
    }
  }

  const handleCreatePosts_plan = () => {
    navigate("/posts_plan/new")
  }

  const posts_planColumns = [
    {
      name: "id",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "posts_choices",
      selector: (row) => {
        const posts_choices = posts_choicesData.find(
          (posts_choices) => posts_choices.id === row.posts_choices
        )
        return posts_choices ? posts_choices.name : ""
      },
      sortable: true,
      cell: (row) => {
        const posts_choices = posts_choicesData.find(
          (posts_choices) => posts_choices.id === row.posts_choices
        )
        return posts_choices ? posts_choices.name : ""
      },
    },
    {
      name: "themes",
      selector: (row) => row.themes,
      sortable: true,
    },
    {
      name: "price",
      selector: (row) => row.price,
      sortable: true,
    },
    {
      name: "enable",
      selector: (row) => {
        if (row.enable) {
          return <Badge bg="success">Yes</Badge>
        } else {
          return <Badge bg="warning">No</Badge>
        }
      },
      sortable: true,
    },
    {
      name: "actions",
      cell: (row) => (
        <div className="text-end w-100">
          <Dropdown>
            <Dropdown.Toggle
              size="sm"
              as={CustomDropdownToggle}
              className="btn btn-sm btn-icon btn-zoom me-n1"
            >
              <Icon name="more-v" />
            </Dropdown.Toggle>
            <Dropdown.Menu
              className="dropdown-menu-sm"
              as={CustomDropdownMenu}
              align="end"
            >
              <div className="dropdown-content py-1">
                <LinkList className="link-list-hover-bg-primary link-list-md">
                  <Link
                    to={`/posts_plan/edit/${row.id}`}
                    className="dropdown-item"
                  >
                    <Icon name="edit" />
                    <span>Edit</span>
                  </Link>
                  <button
                    className="dropdown-item"
                    onClick={() => handleDeletePosts_plan(row.id)}
                  >
                    <Icon name="trash" />
                    <span>Delete</span>
                  </button>
                </LinkList>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ),
      sortable: false,
    },
  ]

  return (
    <Layout title="Posts_plan List" content="container">
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            <Block.Title tag="h2">Posts_plan List</Block.Title>
            <nav>
              <ol className="breadcrumb breadcrumb-arrow mb-0">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Posts_plan
                </li>
              </ol>
            </nav>
          </Block.HeadContent>
          <Block.HeadContent>
            <ul className="d-flex">
              <li>
                <Button
                  className="d-md-none"
                  size="md"
                  variant="primary"
                  onClick={handleCreatePosts_plan}
                >
                  <Icon name="plus" />
                  <span>Add</span>
                </Button>
              </li>
              <li>
                <Button
                  className="d-none d-md-inline-flex"
                  variant="primary"
                  onClick={handleCreatePosts_plan}
                >
                  <Icon name="plus" />
                  <span>Add Posts_plan</span>
                </Button>
              </li>
            </ul>
          </Block.HeadContent>
        </Block.HeadBetween>
      </Block.Head>

      <Block>
        <Card>
          {!loading ? (
            <DataTable
              tableClassName="data-table-head-light table-responsive"
              data={posts_planData}
              columns={posts_planColumns}
              expandableRows
              actions
            />
          ) : (
            <p>Loading...</p>
          )}
        </Card>
      </Block>
    </Layout>
  )
}

export default Posts_planList
