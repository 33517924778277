import axios from "axios"

class Cost_per_leadListService {
  api
  constructor() {
    this.api = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}`,
    })
    this.api.interceptors.request.use((config) => {
      const token = localStorage.getItem("Token")

      if (token) {
        config.headers = {
          Authorization: `Token ${token}`,
          withCredentials: false,
        }
      }

      return config
    })
  }

  getCost_per_leadList = async () => {
    try {
      const response = await this.api.get(`/cost-per-lead/`)
      return response.data
    } catch (error) {
      throw error
    }
  }

  getCost_per_leadFullList = async () => {
    try {
      const response = await this.api.get(`/cost-per-lead/full-list/`)
      return response.data
    } catch (error) {
      throw error
    }
  }

  cost_per_leadBulkUpdate = async (formData) => {
    try {
      const response = await this.api.post(
        "/cost-per-lead/bulk-update/",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      return response.data
    } catch (error) {
      throw error
    }
  }

  findCost_per_leadList = async (specialty_id, province_id) => {
    try {
      const response = await this.api.get(
        `/cost-per-lead/${specialty_id}/${province_id}`
      )
      return response.data
    } catch (error) {
      throw error
    }
  }

  getCostPerLeadAllCountry = async (specialtyId) => {
    try {
      const response = await this.api.get(
        `/cost-per-lead/${specialtyId}/all-country/`
      )
      return response.data
    } catch (error) {
      throw error
    }
  }
  getCost_per_leadDetails = async (Cost_per_leadId) => {
    try {
      const response = await this.api.get(`/cost-per-lead/${Cost_per_leadId}/`)
      return response.data
    } catch (error) {
      throw error
    }
  }

  createCost_per_lead = async (Cost_per_leadData) => {
    try {
      const response = await this.api.post(`/cost-per-lead/`, Cost_per_leadData)
      return response.data
    } catch (error) {
      throw error
    }
  }

  updateCost_per_lead = async (Cost_per_leadId, Cost_per_leadData) => {
    try {
      const response = await this.api.put(
        `/cost-per-lead/${Cost_per_leadId}/`,
        Cost_per_leadData
      )
      return response.data
    } catch (error) {
      throw error
    }
  }

  deleteCost_per_lead = async (Cost_per_leadId) => {
    try {
      const response = await this.api.delete(
        `/cost-per-lead/${Cost_per_leadId}/`
      )
      return response.data
    } catch (error) {
      throw error
    }
  }

  getSpecialtyList = async () => {
    try {
      const response = await this.api.get("/specialty/")
      return response.data
    } catch (error) {
      throw error
    }
  }
  getProvinceList = async () => {
    try {
      const response = await this.api.get("/province/")
      return response.data
    } catch (error) {
      throw error
    }
  }
}

const cost_per_leadListService = new Cost_per_leadListService()

export default cost_per_leadListService
