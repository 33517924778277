import axios, { Axios } from "axios"

class NotificationService {
  api
  constructor() {
    this.api = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}`,
    })
    this.api.interceptors.request.use((config) => {
      const token = localStorage.getItem("Token")

      if (token) {
        config.headers = {
          Authorization: `Token ${token}`,
        }
      }

      return config
    })
  }
  getNotificationList = async () => {
    try {
      const response = await this.api.get(`/notification/`)
      return response.data
    } catch (error) {
      throw error
    }
  }

  getNotificationDetails = async (notificationId) => {
    try {
      const response = await this.api.get(`/notification/${notificationId}/`)
      return response.data
    } catch (error) {
      throw error
    }
  }

  createNotification = async (notificationData) => {
    try {
      const response = await this.api.post(`/notification/`, notificationData)
      return response.data
    } catch (error) {
      throw error
    }
  }

  updateNotification = async (notificationId, notificationData) => {
    try {
      const response = await this.api.put(
        `/notification/${notificationId}/`,
        notificationData
      )
      return response.data
    } catch (error) {
      throw error
    }
  }

  deleteNotification = async (notificationId) => {
    try {
      const response = await this.api.delete(`/notification/${notificationId}/`)
      return response.data
    } catch (error) {
      throw error
    }
  }

  getUserList = async () => {
    try {
      const response = await this.api.get("/user/")
      return response.data
    } catch (error) {
      throw error
    }
  }
}

const notificationService = new NotificationService()

export default notificationService
