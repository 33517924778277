import axios from "axios"

class PostsChoicesService {
  api
  constructor() {
    this.api = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}`,
    })
    this.api.interceptors.request.use((config) => {
      const token = localStorage.getItem("Token")

      if (token) {
        config.headers = {
          Authorization: `Token ${token}`,
          withCredentials: false,
          "Content-Type": "application/json; charset=UTF-8",
        }
      }

      return config
    })
  }
  getPosts_choicesList = async () => {
    try {
      const response = await this.api.get(`/posts-choices/`)
      return response.data
    } catch (error) {
      throw error
    }
  }

  getPosts_choicesDetails = async (posts_choicesId) => {
    try {
      const response = await this.api.get(`/posts-choices/${posts_choicesId}/`)
      return response.data
    } catch (error) {
      throw error
    }
  }

  createPosts_choices = async (posts_choicesData) => {
    try {
      const response = await this.api.post(`/posts-choices/`, posts_choicesData)
      return response.data
    } catch (error) {
      throw error
    }
  }

  updatePosts_choices = async (posts_choicesId, posts_choicesData) => {
    try {
      const response = await this.api.put(
        `/posts-choices/${posts_choicesId}/`,
        posts_choicesData
      )
      return response.data
    } catch (error) {
      throw error
    }
  }

  deletePosts_choices = async (posts_choicesId) => {
    try {
      const response = await this.api.delete(
        `/posts-choices/${posts_choicesId}/`
      )
      return response.data
    } catch (error) {
      throw error
    }
  }
}

const postsChoicesService = new PostsChoicesService()

export default postsChoicesService
