import axios from "axios"

class PostsPlanService {
  api
  constructor() {
    this.api = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}`,
    })
    this.api.interceptors.request.use((config) => {
      const token = localStorage.getItem("Token")

      if (token) {
        config.headers = {
          Authorization: `Token ${token}`,
          withCredentials: false,
          "Content-Type": "application/json; charset=UTF-8",
        }
      }

      return config
    })
  }
  getVip_planList = async () => {
    try {
      const response = await this.api.get(`/vip-plan/`)
      return response.data
    } catch (error) {
      throw error
    }
  }

  getVip_planDetails = async (vip_planId) => {
    try {
      const response = await this.api.get(`/vip-plan/${vip_planId}/`)
      return response.data
    } catch (error) {
      throw error
    }
  }

  createVip_plan = async (vip_planData) => {
    try {
      const response = await this.api.post(`/vip-plan/`, vip_planData)
      return response.data
    } catch (error) {
      throw error
    }
  }

  updateVip_plan = async (vip_planId, vip_planData) => {
    try {
      const response = await this.api.put(
        `/vip-plan/${vip_planId}/`,
        vip_planData
      )
      return response.data
    } catch (error) {
      throw error
    }
  }

  deleteVip_plan = async (vip_planId) => {
    try {
      const response = await this.api.delete(`/vip-plan/${vip_planId}/`)
      return response.data
    } catch (error) {
      throw error
    }
  }
}

const postsPlanService = new PostsPlanService()

export default postsPlanService
