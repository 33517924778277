import axios from "axios"

class LawyerSpecialtyService {
  api
  constructor() {
    this.api = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}`,
    })
    this.api.interceptors.request.use((config) => {
      const token = localStorage.getItem("Token")

      if (token) {
        config.headers = {
          Authorization: `Token ${token}`,
          withCredentials: false,
          "Content-Type": "application/json; charset=UTF-8",
        }
      }

      return config
    })
  }

  getLawyer_specialtyList = async () => {
    try {
      const response = await this.api.get(`/lawyer-specialty/`)
      return response.data
    } catch (error) {
      throw error
    }
  }

  getLawyer_specialtyDetails = async (lawyer_specialtyId) => {
    try {
      const response = await this.api.get(
        `/lawyer-specialty/${lawyer_specialtyId}/`
      )
      return response.data
    } catch (error) {
      throw error
    }
  }

  createLawyer_specialty = async (lawyer_specialtyData) => {
    try {
      const response = await this.api.post(
        `/lawyer-specialty/`,
        lawyer_specialtyData
      )
      return response.data
    } catch (error) {
      throw error
    }
  }

  updateLawyer_specialty = async (lawyer_specialtyId, lawyer_specialtyData) => {
    try {
      const response = await this.api.put(
        `/lawyer-specialty/${lawyer_specialtyId}/`,
        lawyer_specialtyData
      )
      return response.data
    } catch (error) {
      throw error
    }
  }

  deleteLawyer_specialty = async (lawyer_specialtyId) => {
    try {
      const response = await this.api.delete(
        `/lawyer-specialty/${lawyer_specialtyId}/`
      )
      return response.data
    } catch (error) {
      throw error
    }
  }

  getLawyerList = async () => {
    try {
      const response = await this.api.get("/lawyer/")
      return response.data
    } catch (error) {
      throw error
    }
  }

  getSpecialtyList = async () => {
    try {
      const response = await this.api.get("/specialty/")
      return response.data
    } catch (error) {
      throw error
    }
  }
}

const lawyerSpecialtyService = new LawyerSpecialtyService()

export default lawyerSpecialtyService
