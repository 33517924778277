import axios from "axios"

class Lead_assignmentService {
  api
  constructor() {
    this.api = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}`,
    })
    this.api.interceptors.request.use((config) => {
      const token = localStorage.getItem("Token")

      if (token) {
        config.headers = {
          Authorization: `Token ${token}`,
          withCredentials: false,
          "Content-Type": "application/json; charset=UTF-8",
        }
      }

      return config
    })
  }

  getLead_assignmentList = async () => {
    try {
      const response = await this.api.get(`/lead-assignment/`)
      return response.data
    } catch (error) {
      throw error
    }
  }

  getLead_assignmentDetails = async (leadId) => {
    try {
      const response = await this.api.get(`/lead-assignment/${leadId}/`)
      return response.data
    } catch (error) {
      throw error
    }
  }

  createLead_assignment = async (leadData) => {
    try {
      const response = await this.api.post(`/lead-assignment/`, leadData)
      return response.data
    } catch (error) {
      throw error
    }
  }

  updateLead_assignment = async (leadId, leadData) => {
    try {
      const response = await this.api.patch(
        `/lead-assignment/${leadId}/`,
        leadData
      )
      return response.data
    } catch (error) {
      throw error
    }
  }

  deleteLead_assignment = async (leadId) => {
    try {
      const response = await this.api.delete(`/lead-assignment/${leadId}/`)
      return response.data
    } catch (error) {
      throw error
    }
  }
}

const lead_assignmentService = new Lead_assignmentService()

export default lead_assignmentService
