import { useContext } from "react"
import { Navigate, Outlet } from "react-router-dom"
import { AuthContext } from "../context/auth.context"
import { message } from "antd"
import { Spinner } from "react-bootstrap"

const PrivateRoutes = () => {
  const { isLoggedIn, isLoading, getToken, logOutUser } =
    useContext(AuthContext)
  const token = getToken()

  if (isLoading) {
    return (
      <div
        style={{
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner animation="grow" variant="primary" />
      </div>
    )
  } else {
    if (!isLoggedIn) {
      message.error("Unauthorized")
      return <Navigate to="/auths/auth-login" />
    } else if (!token) {
      logOutUser()
      message.error("Unauthorized")
      return <Navigate to="/auths/auth-login" />
    } else {
      return <Outlet />
    }
  }
}

export default PrivateRoutes
