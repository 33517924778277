import { Card, Button, Dropdown } from "react-bootstrap"
import { Link, useNavigate } from "react-router-dom"
import {
  Icon,
  CustomDropdownToggle,
  CustomDropdownMenu,
  LinkList,
} from "../../../components"
import Layout from "../../../layout/default"
import Block from "../../../components/Block/Block"
import DataTable from "../../../components/DataTable/DataTable.js"

import React, { useEffect, useState } from "react"
import mainSpecialtyService from "../services/main_specialtyService"

const Main_specialtyList = () => {
  const navigate = useNavigate()
  const [main_specialtyData, setMain_specialty] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    fetchMain_specialtyList()
  }, [])

  const fetchMain_specialtyList = async () => {
    try {
      const response = await mainSpecialtyService.getMain_specialtyList()
      setMain_specialty(response)
      setLoading(false)
    } catch (error) {
      console.log("Error fetching main_specialty list:", error)
      setLoading(false)
    }
  }

  const handleDeleteMain_specialty = async (main_specialtyId) => {
    try {
      setLoading(true)
      await mainSpecialtyService.deleteMain_specialty(main_specialtyId)
      const data = main_specialtyData.filter(
        (rows) => rows.id != main_specialtyId
      )
      setMain_specialty(data)
      setLoading(false)
    } catch (error) {
      console.log("Error deleting main_specialty:", error)
      setLoading(false)
    }
  }

  const handleCreateMain_specialty = () => {
    navigate("/main_specialty/new")
  }

  const main_specialtyColumns = [
    {
      name: "id",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "actions",
      cell: (row) => (
        <div className="text-end w-100">
          <Dropdown>
            <Dropdown.Toggle
              size="sm"
              as={CustomDropdownToggle}
              className="btn btn-sm btn-icon btn-zoom me-n1"
            >
              <Icon name="more-v" />
            </Dropdown.Toggle>
            <Dropdown.Menu
              className="dropdown-menu-sm"
              as={CustomDropdownMenu}
              align="end"
            >
              <div className="dropdown-content py-1">
                <LinkList className="link-list-hover-bg-primary link-list-md">
                  <Link
                    to={`/main_specialty/edit/${row.id}`}
                    className="dropdown-item"
                  >
                    <Icon name="edit" />
                    <span>Edit</span>
                  </Link>
                  <button
                    className="dropdown-item"
                    onClick={() => handleDeleteMain_specialty(row.id)}
                  >
                    <Icon name="trash" />
                    <span>Delete</span>
                  </button>
                </LinkList>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ),
      sortable: false,
    },
  ]

  return (
    <Layout title="Main_specialty List" content="container">
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            <Block.Title tag="h2">Main_specialty List</Block.Title>
            <nav>
              <ol className="breadcrumb breadcrumb-arrow mb-0">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Main_specialty
                </li>
              </ol>
            </nav>
          </Block.HeadContent>
          <Block.HeadContent>
            <ul className="d-flex">
              <li>
                <Button
                  className="d-md-none"
                  size="md"
                  variant="primary"
                  onClick={handleCreateMain_specialty}
                >
                  <Icon name="plus" />
                  <span>Add</span>
                </Button>
              </li>
              <li>
                <Button
                  className="d-none d-md-inline-flex"
                  variant="primary"
                  onClick={handleCreateMain_specialty}
                >
                  <Icon name="plus" />
                  <span>Add Main_specialty</span>
                </Button>
              </li>
            </ul>
          </Block.HeadContent>
        </Block.HeadBetween>
      </Block.Head>

      <Block>
        <Card>
          {!loading ? (
            <DataTable
              tableClassName="data-table-head-light table-responsive"
              data={main_specialtyData}
              columns={main_specialtyColumns}
              expandableRows
              actions
            />
          ) : (
            <p>Loading...</p>
          )}
        </Card>
      </Block>
    </Layout>
  )
}

export default Main_specialtyList
