import { Card, Button, Dropdown } from "react-bootstrap"
import { Link, useNavigate } from "react-router-dom"
import {
  Icon,
  CustomDropdownToggle,
  CustomDropdownMenu,
  LinkList,
} from "../../../components"
import Layout from "../../../layout/default"
import Block from "../../../components/Block/Block"
import DataTable from "../../../components/DataTable/DataTable.js"
import specialtyService from "../services/specialtyService"
import React, { useEffect, useState } from "react"



const SpecialtyList = () => {
  const navigate = useNavigate()
  const [specialtyData, setSpecialty] = useState([])
  const [loading, setLoading] = useState(false)
  const [main_specialtyData, setMain_specialty] = useState([])

  useEffect(() => {
    setLoading(true)
    fetchSpecialtyList()
    fetchMain_specialtyList()
  }, [])

  const fetchMain_specialtyList = async () => {
    try {
      const main_specialty = await specialtyService.getMain_specialtyList()
      setMain_specialty(main_specialty)
    } catch (error) {
      console.log("Error fetching main_specialty list:", error)
    }
  }

  const fetchSpecialtyList = async () => {
    try {
      const response = await specialtyService.getSpecialtyList()
      setSpecialty(response)
      setLoading(false)
    } catch (error) {
      console.log("Error fetching specialty list:", error)
      setLoading(false)
    }
  }

  const handleDeleteSpecialty = async (specialtyId) => {
    try {
      setLoading(true)
      await specialtyService.deleteSpecialty(specialtyId)
      const data = specialtyData.filter((rows) => rows.id != specialtyId)
      setSpecialty(data)
      setLoading(false)
    } catch (error) {
      console.log("Error deleting specialty:", error)
      setLoading(false)
    }
  }

  const handleCreateSpecialty = () => {
    navigate("/specialty/new")
  }

  const specialtyColumns = [
    {
      name: "id",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "main_specialty",
      selector: (row) => {
        const main_specialty = main_specialtyData.find(
          (main_specialty) => main_specialty.id === row.main_specialty
        )
        return main_specialty ? main_specialty.name : ""
      },
      sortable: true,
      cell: (row) => {
        const main_specialty = main_specialtyData.find(
          (main_specialty) => main_specialty.id === row.main_specialty
        )
        return main_specialty ? main_specialty.name : ""
      },
    },
    {
      name: "child_specialty",
      selector: (row) => row.child_specialty,
      sortable: true,
    },
    {
      name: "cost_per_lead",
      selector: (row) => row.cost_per_lead,
      sortable: true,
    },
    {
      name: "total_searchs",
      selector: (row) => row.total_searchs,
      sortable: true,
    },
    {
      name: "montly_searchs",
      selector: (row) => row.montly_searchs,
      sortable: true,
    },
    {
      name: "actions",
      cell: (row) => (
        <div className="text-end w-100">
          <Dropdown>
            <Dropdown.Toggle
              size="sm"
              as={CustomDropdownToggle}
              className="btn btn-sm btn-icon btn-zoom me-n1"
            >
              <Icon name="more-v" />
            </Dropdown.Toggle>
            <Dropdown.Menu
              className="dropdown-menu-sm"
              as={CustomDropdownMenu}
              align="end"
            >
              <div className="dropdown-content py-1">
                <LinkList className="link-list-hover-bg-primary link-list-md">
                  <Link
                    to={`/specialty/edit/${row.id}`}
                    className="dropdown-item"
                  >
                    <Icon name="edit" />
                    <span>Edit</span>
                  </Link>
                  <button
                    className="dropdown-item"
                    onClick={() => handleDeleteSpecialty(row.id)}
                  >
                    <Icon name="trash" />
                    <span>Delete</span>
                  </button>
                </LinkList>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ),
      sortable: false,
    },
  ]

  return (
    <Layout title="Specialty List" content="container">
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            <Block.Title tag="h2">Specialty List</Block.Title>
            <nav>
              <ol className="breadcrumb breadcrumb-arrow mb-0">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Specialty
                </li>
              </ol>
            </nav>
          </Block.HeadContent>
          <Block.HeadContent>
            <ul className="d-flex">
              <li>
                <Button
                  className="d-md-none"
                  size="md"
                  variant="primary"
                  onClick={handleCreateSpecialty}
                >
                  <Icon name="plus" />
                  <span>Add</span>
                </Button>
              </li>
              <li>
                <Button
                  className="d-none d-md-inline-flex"
                  variant="primary"
                  onClick={handleCreateSpecialty}
                >
                  <Icon name="plus" />
                  <span>Add Specialty</span>
                </Button>
              </li>
            </ul>
          </Block.HeadContent>
        </Block.HeadBetween>
      </Block.Head>

      <Block>
        <Card>
          {!loading ? (
            <DataTable
              tableClassName="data-table-head-light table-responsive"
              data={specialtyData}
              columns={specialtyColumns}
              expandableRows
              actions
            />
          ) : (
            <p>Loading...</p>
          )}
        </Card>
      </Block>
    </Layout>
  )
}

export default SpecialtyList
