import { useState, useEffect } from "react"
import { Card, Button, Row, Col, Form } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import DatePicker from "../../../components/Form/DatePicker"
import postsChoicesService from "../services/posts_choicesService"
import Layout from "../../../layout/default"
import Block from "../../../components/Block/Block"

function Posts_choicesForm() {
  const navigate = useNavigate()
  const { id } = useParams()

  const [posts_choicesData, setPosts_choicesData] = useState({
    name: "",
    lastname: "",
  })

  useEffect(() => {
    if (id) {
      fetchPosts_choices()
    }
  }, [id])

  const fetchPosts_choices = async () => {
    try {
      const posts_choices = await postsChoicesService.getPosts_choicesDetails(
        id
      )
      setPosts_choicesData(posts_choices)
    } catch (error) {
      console.log("Error fetching posts_choices:", error)
    }
  }

  const handleChange = (event) => {
    setPosts_choicesData({
      ...posts_choicesData,
      [event.target.name]: event.target.value,
    })
  }

  const handleCreateOrUpdatePosts_choices = async (event) => {
    event.preventDefault()

    try {
      if (id) {
        await postsChoicesService.updatePosts_choices(id, posts_choicesData)
      } else {
        await postsChoicesService.createPosts_choices(posts_choicesData)
      }
      navigate("/posts_choices")
    } catch (error) {
      console.log("Error creating/updating posts_choices:", error)
    }
  }

  const pageTitle = id ? "Edit Posts_choices" : "Add Posts_choices"
  const submitButtonText = id ? "Update" : "Save"

  return (
    <Layout title={pageTitle} content="container">
      <Block>
        <Card className="card-gutter-md">
          <Card.Body>
            <div className="bio-block">
              <h4 className="bio-block-title mb-4">{pageTitle}</h4>
              <Form onSubmit={handleCreateOrUpdatePosts_choices}>
                <Row className="g-3">
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="name">name</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="name"
                          placeholder="name"
                          name="name"
                          value={posts_choicesData.name}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="price">price</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="price"
                          placeholder="price"
                          name="price"
                          value={posts_choicesData.price}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Button variant="primary" type="submit">
                      {submitButtonText}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Card.Body>
        </Card>
      </Block>
    </Layout>
  )
}

export default Posts_choicesForm
