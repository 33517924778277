import { useState, useEffect } from "react"
import { Card, Button, Row, Col, Form } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import specialtyService from "../services/specialtyService"
import Layout from "../../../layout/default"
import Block from "../../../components/Block/Block"

function SpecialtyForm() {
  const navigate = useNavigate()
  const { id } = useParams()

  const [specialtyData, setSpecialtyData] = useState({
    child_specialty: "",
    cost_per_lead: "",
    total_searchs: "",
    montly_searchs: "",
  })
  const [Main_specialtyList, setMain_specialtyList] = useState([])

  useEffect(() => {
    if (id) {
      fetchSpecialty()
    }
    fetchMain_specialty()
  }, [id])

  const fetchSpecialty = async () => {
    try {
      const specialty = await specialtyService.getSpecialtyDetails(id)
      setSpecialtyData(specialty)
    } catch (error) {
      console.log("Error fetching specialty:", error)
    }
  }

  const handleChange = (event) => {
    setSpecialtyData({
      ...specialtyData,
      [event.target.name]: event.target.value,
    })
  }

  const handleCreateOrUpdateSpecialty = async (event) => {
    event.preventDefault()

    try {
      if (id) {
        await specialtyService.updateSpecialty(id, specialtyData)
      } else {
        await specialtyService.createSpecialtyCpl(specialtyData)
      }
      navigate("/specialty")
    } catch (error) {
      console.log("Error creating/updating specialty:", error)
    }
  }

  const fetchMain_specialty = async () => {
    try {
      const dataMain_specialty = await specialtyService.getMain_specialtyList()
      setMain_specialtyList(dataMain_specialty)
    } catch (error) {
      console.error("Error fetching Main_specialty:", error)
    }
  }

  const pageTitle = id ? "Edit Specialty" : "Add Specialty"
  const submitButtonText = id ? "Update" : "Save"

  return (
    <Layout title={pageTitle} content="container">
      <Block>
        <Card className="card-gutter-md">
          <Card.Body>
            <div className="bio-block">
              <h4 className="bio-block-title mb-4">{pageTitle}</h4>
              <Form onSubmit={handleCreateOrUpdateSpecialty}>
                <Row className="g-3">
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="child_specialty">
                        child_specialty
                      </Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="child_specialty"
                          placeholder="child_specialty"
                          name="child_specialty"
                          value={specialtyData.child_specialty}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="cost_per_lead">
                        cost_per_lead
                      </Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="cost_per_lead"
                          placeholder="cost_per_lead"
                          name="cost_per_lead"
                          value={specialtyData.cost_per_lead}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="total_searchs">
                        total_searchs
                      </Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="total_searchs"
                          placeholder="total_searchs"
                          name="total_searchs"
                          value={specialtyData.total_searchs}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="montly_searchs">
                        montly_searchs
                      </Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="montly_searchs"
                          placeholder="montly_searchs"
                          name="montly_searchs"
                          value={specialtyData.montly_searchs}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="Main_specialty">
                        Main_specialty
                      </Form.Label>
                      <div className="form-control-wrap">
                        <Form.Select
                          id="main_specialty"
                          name="main_specialty"
                          value={specialtyData.main_specialty}
                          onChange={handleChange}
                          className="custom-select"
                        >
                          <option value="">Select Main_specialty</option>
                          {Main_specialtyList?.map((main_specialty, idx) => {
                            return (
                              <option key={idx} value={main_specialty.id}>
                                {main_specialty.name}
                              </option>
                            )
                          })}
                        </Form.Select>
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Button variant="primary" type="submit">
                      {submitButtonText}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Card.Body>
        </Card>
      </Block>
    </Layout>
  )
}

export default SpecialtyForm
