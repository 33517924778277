import React, { createContext, useState } from "react"
import { useEffect } from "react"
import leadService from "../modules/lead/services/leadService"

const CommonsDataContext = createContext()
function CommonsDataProviderWrapper(props) {
  const [isLoading, setIsLoading] = useState(false)
  const [location, setLocation] = useState([])
  const [province, setProvince] = useState([])

  const fetchLocationList = async () => {
    try {
      const location = await leadService.getLocationList()
      setLocation(location)
    } catch (error) {
      console.log("Error fetching location list:", error)
    }
  }

  const fetchProvinceList = async () => {
    try {
      const province = await leadService.getProvinceList()
      setProvince(province)
    } catch (error) {
      console.log("Error fetching province list:", error)
    }
  }
  const loadData = async () => {
    try {
      setIsLoading(true)
      // COMENTO PORQUE NO SE USA Y EMPEORA RENDIMIENTO
      // await Promise.all([fetchLocationList(), fetchProvinceList()])
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    loadData()
  }, [])

  return (
    <CommonsDataContext.Provider
      value={{
        isLoading,
        setIsLoading,
        location,
        setLocation,
        province,
        setProvince,
        loadData,
      }}
    >
      {props.children}
    </CommonsDataContext.Provider>
  )
}

export { CommonsDataContext, CommonsDataProviderWrapper }
