import axios from "axios"

class LeadStateService {
  api
  constructor() {
    this.api = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}`,
    })
    this.api.interceptors.request.use((config) => {
      const token = localStorage.getItem("Token")

      if (token) {
        config.headers = {
          Authorization: `Token ${token}`,
          withCredentials: false,
          "Content-Type": "application/json; charset=UTF-8",
        }
      }

      return config
    })
  }

  getLead_stateList = async () => {
    try {
      const response = await this.api.get(`/lead-state/`)
      return response.data
    } catch (error) {
      throw error
    }
  }

  getLead_stateDetails = async (lead_stateId) => {
    try {
      const response = await this.api.get(`/lead-state/${lead_stateId}/`)
      return response.data
    } catch (error) {
      throw error
    }
  }

  createLead_state = async (lead_stateData) => {
    try {
      const response = await this.api.post(`/lead-state/`, lead_stateData)
      return response.data
    } catch (error) {
      throw error
    }
  }

  updateLead_state = async (lead_stateId, lead_stateData) => {
    try {
      const response = await this.api.put(
        `/lead-state/${lead_stateId}/`,
        lead_stateData
      )
      return response.data
    } catch (error) {
      throw error
    }
  }

  deleteLead_state = async (lead_stateId) => {
    try {
      const response = await this.api.delete(`/lead-state/${lead_stateId}/`)
      return response.data
    } catch (error) {
      throw error
    }
  }
}

const leadStateService = new LeadStateService()

export default leadStateService
