import { Link } from "react-router-dom"

function Logo() {
  return (
    <Link to="/" className="logo-link">
      <img src="/Slide16_9-12.png" alt="Abogueo Icon" />
    </Link>
  )
} 

export default Logo
