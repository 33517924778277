import { Card, Button, Dropdown, Badge } from "react-bootstrap"
import { Link, useNavigate } from "react-router-dom"
import Layout from "../../../layout/default"
import Block from "../../../components/Block/Block"
import DataTable from "../../../components/DataTable/DataTable.js"
import React, { useEffect, useRef, useState } from "react"
import {
  Icon,
  CustomDropdownToggle,
  CustomDropdownMenu,
  LinkList,
} from "../../../components"
import cost_per_leadListService from "../services/cost_per_leadService"
import { message } from "antd"

const Cost_per_leadList = () => {
  const navigate = useNavigate()
  const [cost_per_lead, setCost_per_lead] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    fetchAll()
  }, [])

  const fetchAll = async () => {
    try {
      setLoading(true)
      await fetchCost_per_leadList()
    } catch (error) {
      console.error(error)
      message.error("Error al obtener CPLs")
    } finally {
      setLoading(false)
    }
  }

  const fetchCost_per_leadList = async () => {
    const response = await cost_per_leadListService.getCost_per_leadFullList()
    response.map(
      (res) =>
        (res.cpl = parseFloat(res.cpl).toLocaleString("es-ES", {
          currency: "EUR",
          minimumFractionDigits: 2,
        }))
    )
    setCost_per_lead(response)
  }

  const handleDeleteLead = async (leadId) => {
    try {
      setLoading(true)
      await cost_per_leadListService.deleteCost_per_lead(leadId)
      const data = cost_per_lead.filter((rows) => rows.id != leadId)
      setCost_per_lead(data)
      setLoading(false)
    } catch (error) {
      console.log("Error deleting lead:", error)
      setLoading(false)
    }
  }

  const handleCreateLead = () => {
    navigate("/cpl/new")
  }

  const columns = [
    {
      name: "id",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "cpl",
      selector: (row) => row.cpl,
      sortable: true,
    },
    {
      name: "specialty",
      selector: (row) => row.specialty,
    },
    {
      name: "province",
      selector: (row) => row.province || "Todo España",
    },
    {
      name: "actions",
      cell: (row) => (
        <div className="text-end w-100">
          <Dropdown>
            <Dropdown.Toggle
              size="sm"
              as={CustomDropdownToggle}
              className="btn btn-sm btn-icon btn-zoom me-n1"
            >
              <Icon name="more-v" />
            </Dropdown.Toggle>
            <Dropdown.Menu
              className="dropdown-menu-sm"
              as={CustomDropdownMenu}
              align="end"
            >
              <div className="dropdown-content py-1">
                <LinkList className="link-list-hover-bg-primary link-list-md">
                  <Link to={`/cpl/edit/${row.id}`} className="dropdown-item">
                    <Icon name="edit" />
                    <span>Edit</span>
                  </Link>
                  <button
                    className="dropdown-item"
                    onClick={() => handleDeleteLead(row.id)}
                  >
                    <Icon name="trash" />
                    <span>Delete</span>
                  </button>
                </LinkList>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ),
      sortable: false,
    },
  ]
  // Referencia al input de tipo file
  const fileInputRef = useRef(null)

  // Función para manejar el clic en el botón y abrir el diálogo de selección de archivos
  const handleUploadClick = () => {
    fileInputRef.current.click()
  }

  // Función para manejar la selección de archivos
  // Función para manejar la selección de archivos
  const handleFileChange = async (event) => {
    try {
      const file = event.target.files[0]
      if (!file) return
      const formData = new FormData()
      formData.append("file", file)
      setLoading(true)
      const response = await cost_per_leadListService.cost_per_leadBulkUpdate(
        formData
      )
      response.map(
        (res) =>
          (res.cpl = parseFloat(res.cpl).toLocaleString("es-ES", {
            currency: "EUR",
            minimumFractionDigits: 2,
          }))
      )

      setCost_per_lead(response)
    } catch (error) {
      console.error("Error updating lead:", error)
      if (error.response.data.detail) {
        message.error(`Error Actualizando lead: ${error.response.data.detail}`)
      } else {
        message.error(`Error Actualizando CPL`)
      }
    } finally {
      setLoading(false)
      // Resetea el valor del input de tipo file
      event.target.value = "" // Esto permite que el mismo archivo sea seleccionado nuevamente
    }
  }

  return (
    <Layout title="Coste por Lead" content="container" loading={loading}>
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            <Block.Title tag="h2">Coste por Lead</Block.Title>
            <nav>
              <ol className="breadcrumb breadcrumb-arrow mb-0">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Coste por Lead
                </li>
              </ol>
            </nav>
          </Block.HeadContent>
          <Block.HeadContent>
            <ul className="d-flex" style={{ gap: "1rem" }}>
              <li>
                <Button
                  className="d-none d-md-inline-flex"
                  variant="primary"
                  onClick={handleUploadClick}
                >
                  <Icon name="file" />
                  <span>Actualizar CPL</span>
                </Button>
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                  accept=".xls, .xlsx, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                />
              </li>

              <li>
                <Button
                  className="d-none d-md-inline-flex"
                  variant="primary"
                  onClick={handleCreateLead}
                >
                  <Icon name="plus" />
                  <span>Añadir CPL</span>
                </Button>
              </li>
            </ul>
          </Block.HeadContent>
        </Block.HeadBetween>
      </Block.Head>

      <Block>
        <Card>
          {!loading && (
            <DataTable
              tableClassName="data-table-head-light table-responsive"
              data={cost_per_lead}
              columns={columns}
              searchByList={["specialty", "province"]}
              expandableRows
              actions
            />
          )}
        </Card>
      </Block>
    </Layout>
  )
}

export default Cost_per_leadList
