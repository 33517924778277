import axios, { Axios } from "axios"

class LocationService {
  api
  constructor() {
    this.api = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}/location`,
    })
    this.api.interceptors.request.use((config) => {
      const token = localStorage.getItem("Token")

      if (token) {
        config.headers = {
          Authorization: `Token ${token}`,
        }
      }

      return config
    })
  }
  getLocationList = async () => {
    try {
      const response = await this.api.get(`/`)
      return response.data
    } catch (error) {
      throw error
    }
  }

  getLocationDetails = async (locationId) => {
    try {
      const response = await this.api.get(`/${locationId}/`)
      return response.data
    } catch (error) {
      throw error
    }
  }

  createLocation = async (locationData) => {
    try {
      const response = await this.api.post(`/`, locationData)
      return response.data
    } catch (error) {
      throw error
    }
  }

  updateLocation = async (locationId, locationData) => {
    try {
      const response = await this.api.put(`/${locationId}/`, locationData)
      return response.data
    } catch (error) {
      throw error
    }
  }

  deleteLocation = async (locationId) => {
    try {
      const response = await this.api.delete(`/${locationId}/`)
      return response.data
    } catch (error) {
      throw error
    }
  }
}

const locationService = new LocationService()

export default locationService
