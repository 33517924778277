import { useState, useEffect } from "react"
import { Card, Button, Row, Col, Form } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import lawyerLanguageService from "../services/lawyer_languagesService"
import Layout from "../../../layout/default"
import Block from "../../../components/Block/Block"

function Lawyer_languagesForm() {
  const navigate = useNavigate()
  const { id } = useParams()

  const [lawyer_languagesData, setLawyer_languagesData] = useState({})
  const [LanguageList, setLanguageList] = useState([])
  const [LawyerList, setLawyerList] = useState([])

  useEffect(() => {
    if (id) {
      fetchLawyer_languages()
    }
    fetchLanguage()
    fetchLawyer()
  }, [id])

  const fetchLawyer_languages = async () => {
    try {
      const lawyer_languages =
        await lawyerLanguageService.getLawyer_languagesDetails(id)
      setLawyer_languagesData(lawyer_languages)
    } catch (error) {
      console.log("Error fetching lawyer_languages:", error)
    }
  }

  const handleChange = (event) => {
    setLawyer_languagesData({
      ...lawyer_languagesData,
      [event.target.name]: event.target.value,
    })
  }

  const handleCreateOrUpdateLawyer_languages = async (event) => {
    event.preventDefault()

    try {
      if (id) {
        await lawyerLanguageService.updateLawyer_languages(
          id,
          lawyer_languagesData
        )
      } else {
        await lawyerLanguageService.createLawyer_languages(lawyer_languagesData)
      }
      navigate("/lawyer_languages")
    } catch (error) {
      console.log("Error creating/updating lawyer_languages:", error)
    }
  }

  const fetchLanguage = async () => {
    try {
      const dataLanguage = await lawyerLanguageService.getLanguageList()
      setLanguageList(dataLanguage)
    } catch (error) {
      console.error("Error fetching Language:", error)
    }
  }

  const fetchLawyer = async () => {
    try {
      const dataLawyer = await lawyerLanguageService.getLawyerList()
      setLawyerList(dataLawyer)
    } catch (error) {
      console.error("Error fetching Lawyer:", error)
    }
  }

  const pageTitle = id ? "Edit Lawyer_languages" : "Add Lawyer_languages"
  const submitButtonText = id ? "Update" : "Save"

  return (
    <Layout title={pageTitle} content="container">
      <Block>
        <Card className="card-gutter-md">
          <Card.Body>
            <div className="bio-block">
              <h4 className="bio-block-title mb-4">{pageTitle}</h4>
              <Form onSubmit={handleCreateOrUpdateLawyer_languages}>
                <Row className="g-3">
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="Language">Language</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Select
                          id="language"
                          name="language"
                          value={lawyer_languagesData.language}
                          onChange={handleChange}
                          className="custom-select"
                        >
                          <option value="">Select Language</option>
                          {LanguageList?.map((language, idx) => {
                            return (
                              <option key={idx} value={language.id}>
                                {language.name}
                              </option>
                            )
                          })}
                        </Form.Select>
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="Lawyer">Lawyer</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Select
                          id="lawyer"
                          name="lawyer"
                          value={lawyer_languagesData.lawyer}
                          onChange={handleChange}
                          className="custom-select"
                        >
                          <option value="">Select Lawyer</option>
                          {LawyerList?.map((lawyer, idx) => {
                            return (
                              <option key={idx} value={lawyer.id}>
                                {lawyer.name}
                              </option>
                            )
                          })}
                        </Form.Select>
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Button variant="primary" type="submit">
                      {submitButtonText}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Card.Body>
        </Card>
      </Block>
    </Layout>
  )
}

export default Lawyer_languagesForm
