import { useState, useEffect } from "react"
import { Card, Button, Row, Col, Form } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import DatePicker from "../../../components/Form/DatePicker"
import lawyerSpecialtyService from "../services/lawyer_specialtyService"
import Layout from "../../../layout/default"
import Block from "../../../components/Block/Block"

function Lawyer_specialtyForm() {
  const navigate = useNavigate()
  const { id } = useParams()

  const [lawyer_specialtyData, setLawyer_specialtyData] = useState({})
  const [LawyerList, setLawyerList] = useState([])
  const [SpecialtyList, setSpecialtyList] = useState([])

  useEffect(() => {
    if (id) {
      fetchLawyer_specialty()
    }
    fetchLawyer()
    fetchSpecialty()
  }, [id])

  const fetchLawyer_specialty = async () => {
    try {
      const lawyer_specialty =
        await lawyerSpecialtyService.getLawyer_specialtyDetails(id)
      setLawyer_specialtyData(lawyer_specialty)
    } catch (error) {
      console.log("Error fetching lawyer_specialty:", error)
    }
  }

  const handleChange = (event) => {
    setLawyer_specialtyData({
      ...lawyer_specialtyData,
      [event.target.name]: event.target.value,
    })
  }

  const handleCreateOrUpdateLawyer_specialty = async (event) => {
    event.preventDefault()

    try {
      if (id) {
        await lawyerSpecialtyService.updateLawyer_specialty(
          id,
          lawyer_specialtyData
        )
      } else {
        await lawyerSpecialtyService.createLawyer_specialty(
          lawyer_specialtyData
        )
      }
      navigate("/lawyer_specialty")
    } catch (error) {
      console.log("Error creating/updating lawyer_specialty:", error)
    }
  }

  const fetchLawyer = async () => {
    try {
      const dataLawyer = await lawyerSpecialtyService.getLawyerList()
      setLawyerList(dataLawyer)
    } catch (error) {
      console.error("Error fetching Lawyer:", error)
    }
  }

  const fetchSpecialty = async () => {
    try {
      const dataSpecialty = await lawyerSpecialtyService.getSpecialtyList()
      setSpecialtyList(dataSpecialty)
    } catch (error) {
      console.error("Error fetching Specialty:", error)
    }
  }

  const pageTitle = id ? "Edit Lawyer_specialty" : "Add Lawyer_specialty"
  const submitButtonText = id ? "Update" : "Save"

  return (
    <Layout title={pageTitle} content="container">
      <Block>
        <Card className="card-gutter-md">
          <Card.Body>
            <div className="bio-block">
              <h4 className="bio-block-title mb-4">{pageTitle}</h4>
              <Form onSubmit={handleCreateOrUpdateLawyer_specialty}>
                <Row className="g-3">
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="Lawyer">Lawyer</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Select
                          id="lawyer"
                          name="lawyer"
                          value={lawyer_specialtyData.lawyer}
                          onChange={handleChange}
                          className="custom-select"
                        >
                          <option value="">Select Lawyer</option>
                          {LawyerList?.map((lawyer, idx) => {
                            return (
                              <option key={idx} value={lawyer.id}>
                                {lawyer.email}
                              </option>
                            )
                          })}
                        </Form.Select>
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="Specialty">Specialty</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Select
                          id="specialty"
                          name="specialty"
                          value={lawyer_specialtyData.specialty}
                          onChange={handleChange}
                          className="custom-select"
                        >
                          <option value="">Select Specialty</option>
                          {SpecialtyList?.map((specialty, idx) => {
                            return (
                              <option key={idx} value={specialty.id}>
                                {specialty.child_specialty}
                              </option>
                            )
                          })}
                        </Form.Select>
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Button variant="primary" type="submit">
                      {submitButtonText}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Card.Body>
        </Card>
      </Block>
    </Layout>
  )
}

export default Lawyer_specialtyForm
