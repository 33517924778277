import { Card } from "react-bootstrap"
import { Link } from "react-router-dom"
import Layout from "../../../layout/default"
import Block from "../../../components/Block/Block"
import DataTable from "../../../components/DataTable/DataTable.js"
import React, { useEffect, useState } from "react"
import campaignService from "../services/campaignService"

const AggregateCampaign = () => {
  const [campaignData, setCampaign] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    fetchCampaigns()
  }, [])

  const fetchCampaigns = async () => {
    try {
      setLoading(true)
      const response = await campaignService.getAggregateCampaigns()
      setCampaign(response)
    } catch (error) {
      console.log("Error fetching campaign list:", error)
    } finally {
      setLoading(false)
    }
  }

  const campaignColumns = [
    {
      name: "Especialidad",
      selector: (row) => row.Specialty,
      sortable: true,
    },
    {
      name: "Localización",
      selector: (row) => row?.Location,
      sortable: true,
      cell: (row) => row?.Location,
    },
    {
      name: "Nº de campañas activas",
      selector: (row) => row?.ActiveCampaigns,
      sortable: true,
      cell: (row) => row?.ActiveCampaigns,
    },
    {
      name: "Leads estimados",
      selector: (row) => row?.EstimatedLeads,
      sortable: true,
      cell: (row) => row?.EstimatedLeads,
    },
    {
      name: "Leads pendientes",
      selector: (row) => row?.RemainingLeads,
      sortable: true,
      cell: (row) => row?.RemainingLeads,
    },
    {
      name: "Leads entregados",
      selector: (row) => row?.AssignedLeads,
      sortable: true,
      cell: (row) => row?.AssignedLeads,
    },
    {
      name: "CPL",
      selector: (row) => row?.cpl,
      sortable: true,
      cell: (row) => `${row?.cpl} €`,
    },
    {
      name: "Presupuesto total",
      selector: (row) => row?.CampaignAmount,
      sortable: true,
      cell: (row) => `${row?.CampaignAmount} €`,
    },
    {
      name: "Presupuesto consumido",
      selector: (row) => row?.ConsumedBudget,
      sortable: true,
      cell: (row) => `${row?.ConsumedBudget} €`,
    },
    {
      name: "Presupuesto disponible",
      selector: (row) => row?.RemainingBudget,
      sortable: true,
      cell: (row) => `${row?.RemainingBudget} €`,
    },
  ]

  return (
    <Layout title="Campañas" content="container" loading={loading}>
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            <Block.Title tag="h2">Campañas Agregadas</Block.Title>
            <nav>
              <ol className="breadcrumb breadcrumb-arrow mb-0">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Campañas Agregadas
                </li>
              </ol>
            </nav>
          </Block.HeadContent>
        </Block.HeadBetween>
      </Block.Head>

      <Block>
        <Card>
          {!loading && (
            <DataTable
              tableClassName="data-table-head-light table-responsive"
              data={campaignData}
              columns={campaignColumns}
              expandableRows
              actions
              searchByList={["Specialty", "Location"]}
            />
          )}
        </Card>
      </Block>
    </Layout>
  )
}

export default AggregateCampaign
