import React from "react"
import { Routes, Route } from "react-router-dom"
import { ScrollToTop } from "../components"
import Blank from "../pages/Blank"
import Home from "../pages/Home"
import HomeEcommerce from "../pages/HomeEcommerce"
import HomeProject from "../pages/HomeProject"
import HomeMarketing from "../pages/HomeMarketing"
import HomeNFT from "../pages/HomeNFT"
import AuthRegister from "../pages/auths/AuthRegister"
import AuthLogin from "../pages/auths/AuthLogin"
import AuthReset from "../pages/auths/AuthReset"
import NotFound from "../pages/error/NotFound"
import { LocationList, LocationForm } from "../modules/location"
import { ProvinceList, ProvinceForm } from "../modules/province"
import { LanguageList, LanguageForm } from "../modules/language"
import {
  Lawyer_languagesList,
  Lawyer_languagesForm,
} from "../modules/lawyer_languages"
import { LeadList, LeadForm } from "../modules/lead"
import { Lead_stateList, Lead_stateForm } from "../modules/lead_state"
import { SpecialtyList, SpecialtyForm } from "../modules/specialty"
import {
  Main_specialtyList,
  Main_specialtyForm,
} from "../modules/main_specialty"
import { Posts_planList, Posts_planForm } from "../modules/posts_plan"
import { Posts_choicesList, Posts_choicesForm } from "../modules/posts_choices"
import { Vip_planList, Vip_planForm } from "../modules/vip_plan"
import {
  CampaignList,
  CampaignForm,
  AggregateCampaign,
} from "../modules/campaign"
import { 
  CampaignCustomList, 
  CampaignCustomForm 
} from "../modules/custom_campaign"
import {
  Lawyer_campaignList,
  Lawyer_campaignForm,
} from "../modules/lawyer_campaign"
import {
  Lawyer_specialtyList,
  Lawyer_specialtyForm,
} from "../modules/lawyer_specialty"
import { LawyerList, LawyerForm, LawyerDetails } from "../modules/lawyer"
import { Lawyer_schoolList, Lawyer_schoolForm } from "../modules/lawyer_school"
import { NotificationList, NotificationForm } from "../modules/notification"
import {
  Notification_settingsList,
  Notification_settingsForm,
} from "../modules/notification_settings"
import {
  Payment_methodList,
  Payment_methodForm,
} from "../modules/payment_method"
import { BillingList, BillingForm } from "../modules/billing"
import { UserList, UserForm } from "../modules/user"
import PrivateRoutes from "./PrivateRoutes"
import { InvoiceList, InvoiceForm } from "../modules/invoice"
import { Cost_per_leadForm, Cost_per_leadList } from "../modules/cost_per_lead"
import KanbanBasicPage from "../pages/kanban/KanbanBasic"
import {
  Lead_assignmentForm,
  Lead_assignmentList,
} from "../modules/Lead_assignment"

function Router() {
  return (
    <ScrollToTop>
      <Routes>
        <Route path="blank" element={<PrivateRoutes />}>
          <Route path="" element={<Blank />} />
        </Route>
        <Route path="/" element={<PrivateRoutes />}>
          <Route path="" element={<Home />} />
        </Route>

        <Route path="/kanban" element={<PrivateRoutes />}>
          <Route path="" element={<KanbanBasicPage />} />
        </Route>

        <Route path="/home" element={<PrivateRoutes />}>
          <Route path="" element={<Home />} />
        </Route>
        <Route path="home-ecommerce" element={<PrivateRoutes />}>
          <Route path="" element={<HomeEcommerce />} />
        </Route>
        <Route path="home-project" element={<PrivateRoutes />}>
          <Route path="" element={<HomeProject />} />
        </Route>
        <Route path="home-marketing" element={<PrivateRoutes />}>
          <Route path="" element={<HomeMarketing />} />
        </Route>
        <Route path="home-nft" element={<PrivateRoutes />}>
          <Route path="" element={<HomeNFT />} />
        </Route>

        <Route path="auths">
          {/* <Route path="auth-register" element={<AuthRegister />} /> */}
          <Route path="auth-login" element={<AuthLogin />} />
          {/* <Route path="auth-reset" element={<AuthReset />} /> */}
        </Route>
        <Route path="not-found" element={<NotFound />} />
        <Route path="*" element={<NotFound />} />

        <Route path="location" element={<PrivateRoutes />}>
          <Route path="/location" element={<LocationList />} />
          <Route path="/location/new" element={<LocationForm />} />
          <Route path="/location/edit/:id" element={<LocationForm />} />
        </Route>

        <Route path="province" element={<PrivateRoutes />}>
          <Route path="/province" element={<ProvinceList />} />
          <Route path="/province/new" element={<ProvinceForm />} />
          <Route path="/province/edit/:id" element={<ProvinceForm />} />
        </Route>

        <Route path="language" element={<PrivateRoutes />}>
          <Route path="/language" element={<LanguageList />} />
          <Route path="/language/new" element={<LanguageForm />} />
          <Route path="/language/edit/:id" element={<LanguageForm />} />
        </Route>

        <Route path="lawyer_languages" element={<PrivateRoutes />}>
          <Route path="/lawyer_languages" element={<Lawyer_languagesList />} />
          <Route
            path="/lawyer_languages/new"
            element={<Lawyer_languagesForm />}
          />
          <Route
            path="/lawyer_languages/edit/:id"
            element={<Lawyer_languagesForm />}
          />
        </Route>

        <Route path="lead" element={<PrivateRoutes />}>
          <Route path="/lead" element={<LeadList />} />
          <Route path="/lead/new" element={<LeadForm />} />
          <Route path="/lead/edit/:id" element={<LeadForm />} />
        </Route>
        <Route path="lead-assignment" element={<PrivateRoutes />}>
          <Route path="/lead-assignment" element={<Lead_assignmentList />} />
          <Route
            path="/lead-assignment/new"
            element={<Lead_assignmentForm />}
          />
          <Route
            path="/lead-assignment/edit/:id"
            element={<Lead_assignmentForm />}
          />
        </Route>

        <Route path="cpl" element={<PrivateRoutes />}>
          <Route path="/cpl" element={<Cost_per_leadList />} />
          <Route path="/cpl/new" element={<Cost_per_leadForm />} />
          <Route path="/cpl/edit/:id" element={<Cost_per_leadForm />} />
        </Route>

        <Route path="lead_state" element={<PrivateRoutes />}>
          <Route path="/lead_state" element={<Lead_stateList />} />
          <Route path="/lead_state/new" element={<Lead_stateForm />} />
          <Route path="/lead_state/edit/:id" element={<Lead_stateForm />} />
        </Route>

        <Route path="specialty" element={<PrivateRoutes />}>
          <Route path="/specialty" element={<SpecialtyList />} />
          <Route path="/specialty/new" element={<SpecialtyForm />} />
          <Route path="/specialty/edit/:id" element={<SpecialtyForm />} />
        </Route>

        <Route path="main_specialty" element={<PrivateRoutes />}>
          <Route path="/main_specialty" element={<Main_specialtyList />} />
          <Route path="/main_specialty/new" element={<Main_specialtyForm />} />
          <Route
            path="/main_specialty/edit/:id"
            element={<Main_specialtyForm />}
          />
        </Route>

        <Route path="posts_plan" element={<PrivateRoutes />}>
          <Route path="/posts_plan" element={<Posts_planList />} />
          <Route path="/posts_plan/new" element={<Posts_planForm />} />
          <Route path="/posts_plan/edit/:id" element={<Posts_planForm />} />
        </Route>

        <Route path="posts_choices" element={<PrivateRoutes />}>
          <Route path="/posts_choices" element={<Posts_choicesList />} />
          <Route path="/posts_choices/new" element={<Posts_choicesForm />} />
          <Route
            path="/posts_choices/edit/:id"
            element={<Posts_choicesForm />}
          />
        </Route>

        <Route path="vip_plan" element={<PrivateRoutes />}>
          <Route path="/vip_plan" element={<Vip_planList />} />
          <Route path="/vip_plan/new" element={<Vip_planForm />} />
          <Route path="/vip_plan/edit/:id" element={<Vip_planForm />} />
        </Route>

        <Route path="campaign" element={<PrivateRoutes />}>
          <Route path="/campaign" element={<CampaignList />} />
          <Route path="/campaign/new" element={<CampaignForm />} />
          <Route path="/campaign/edit/:id" element={<CampaignForm />} />
        </Route>

        <Route path="campaign-custom" element={<PrivateRoutes />}>
          <Route path="/campaign-custom" element={<CampaignCustomList />} />
          <Route path="/campaign-custom/new" element={<CampaignCustomForm />} />
          <Route path="/campaign-custom/edit/:id" element={<CampaignCustomForm />} />
        </Route>

        <Route path="aggregate" element={<PrivateRoutes />}>
          <Route path="/aggregate/campaign" element={<AggregateCampaign />} />
        </Route>

        <Route path="lawyer_campaign" element={<PrivateRoutes />}>
          <Route path="/lawyer_campaign" element={<Lawyer_campaignList />} />
          <Route
            path="/lawyer_campaign/new"
            element={<Lawyer_campaignForm />}
          />
          <Route
            path="/lawyer_campaign/edit/:id"
            element={<Lawyer_campaignForm />}
          />
        </Route>

        <Route path="lawyer_specialty" element={<PrivateRoutes />}>
          <Route path="/lawyer_specialty" element={<Lawyer_specialtyList />} />
          <Route
            path="/lawyer_specialty/new"
            element={<Lawyer_specialtyForm />}
          />
          <Route
            path="/lawyer_specialty/edit/:id"
            element={<Lawyer_specialtyForm />}
          />
        </Route>

        <Route path="lawyer" element={<PrivateRoutes />}>
          <Route path="/lawyer" element={<LawyerList />} />
          <Route path="/lawyer/new" element={<LawyerForm />} />
          <Route path="/lawyer/edit/:id" element={<LawyerForm />} />
          <Route path="/lawyer/:id" element={<LawyerDetails />} />
        </Route>

        <Route path="lawyer_school" element={<PrivateRoutes />}>
          <Route path="/lawyer_school" element={<Lawyer_schoolList />} />
          <Route path="/lawyer_school/new" element={<Lawyer_schoolForm />} />
          <Route
            path="/lawyer_school/edit/:id"
            element={<Lawyer_schoolForm />}
          />
        </Route>

        <Route path="notification" element={<PrivateRoutes />}>
          <Route path="/notification" element={<NotificationList />} />
          <Route path="/notification/new" element={<NotificationForm />} />
          <Route path="/notification/edit/:id" element={<NotificationForm />} />
        </Route>

        <Route path="notification_settings" element={<PrivateRoutes />}>
          <Route
            path="/notification_settings"
            element={<Notification_settingsList />}
          />
          <Route
            path="/notification_settings/new"
            element={<Notification_settingsForm />}
          />
          <Route
            path="/notification_settings/edit/:id"
            element={<Notification_settingsForm />}
          />
        </Route>

        <Route path="payment_method" element={<PrivateRoutes />}>
          <Route path="/payment_method" element={<Payment_methodList />} />
          <Route path="/payment_method/new" element={<Payment_methodForm />} />
          <Route
            path="/payment_method/edit/:id"
            element={<Payment_methodForm />}
          />
        </Route>

        <Route path="billing" element={<PrivateRoutes />}>
          <Route path="/billing" element={<BillingList />} />
          <Route path="/billing/new" element={<BillingForm />} />
          <Route path="/billing/edit/:id" element={<BillingForm />} />
        </Route>

        <Route path="user" element={<PrivateRoutes />}>
          <Route path="/user" element={<UserList />} />
          <Route path="/user/new" element={<UserForm />} />
          <Route path="/user/edit/:id" element={<UserForm />} />
        </Route>

        <Route path="invoice" element={<PrivateRoutes />}>
          <Route path="/invoice" element={<InvoiceList />} />
          <Route path="/invoice/new" element={<InvoiceForm />} />
          <Route path="/invoice/edit/:id" element={<InvoiceForm />} />
        </Route>
      </Routes>
    </ScrollToTop>
  )
}

export default Router
