import { useState, useEffect } from "react"
import { Card, Button, Row, Col, Form } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import Layout from "../../../layout/default"
import Block from "../../../components/Block/Block"
import cost_per_leadListService from "../services/cost_per_leadService"
import { message } from "antd"

function Cost_per_leadForm() {
  const navigate = useNavigate()
  const { id } = useParams()
  const [loading, setLoading] = useState(false)
  const [initialLoading, setInitialLoading] = useState(false)
  const [leadData, setLeadData] = useState({
    cpl: "",
  })
  const [Specialty, setSpecialty] = useState([])
  const [Province, setProvince] = useState([])

  useEffect(() => {
    if (id) {
      fetchCost_per_lead()
    }
    fetchSpecialtyList()
    fetchProvinceList()
  }, [id])

  const fetchSpecialtyList = async () => {
    try {
      const specialty = await cost_per_leadListService.getSpecialtyList()
      setSpecialty(specialty)
    } catch (error) {
      console.log("Error fetching specialty list:", error)
    }
  }

  const fetchProvinceList = async () => {
    try {
      const province = await cost_per_leadListService.getProvinceList()
      setProvince(province)
    } catch (error) {
      console.log("Error fetching province list:", error)
    }
  }

  const fetchCost_per_lead = async () => {
    try {
      setInitialLoading(true)
      const res = await cost_per_leadListService.getCost_per_leadDetails(id)
      setLeadData(res)
    } catch (error) {
      console.log("Error fetching lead:", error)
    } finally {
      setInitialLoading(false)
    }
  }

  const handleChange = (event) => {
    setLeadData({ ...leadData, [event.target.name]: event.target.value })
  }

  const handleCreateOrUpdateLead = async (event) => {
    event.preventDefault()

    try {
      setLoading(true)
      if (id) {
        await cost_per_leadListService.updateCost_per_lead(id, leadData)
      } else {
        await cost_per_leadListService.createCost_per_lead(leadData)
      }
      navigate("/cpl")
      message.success("CPL actualizado correctamente")
    } catch (error) {
      console.log("Error creating/updating cpl:", error)
    } finally {
      setLoading(false)
    }
  }

  const pageTitle = id ? "Editar CPL" : "Añadir CPL"
  const submitButtonText = id ? "Actualizar" : "Guardar"

  return (
    <Layout title={pageTitle} content="container" loading={initialLoading}>
      <Block>
        <Card className="card-gutter-md">
          <Card.Body>
            <div className="bio-block">
              <h4 className="bio-block-title mb-4">{pageTitle}</h4>
              <Form onSubmit={handleCreateOrUpdateLead}>
                <Row className="g-3">
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="name">CPL</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="number"
                          id="cpl"
                          placeholder="cpl"
                          name="cpl"
                          value={leadData.cpl}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="specialty">specialty</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Select
                          id="specialty"
                          name="specialty"
                          value={leadData.specialty}
                          onChange={handleChange}
                          className="custom-select"
                        >
                          <option value="">Select specialty</option>
                          {Specialty?.map((specialty, idx) => {
                            return (
                              <option key={idx} value={specialty.id}>
                                {specialty.child_specialty}
                              </option>
                            )
                          })}
                        </Form.Select>
                      </div>
                    </Form.Group>
                  </Col>

                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="province">province</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Select
                          id="province"
                          name="province"
                          value={leadData.province}
                          onChange={handleChange}
                          className="custom-select"
                        >
                          <option value="">Select province</option>
                          {Province?.map((province, idx) => {
                            return (
                              <option key={idx} value={province.id}>
                                {province.name}
                              </option>
                            )
                          })}
                        </Form.Select>
                      </div>
                    </Form.Group>
                  </Col>

                  <Col lg="12">
                    <Button variant="primary" type="submit">
                      {loading ? "cargando..." : submitButtonText}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Card.Body>
        </Card>
      </Block>
    </Layout>
  )
}

export default Cost_per_leadForm
