import axios from "axios"

class ProvinceService {
  api
  constructor() {
    this.api = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}/province`,
    })
    this.api.interceptors.request.use((config) => {
      const token = localStorage.getItem("Token")

      if (token) {
        config.headers = {
          Authorization: `Token ${token}`,
          withCredentials: false,
          "Content-Type": "application/json; charset=UTF-8",
        }
      }

      return config
    })
  }

  getProvinceList = async () => {
    try {
      const response = await this.api.get(`/`)
      return response.data
    } catch (error) {
      throw error
    }
  }

  getProvinceDetails = async (provinceId) => {
    try {
      const response = await this.api.get(`/${provinceId}/`)
      return response.data
    } catch (error) {
      throw error
    }
  }

  createProvince = async (provinceData) => {
    try {
      const response = await this.api.post(`/`, provinceData)
      return response.data
    } catch (error) {
      throw error
    }
  }

  updateProvince = async (provinceId, provinceData) => {
    try {
      const response = await this.api.put(`/${provinceId}/`, provinceData)
      return response.data
    } catch (error) {
      throw error
    }
  }

  deleteProvince = async (provinceId) => {
    try {
      const response = await this.api.delete(`/${provinceId}/`)
      return response.data
    } catch (error) {
      throw error
    }
  }
}

const provinceService = new ProvinceService()

export default provinceService
