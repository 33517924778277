import { Card, Button, Dropdown } from "react-bootstrap"
import { Link, useNavigate } from "react-router-dom"
import {
  Icon,
  CustomDropdownToggle,
  CustomDropdownMenu,
  LinkList,
} from "../../../components"
import Layout from "../../../layout/default"
import Block from "../../../components/Block/Block"
import DataTable from "../../../components/DataTable/DataTable.js"
import paymentMethodService from "../services/payment_methodService"
import React, { useEffect, useState } from "react"

const Payment_methodList = () => {
  const navigate = useNavigate()
  const [payment_methodData, setPayment_method] = useState([])
  const [loading, setLoading] = useState(false)
  const [billingData, setBilling] = useState([])

  useEffect(() => {
    setLoading(true)
    fetchPayment_methodList()
    fetchBillingList()
  }, [])

  const fetchBillingList = async () => {
    try {
      const billing = await paymentMethodService.getBillingList()
      setBilling(billing)
    } catch (error) {
      console.log("Error fetching billing list:", error)
    }
  }

  const fetchPayment_methodList = async () => {
    try {
      const response = await paymentMethodService.getPayment_methodList()
      setPayment_method(response)
      setLoading(false)
    } catch (error) {
      console.log("Error fetching payment_method list:", error)
      setLoading(false)
    }
  }

  const handleDeletePayment_method = async (payment_methodId) => {
    try {
      setLoading(true)
      await paymentMethodService.deletePayment_method(payment_methodId)
      const data = payment_methodData.filter(
        (rows) => rows.id != payment_methodId
      )
      setPayment_method(data)
      setLoading(false)
    } catch (error) {
      console.log("Error deleting payment_method:", error)
      setLoading(false)
    }
  }

  const handleCreatePayment_method = () => {
    navigate("/payment_method/new")
  }

  const payment_methodColumns = [
    {
      name: "id",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "card_name",
      selector: (row) => row.card_name,
      sortable: true,
    },
    {
      name: "card_number",
      selector: (row) => row.card_number,
      sortable: true,
    },
    {
      name: "expiry_date",
      selector: (row) => row.expiry_date,
      sortable: true,
    },
    {
      name: "cvv",
      selector: (row) => row.cvv,
      sortable: true,
    },
    {
      name: "billing",
      selector: (row) => {
        const billing = billingData.find(
          (billing) => billing.id === row.billing
        )
        return billing ? billing.email : ""
      },
      sortable: true,
      cell: (row) => {
        const billing = billingData.find(
          (billing) => billing.id === row.billing
        )
        return billing ? billing.email : ""
      },
    },
    {
      name: "actions",
      cell: (row) => (
        <div className="text-end w-100">
          <Dropdown>
            <Dropdown.Toggle
              size="sm"
              as={CustomDropdownToggle}
              className="btn btn-sm btn-icon btn-zoom me-n1"
            >
              <Icon name="more-v" />
            </Dropdown.Toggle>
            <Dropdown.Menu
              className="dropdown-menu-sm"
              as={CustomDropdownMenu}
              align="end"
            >
              <div className="dropdown-content py-1">
                <LinkList className="link-list-hover-bg-primary link-list-md">
                  <Link
                    to={`/payment_method/edit/${row.id}`}
                    className="dropdown-item"
                  >
                    <Icon name="edit" />
                    <span>Edit</span>
                  </Link>
                  <button
                    className="dropdown-item"
                    onClick={() => handleDeletePayment_method(row.id)}
                  >
                    <Icon name="trash" />
                    <span>Delete</span>
                  </button>
                </LinkList>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ),
      sortable: false,
    },
  ]

  return (
    <Layout title="Payment_method List" content="container">
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            <Block.Title tag="h2">Payment_method List</Block.Title>
            <nav>
              <ol className="breadcrumb breadcrumb-arrow mb-0">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Payment_method
                </li>
              </ol>
            </nav>
          </Block.HeadContent>
          <Block.HeadContent>
            <ul className="d-flex">
              <li>
                <Button
                  className="d-md-none"
                  size="md"
                  variant="primary"
                  onClick={handleCreatePayment_method}
                >
                  <Icon name="plus" />
                  <span>Add</span>
                </Button>
              </li>
              <li>
                <Button
                  className="d-none d-md-inline-flex"
                  variant="primary"
                  onClick={handleCreatePayment_method}
                >
                  <Icon name="plus" />
                  <span>Add Payment_method</span>
                </Button>
              </li>
            </ul>
          </Block.HeadContent>
        </Block.HeadBetween>
      </Block.Head>

      <Block>
        <Card>
          {!loading ? (
            <DataTable
              tableClassName="data-table-head-light table-responsive"
              data={payment_methodData}
              columns={payment_methodColumns}
              expandableRows
              actions
            />
          ) : (
            <p>Loading...</p>
          )}
        </Card>
      </Block>
    </Layout>
  )
}

export default Payment_methodList
