import axios from "axios"

class LanguageService {
  api
  constructor() {
    this.api = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}/language`,
    })
    this.api.interceptors.request.use((config) => {
      const token = localStorage.getItem("Token")

      if (token) {
        config.headers = {
          Authorization: `Token ${token}`,
        }
      }

      return config
    })
  }
  getLanguageList = async () => {
    try {
      const response = await this.api.get(`/`)
      return response.data
    } catch (error) {
      throw error
    }
  }

  getLanguageDetails = async (languageId) => {
    try {
      const response = await this.api.get(`/${languageId}`)
      return response.data
    } catch (error) {
      throw error
    }
  }

  createLanguage = async (languageData) => {
    try {
      const response = await this.api.post(`/`, languageData)
      return response.data
    } catch (error) {
      throw error
    }
  }

  updateLanguage = async (languageId, languageData) => {
    try {
      const response = await this.api.put(`/${languageId}`, languageData)
      return response.data
    } catch (error) {
      throw error
    }
  }

  deleteLanguage = async (languageId) => {
    try {
      const response = await this.api.delete(`/${languageId}`)
      return response.data
    } catch (error) {
      throw error
    }
  }
}

const languageService = new LanguageService()

export default languageService
