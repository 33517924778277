import { useState, useEffect } from "react"
import { Card, Button, Row, Col, Form } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import userService from "../services/userService"
import Layout from "../../../layout/default"
import Block from "../../../components/Block/Block"

function UserForm() {
  const navigate = useNavigate()
  const { id } = useParams()
  const [loading, setLoading] = useState(false)
  const [initialLoading, setInitialLoading] = useState(false)
  const [userData, setUserData] = useState({
    name: "",
    last_name: "",
    is_active: true,
    role: "admin",
  })

  useEffect(() => {
    if (id) {
      fetchUser()
    }
  }, [id])

  const fetchUser = async () => {
    try {
      setInitialLoading(true)
      const user = await userService.getUserDetails(id)
      setUserData(user)
    } catch (error) {
      console.log("Error fetching user:", error)
    } finally {
      setInitialLoading(false)
    }
  }

  const handleChange = (event) => {
    const { name, value, type, files } = event.target
    if (type === "file") {
      setUserData({
        ...userData,
        [name]: files[0],
      })
    } else {
      setUserData({
        ...userData,
        [name]: value,
      })
    }
  }

  const handleCreateOrUpdateUser = async (event) => {
    event.preventDefault()

    const uploadData = new FormData()
    Object.entries(userData).forEach(([key, value]) => {
      uploadData.append(key, value)
    })

    try {
      setLoading(true)
      if (id) {
        await userService.updateUser(id, uploadData)
      } else {
        await userService.createUser(uploadData)
      }
      navigate("/user")
    } catch (error) {
      console.log("Error creating/updating user:", error)
    } finally {
      setLoading(false)
    }
  }

  const pageTitle = id ? "Edit User" : "Añadir admin User"
  const submitButtonText = id ? "Actualizar" : "Guardar"

  return (
    <Layout title={pageTitle} content="container" loading={initialLoading}>
      <Block>
        <Card className="card-gutter-md">
          <Card.Body>
            <div className="bio-block">
              <h4 className="bio-block-title mb-4">{pageTitle}</h4>
              <Form onSubmit={handleCreateOrUpdateUser}>
                <Row className="g-3">
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="name">name</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="name"
                          placeholder="name"
                          name="name"
                          value={userData.name}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="last_name">last_name</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="last_name"
                          placeholder="last_name"
                          name="last_name"
                          value={userData.last_name}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="password">password</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="password"
                          placeholder="password"
                          name="password"
                          value={userData.password}
                          onChange={handleChange}
                          disabled={id ? true : false}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="email">email</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="email"
                          placeholder="email"
                          name="email"
                          value={userData.email}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="role">phone_number</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="phone_number"
                          placeholder="phone_number"
                          name="phone_number"
                          value={userData.phone_number}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="role">stripe_customer_id</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="stripe_customer_id"
                          placeholder="stripe_customer_id"
                          name="stripe_customer_id"
                          value={userData.stripe_customer_id}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="role">Role</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Select
                          id="role"
                          name="role"
                          value={userData.role}
                          onChange={handleChange}
                          className="custom-select"
                        >
                          {id ? <option value="lawyer">Lawyer</option> : null}
                          <option value="admin">Admin</option>
                        </Form.Select>
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="image">image</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="file"
                          id="image"
                          name="image"
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>

                  <Col lg="12">
                    <Button variant="primary" type="submit">
                      {loading ? "loading..." : submitButtonText}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Card.Body>
        </Card>
      </Block>
    </Layout>
  )
}

export default UserForm
