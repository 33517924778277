import React, { useLayoutEffect } from "react"

import AppRoot from "../global/AppRoot"
import AppMain from "../global/AppMain"
import AppWrap from "../global/AppWrap"
import AppContent from "../global/AppContent"

import Header from "./Header"
import Sidebar from "./Sidebar"
import Footer from "./Footer"
import LayoutProvider from "./LayoutProvider"
import { Spin } from "antd"

function Default({ title, content, loading = false, ...props }) {
  useLayoutEffect(() => {
    document.title = `${title} - Abogueo CRM`
  })

  return (
    <LayoutProvider>
      <AppRoot>
        <AppMain>
          <Sidebar />
          <AppWrap>
            <Header />
            <AppContent content={content}>
              <Spin spinning={loading} size="large" style={{ marginTop: "10%" }}>
                {props.children}
              </Spin>
            </AppContent>
            <Footer />
          </AppWrap>
        </AppMain>
      </AppRoot>
    </LayoutProvider>
  )
}

export default Default
