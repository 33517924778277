import React, { useEffect } from "react"
import classNames from "classnames"
import slideUp from "../../../utilities/slideUp"
import slideDown from "../../../utilities/slideDown"
import getParents from "../../../utilities/getParents"

import { NavLink, Link } from "react-router-dom"
import { ROUTES } from "../../../constants/appRoutes"

function MenuHeading({ className, text, ...props }) {
  const compClass = classNames({
    "nk-menu-heading": true,
    [className]: className,
  })
  return (
    <li className={compClass}>
      <h6 className="overline-title">{text || props.children}</h6>
    </li>
  )
}

function MenuItemTemplate({ text, icon }) {
  return (
    <>
      {icon && (
        <span className="nk-menu-icon">
          <i className={`icon ni ni-${icon}`}></i>
        </span>
      )}
      {text && <span className="nk-menu-text">{text}</span>}
    </>
  )
}

function MenuItemLink({ text, icon, sub, to, blank, onClick }) {
  return (
    <>
      {!blank && !sub && (
        <NavLink className="nk-menu-link" to={to}>
          <MenuItemTemplate icon={icon} text={text} />
        </NavLink>
      )}
      {blank && (
        <Link className="nk-menu-link" to={to} target="_blank">
          <MenuItemTemplate icon={icon} text={text} />
        </Link>
      )}
      {sub && (
        <a
          className="nk-menu-link nk-menu-toggle"
          onClick={onClick}
          href="#expand"
        >
          <MenuItemTemplate icon={icon} text={text} />
        </a>
      )}
    </>
  )
}

function MenuItem({ sub, className, ...props }) {
  const compClass = classNames({
    "nk-menu-item": true,
    "has-sub": sub,
    [className]: className,
  })
  return <li className={compClass}>{props.children}</li>
}

function MenuSub({ mega, className, ...props }) {
  const compClass = classNames({
    "nk-menu-sub": true,
    [className]: className,
  })
  return <ul className={compClass}>{props.children}</ul>
}

function MenuList({ className, ...props }) {
  const compClass = classNames({
    "nk-menu": true,
    [className]: className,
  })
  return <ul className={compClass}>{props.children}</ul>
}

function Menu() {
  // variables for Sidebar
  let menu = {
    classes: {
      main: "nk-menu",
      item: "nk-menu-item",
      link: "nk-menu-link",
      toggle: "nk-menu-toggle",
      sub: "nk-menu-sub",
      subparent: "has-sub",
      active: "active",
      current: "current-page",
    },
  }

  let currentLink = function (selector) {
    let elm = document.querySelectorAll(selector)
    elm.forEach(function (item) {
      var activeRouterLink = item.classList.contains("active")
      if (activeRouterLink) {
        let parents = getParents(
          item,
          `.${menu.classes.main}`,
          menu.classes.item
        )
        parents.forEach((parentElemets) => {
          parentElemets.classList.add(menu.classes.active, menu.classes.current)
          let subItem = parentElemets.querySelector(`.${menu.classes.sub}`)
          subItem !== null && (subItem.style.display = "block")
        })
      } else {
        item.parentElement.classList.remove(
          menu.classes.active,
          menu.classes.current
        )
      }
    })
  }

  // dropdown toggle
  let dropdownToggle = function (elm) {
    let parent = elm.parentElement
    let nextelm = elm.nextElementSibling
    let speed =
      nextelm.children.length > 5 ? 400 + nextelm.children.length * 10 : 400
    if (!parent.classList.contains(menu.classes.active)) {
      parent.classList.add(menu.classes.active)
      slideDown(nextelm, speed)
    } else {
      parent.classList.remove(menu.classes.active)
      slideUp(nextelm, speed)
    }
  }

  // dropdown close siblings
  let closeSiblings = function (elm) {
    let parent = elm.parentElement
    let siblings = parent.parentElement.children
    Array.from(siblings).forEach((item) => {
      if (item !== parent) {
        item.classList.remove(menu.classes.active)
        if (item.classList.contains(menu.classes.subparent)) {
          let subitem = item.querySelectorAll(`.${menu.classes.sub}`)
          subitem.forEach((child) => {
            child.parentElement.classList.remove(menu.classes.active)
            slideUp(child, 400)
          })
        }
      }
    })
  }

  let menuToggle = function (e) {
    e.preventDefault()
    let item = e.target.closest(`.${menu.classes.toggle}`)
    dropdownToggle(item)
    closeSiblings(item)
  }

  useEffect(() => {
    currentLink(`.${menu.classes.link}`)
    // eslint-disable-next-line
  }, [null])

  return (
    <MenuList>

      <MenuItem>
        <MenuItemLink icon="dashboard" text="Dashboard" to="/home" />
      </MenuItem>

      <MenuHeading text="Utilidades" />
      <MenuItem>
        <MenuItemLink
          icon="line-chart"
          text="Google Analytics"
          to={ROUTES.analytics}
          blank
        />
        <MenuItemLink
          icon="briefcase"
          text="Alta Abogado"
          to={ROUTES.abogueoRegister}
          blank
        />
        <MenuItemLink
          icon="wallet"
          text="Clientes Stripe"
          to={ROUTES.stripeCustomers}
          blank
        />
      </MenuItem>
      <MenuHeading text="Gestión" />

      <MenuItem>
        <MenuItemLink icon="user" text="Usuarios" to="/user" />
      </MenuItem>

      <MenuItem>
        <MenuItemLink icon="briefcase" text="Abogados" to="/lawyer" />
      </MenuItem>

      <MenuItem>
        <MenuItemLink icon="users" text="Leads" to="/lead" />
      </MenuItem>

      <MenuItem>
        <MenuItemLink icon="users" text="Asignación" to="/lead-assignment" />
      </MenuItem>

      <MenuItem>
        <MenuItemLink icon="list" text="Campañas" to="/campaign" />
      </MenuItem>

      <MenuItem>
        <MenuItemLink icon="list" text="Campañas personalizadas" to="/campaign-custom" />
      </MenuItem>

      <MenuItem>
        <MenuItemLink
          icon="bar-chart"
          text="Campañas agregadas"
          to="/aggregate/campaign"
        />
      </MenuItem>

      <MenuItem>
        <MenuItemLink icon="coins" text="CPLs" to="/cpl" />
      </MenuItem>

      <MenuHeading text="Otros" />

      <MenuItem sub>
        <MenuItemLink icon="search" text="Lead data" onClick={menuToggle} sub />
        <MenuSub>
          <MenuItem>
            <MenuItemLink icon="clipboard" text="Lead State" to="/lead_state" />
          </MenuItem>
        </MenuSub>
      </MenuItem>

      <MenuItem sub>
        <MenuItemLink
          icon="shield-check"
          text="Lawyer data"
          onClick={menuToggle}
          sub
        />
        <MenuSub>
          <MenuItem>
            <MenuItemLink
              icon="briefcase"
              text="Lawyer School"
              to="/lawyer_school"
            />
          </MenuItem>

          <MenuItem>
            <MenuItemLink
              icon="briefcase"
              text="Lawyer Campaign"
              to="/lawyer_campaign"
            />
          </MenuItem>

          <MenuItem>
            <MenuItemLink
              icon="globe"
              text="Lawyer Languages"
              to="/lawyer_languages"
            />
          </MenuItem>

          <MenuItem>
            <MenuItemLink
              icon="list"
              text="Lawyer Specialty"
              to="/lawyer_specialty"
            />
          </MenuItem>
        </MenuSub>
      </MenuItem>

      {/* <MenuItem sub>
        <MenuItemLink
          icon="wallet"
          text="Billing data"
          onClick={menuToggle}
          sub
        />
        <MenuSub>
          <MenuItem>
            <MenuItemLink icon="wallet" text="Billing" to="/billing" />
          </MenuItem>

          <MenuItem>
            <MenuItemLink
              icon="coins"
              text="Payment Method"
              to="/payment_method"
            />
          </MenuItem>
          
          <MenuItem>
            <MenuItemLink icon="money" text="invoice" to="/invoice" />
          </MenuItem>
 
        </MenuSub>
      </MenuItem> */}

      <MenuItem sub>
        <MenuItemLink
          icon="bell"
          text="Notification"
          onClick={menuToggle}
          sub
        />
        <MenuSub>
          <MenuItem>
            <MenuItemLink icon="bell" text="Notification" to="/notification" />
          </MenuItem>

          <MenuItem>
            <MenuItemLink
              icon="setting"
              text="Notification Settings"
              to="/notification_settings"
            />
          </MenuItem>
        </MenuSub>
      </MenuItem>

      <MenuItem sub>
        <MenuItemLink icon="map" text="Location" onClick={menuToggle} sub />
        <MenuSub>
          <MenuItem>
            <MenuItemLink icon="map" text="Location" to="/location" />
          </MenuItem>

          <MenuItem>
            <MenuItemLink icon="map" text="Province" to="/province" />
          </MenuItem>
        </MenuSub>
      </MenuItem>

      <MenuItem sub>
        <MenuItemLink icon="list" text="Specialty" onClick={menuToggle} sub />
        <MenuSub>
          <MenuItem>
            <MenuItemLink icon="list" text="Specialty" to="/specialty" />
          </MenuItem>

          <MenuItem>
            <MenuItemLink
              icon="list"
              text="Main Specialty"
              to="/main_specialty"
            />
          </MenuItem>
        </MenuSub>
      </MenuItem>

      <MenuItem sub>
        <MenuItemLink icon="star" text="Plans" onClick={menuToggle} sub />
        <MenuSub>
          <MenuItem>
            <MenuItemLink icon="calendar" text="Posts Plan" to="/posts_plan" />
          </MenuItem>

          <MenuItem>
            <MenuItemLink
              icon="calendar"
              text="Posts Choices"
              to="/posts_choices"
            />
          </MenuItem>

          <MenuItem>
            <MenuItemLink icon="star" text="VIP Plan" to="/vip_plan" />
          </MenuItem>
        </MenuSub>
      </MenuItem>

      <MenuItem>
        <MenuItemLink icon="globe" text="Language" to="/language" />
      </MenuItem>
      <br></br>
      <br></br>
      <br></br>
    </MenuList>
  )
}

export default Menu
