import { useState, useEffect } from "react"
import { Card, Button, Row, Col, Form } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import DatePicker from "../../../components/Form/DatePicker"
import paymentMethodService from "../services/payment_methodService"
import Layout from "../../../layout/default"
import Block from "../../../components/Block/Block"

function Payment_methodForm() {
  const navigate = useNavigate()
  const { id } = useParams()

  const [payment_methodData, setPayment_methodData] = useState({
    card_name: "",
    card_number: "",
    expiry_date: "",
    cvv: "",
  })
  const [BillingList, setBillingList] = useState([])

  useEffect(() => {
    if (id) {
      fetchPayment_method()
    }
    fetchBilling()
  }, [id])

  const fetchPayment_method = async () => {
    try {
      const payment_method =
        await paymentMethodService.getPayment_methodDetails(id)
      setPayment_methodData(payment_method)
    } catch (error) {
      console.log("Error fetching payment_method:", error)
    }
  }

  const handleChange = (event) => {
    setPayment_methodData({
      ...payment_methodData,
      [event.target.name]: event.target.value,
    })
  }

  const handleCreateOrUpdatePayment_method = async (event) => {
    event.preventDefault()

    try {
      if (id) {
        await paymentMethodService.updatePayment_method(id, payment_methodData)
      } else {
        await paymentMethodService.createPayment_method(payment_methodData)
      }
      navigate("/payment_method")
    } catch (error) {
      console.log("Error creating/updating payment_method:", error)
    }
  }

  const fetchBilling = async () => {
    try {
      const dataBilling = await paymentMethodService.getBillingList()
      setBillingList(dataBilling)
    } catch (error) {
      console.error("Error fetching Billing:", error)
    }
  }

  const pageTitle = id ? "Edit Payment_method" : "Add Payment_method"
  const submitButtonText = id ? "Update" : "Save"

  return (
    <Layout title={pageTitle} content="container">
      <Block>
        <Card className="card-gutter-md">
          <Card.Body>
            <div className="bio-block">
              <h4 className="bio-block-title mb-4">{pageTitle}</h4>
              <Form onSubmit={handleCreateOrUpdatePayment_method}>
                <Row className="g-3">
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="card_name">card_name</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="card_name"
                          placeholder="card_name"
                          name="card_name"
                          value={payment_methodData.card_name}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="card_number">card_number</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="card_number"
                          placeholder="card_number"
                          name="card_number"
                          value={payment_methodData.card_number}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="expiry_date">expiry_date</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="date"
                          name="expiry_date"
                          placeholder="expiry_date"
                          value={payment_methodData.expiry_date}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="cvv">cvv</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="cvv"
                          placeholder="cvv"
                          name="cvv"
                          value={payment_methodData.cvv}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="Billing">Billing</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Select
                          id="billing"
                          name="billing"
                          value={payment_methodData.billing}
                          onChange={handleChange}
                          className="custom-select"
                        >
                          <option value="">Select Billing</option>
                          {BillingList?.map((billing, idx) => {
                            return (
                              <option key={idx} value={billing.id}>
                                {billing.email}
                              </option>
                            )
                          })}
                        </Form.Select>
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Button variant="primary" type="submit">
                      {submitButtonText}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Card.Body>
        </Card>
      </Block>
    </Layout>
  )
}

export default Payment_methodForm
