import axios from "axios"

class LawyerCampaignService {
  api
  constructor() {
    this.api = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}`,
    })
    this.api.interceptors.request.use((config) => {
      const token = localStorage.getItem("Token")

      if (token) {
        config.headers = {
          Authorization: `Token ${token}`,
          withCredentials: false,
          "Content-Type": "application/json; charset=UTF-8",
        }
      }

      return config
    })
  }
  getLawyer_campaignList = async () => {
    try {
      const response = await this.api.get(`/lawyer-campaign/`)
      return response.data
    } catch (error) {
      throw error
    }
  }

  getLawyer_campaignDetails = async (lawyer_campaignId) => {
    try {
      const response = await this.api.get(
        `/lawyer-campaign/${lawyer_campaignId}/`
      )
      return response.data
    } catch (error) {
      throw error
    }
  }

  createLawyer_campaign = async (lawyer_campaignData) => {
    try {
      const response = await this.api.post(
        `/lawyer-campaign/`,
        lawyer_campaignData
      )
      return response.data
    } catch (error) {
      throw error
    }
  }

  updateLawyer_campaign = async (lawyer_campaignId, lawyer_campaignData) => {
    try {
      const response = await this.api.put(
        `/lawyer-campaign/${lawyer_campaignId}/`,
        lawyer_campaignData
      )
      return response.data
    } catch (error) {
      throw error
    }
  }

  deleteLawyer_campaign = async (lawyer_campaignId) => {
    try {
      const response = await this.api.delete(
        `/lawyer-campaign/${lawyer_campaignId}/`
      )
      return response.data
    } catch (error) {
      throw error
    }
  }

  getLawyerList = async () => {
    try {
      const response = await this.api.get("/lawyer/")
      return response.data
    } catch (error) {
      throw error
    }
  }
}

const lawyerCampaignService = new LawyerCampaignService()

export default lawyerCampaignService
