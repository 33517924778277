import { useState, useEffect } from "react"
import { Card, Button, Row, Col, Form } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import lawyerService from "../services/lawyerService"
import Layout from "../../../layout/default"
import Block from "../../../components/Block/Block"
import leadService from "../../lead/services/leadService"
import { Typeahead } from "react-bootstrap-typeahead"
import locationService from "../../location/services/locationService"

function LawyerForm() {
  const navigate = useNavigate()
  const { id } = useParams()

  const [lawyerData, setLawyerData] = useState({
    name: "",
    last_name: "",
    phone_number: "",
    email: "",
    image: "",
    business_direcction: "",
    business_name: "",
    lawyer_school_code: "",
    about_me: "",
    complete_later: false,
    default_directory_visibility: false,
    directory_visibility_featured: false,
    directory_visibility_priority: 0,
    is_active: true,
    website: "https://abogueo.com/",
  })
  const [Vip_planList, setVip_planList] = useState([])
  const [UserList, setUserList] = useState([])
  const [Lawyer_schoolList, setLawyer_schoolList] = useState([])
  const [LocationList, setLocationList] = useState([])
  const [Posts_planList, setPosts_planList] = useState([])
  const [loading, setLoading] = useState(false)
  const [initialLoading, setInitialLoading] = useState(false)
  const [selectedLocalityList, setSelectedLocalityList] = useState([])
  const [localityList, setLocalityList] = useState([])

  useEffect(() => {
    fetchAll()
  }, [id])

  const fetchAll = async () => {
    try {
      setInitialLoading(true)
      await Promise.all([
        id && fetchLawyer(),
        fetchVip_plan(),
        fetchUserList(),
        fetchLawyer_schoolList(),
        fetchPosts_planList(),
      ])
    } catch (error) {
      console.log("Error fetching lawyer:", error)
    } finally {
      setInitialLoading(false)
    }
  }

  const fetchLawyer = async () => {
    try {
      const lawyer = await lawyerService.getLawyerDetails(id)
      if (!lawyer.website) {
        lawyer.website = "https://abogueo.com/"
      }
      const locationList = await locationService.getLocationDetails(
        lawyer.locality
      )

      setSelectedLocalityList([locationList])
      setLocationList({
        ...LocationList,
        ["locality"]: locationList?.length ? locationList[0].id : null,
      })
      setLawyerData(lawyer)
    } catch (error) {
      console.log("Error fetching lawyer:", error)
    }
  }

  const handleChange = (event) => {
    const { name, value, type, files } = event.target
    if (type === "file") {
      setLawyerData({
        ...lawyerData,
        [name]: files[0],
      })
    } else {
      setLawyerData({
        ...lawyerData,
        [name]: value,
      })
    }
  }

  const handleCheck = (event) => {
    const { name, checked } = event.target
    setLawyerData((prevState) => {
      const newState = {
        ...prevState,
        [name]: checked,
      }
      if (name === "default_directory_visibility" && !checked) {
        // Reset other fields when default_directory_visibility is unchecked
        newState.directory_visibility_featured = false
        newState.directory_visibility_priority = 0 // Reset to default
      }
      return newState
    })
  }

  const handleCreateOrUpdateLawyer = async (event) => {
    event.preventDefault()
    const excludedKeys = ["lawyer_reviews", "lawyer_reviews"]
    const formData = new FormData()
    Object.entries(lawyerData).forEach(([key, value]) => {
      if (!key.includes(excludedKeys) && value !== null && value !== "") {
        formData.append(key, value)
      }
    })

    try {
      setLoading(true)
      if (id) {
        await lawyerService.updateLawyer(id, formData)
      } else {
        await lawyerService.createLawyer(formData)
      }
      navigate("/lawyer")
    } catch (error) {
      console.log("Error creating/updating lawyer:", error)
    } finally {
      setLoading(false)
    }
  }

  const fetchVip_plan = async () => {
    try {
      const dataVip_plan = await lawyerService.getVip_planList()
      setVip_planList(dataVip_plan)
    } catch (error) {
      console.error("Error fetching Vip_plan:", error)
    }
  }
  const fetchUserList = async () => {
    try {
      const response = await lawyerService.getUserList()
      setUserList(response)
    } catch (error) {
      console.error("Error fetching Vip_plan:", error)
    }
  }
  const fetchLawyer_schoolList = async () => {
    try {
      const response = await lawyerService.getLawyer_schoolList()
      setLawyer_schoolList(response)
    } catch (error) {
      console.error("Error fetching Vip_plan:", error)
    }
  }

  const fetchPosts_planList = async () => {
    try {
      const response = await lawyerService.getPosts_planList()
      setPosts_planList(response)
    } catch (error) {
      console.error("Error fetching Vip_plan:", error)
    }
  }

  const filterLocation = async (inputText) => {
    try {
      // If there's no input, maybe you want to reset the list or handle it differently
      if (!inputText?.trim()) {
        // Here, you can reset the localityList to its default state if needed
        // setLocalityList(defaultList);
        return
      }

      const { data } = await leadService.filterLocality(inputText)
      setLocalityList(data)
      return data
    } catch (error) {
      console.log("Error fetching location list:", error)
      return []
    }
  }

  const pageTitle = id ? "Editar Abogado" : "Add Lawyer"
  const submitButtonText = id ? "Actualizar" : "Guardar"

  return (
    <Layout title={pageTitle} content="container" loading={initialLoading}>
      <Block>
        <Card className="card-gutter-md">
          <Card.Body>
            <div className="bio-block">
              <h4 className="bio-block-title mb-4">{pageTitle}</h4>
              <Form onSubmit={handleCreateOrUpdateLawyer}>
                <Row className="g-3">
                  <Col xs="12" sm="6" md="6" lg="6">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="name">name</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="name"
                          placeholder="name"
                          name="name"
                          value={lawyerData.name}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col xs="12" sm="6" md="6" lg="6">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="last_name">last_name</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="last_name"
                          placeholder="last_name"
                          name="last_name"
                          value={lawyerData.last_name}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col xs="12" sm="6" md="6" lg="6">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="phone_number">
                        phone_number
                      </Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="phone_number"
                          placeholder="phone_number"
                          name="phone_number"
                          value={lawyerData.phone_number}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col xs="12" sm="6" md="6" lg="6">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="email">email</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="email"
                          placeholder="email"
                          name="email"
                          value={lawyerData.email}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col xs="12" sm="6" md="6" lg="6">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="image">image</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="file"
                          id="image"
                          name="image"
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>

                  <Col xs="12" sm="6" md="6" lg="6">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="business_direction">
                        business_direction
                      </Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="business_direction"
                          placeholder="business_direction"
                          name="business_direction"
                          value={lawyerData.business_direction}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col xs="12" sm="6" md="6" lg="6">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="business_name">
                        business_name
                      </Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="business_name"
                          placeholder="business_name"
                          name="business_name"
                          value={lawyerData.business_name}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col xs="12" sm="6" md="6" lg="6">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="lawyer_school_code">
                        lawyer_school_code
                      </Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="lawyer_school_code"
                          placeholder="lawyer_school_code"
                          name="lawyer_school_code"
                          value={lawyerData.lawyer_school_code}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col xs="12" sm="6" md="6" lg="6">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="about_me">about_me</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="about_me"
                          placeholder="about_me"
                          name="about_me"
                          value={lawyerData.about_me}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>

                  <Col xs="12" sm="6" md="6" lg="6">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="User">User</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Select
                          id="user"
                          name="user"
                          value={lawyerData.user}
                          onChange={handleChange}
                          className="custom-select"
                        >
                          <option value="">Select User</option>
                          {UserList?.map((user, idx) => {
                            return (
                              <option key={idx} value={user.id}>
                                {user.email}
                              </option>
                            )
                          })}
                        </Form.Select>
                      </div>
                    </Form.Group>
                  </Col>

                  <Col xs="12" sm="6" md="6" lg="6">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="Lawyer_school">
                        Lawyer_school
                      </Form.Label>
                      <div className="form-control-wrap">
                        <Form.Select
                          id="lawyer_school"
                          name="lawyer_school"
                          value={lawyerData.lawyer_school}
                          onChange={handleChange}
                          className="custom-select"
                        >
                          <option value="">Select Lawyer_school</option>
                          {Lawyer_schoolList?.map((lawyer_school, idx) => {
                            return (
                              <option key={idx} value={lawyer_school.id}>
                                {lawyer_school.name}
                              </option>
                            )
                          })}
                        </Form.Select>
                      </div>
                    </Form.Group>
                  </Col>

                  <Col xs="12" sm="6" md="6" lg="6">
                    <Form.Group className="mb-3" controlId="locality">
                      <Form.Label htmlFor="locality">Localidad</Form.Label>
                      <Typeahead
                        id="locality"
                        options={localityList}
                        labelKey="locality"
                        placeholder="Selecciona ubicación"
                        selected={
                          selectedLocalityList?.length
                            ? selectedLocalityList
                            : []
                        }
                        onChange={(selected) => {
                          setSelectedLocalityList(selected)
                          setLocationList({
                            ...LocationList,
                            ["locality"]: selected?.length
                              ? selected[0].id
                              : null,
                          })
                        }}
                        onInputChange={(text) => {
                          filterLocation(text)
                        }}
                        emptyLabel="Ubicación no encontrada"
                      />
                    </Form.Group>
                  </Col>

                  <Col xs="12" sm="6" md="6" lg="6">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="Posts_plan">
                        Artículos patrocinados
                      </Form.Label>
                      <div className="form-control-wrap">
                        <Form.Select
                          id="posts_plan"
                          name="posts_plan"
                          value={lawyerData.posts_plan}
                          onChange={handleChange}
                          className="custom-select"
                        >
                          <option value="">Select plan</option>
                          {Posts_planList?.map((posts_plan, idx) => {
                            return (
                              <option key={idx} value={posts_plan.id}>
                                {posts_plan.name}
                              </option>
                            )
                          })}
                        </Form.Select>
                      </div>
                    </Form.Group>
                  </Col>

                  {/* Presencia en directorio */}
                  <Col xs="12" sm="6" md="6" lg="6">
                    <Form.Group className="form-group">
                      <Form.Label>Presencia en directorio</Form.Label>
                      <Form.Check
                        type="checkbox"
                        id="default_directory_visibility"
                        name="default_directory_visibility"
                        checked={lawyerData.default_directory_visibility}
                        label="Presencia básica en directorio"
                        onChange={handleCheck}
                      />
                    </Form.Group>
                  </Col>

                  {/* Conditional rendering of additional options */}
                  {lawyerData.default_directory_visibility && (
                    <>
                      {/* Checkbox for directory_visibility_featured */}
                      <Col xs="12" sm="6" md="6" lg="6">
                        <Form.Group className="form-group">
                        <Form.Label htmlFor="directory_visibility_priority">
                            Prioridad en directorio
                          </Form.Label>
                          <Form.Check
                            type="checkbox"
                            id="directory_visibility_featured"
                            name="directory_visibility_featured"
                            checked={lawyerData.directory_visibility_featured}
                            label="Destacado en directorio"
                            onChange={handleCheck}
                          />
                        </Form.Group>
                      </Col>

                      {/* Select input for directory_visibility_priority */}
                      <Col xs="12" sm="6" md="6" lg="6">
                        <Form.Group className="form-group">
                          <Form.Label htmlFor="directory_visibility_priority">
                            Posicionamiento en directorio
                          </Form.Label>
                          <Form.Control
                            as="select"
                            id="directory_visibility_priority"
                            name="directory_visibility_priority"
                            value={lawyerData.directory_visibility_priority}
                            onChange={handleChange}
                          >
                            <option value={0}>Selecciona Posicionamiento</option>
                            <option value={1}>ALTO</option>
                            <option value={2}>BAJO</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    </>
                  )}

                  {/* ...other form fields... */}

                  <Col xs="12" sm="6" md="6" lg="6">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="geo_location_lat">
                        geo_location_lat
                      </Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="number"
                          id="geo_location_lat"
                          placeholder="geo_location_lat"
                          name="geo_location_lat"
                          value={lawyerData.geo_location_lat}
                          onChange={handleChange}
                          readOnly
                        />
                      </div>
                    </Form.Group>
                  </Col>

                  <Col xs="12" sm="6" md="6" lg="6">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="geo_location_long">
                        geo_location_long
                      </Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="number"
                          id="geo_location_long"
                          placeholder="geo_location_long"
                          name="geo_location_long"
                          value={lawyerData.geo_location_long}
                          onChange={handleChange}
                          readOnly
                        />
                      </div>
                    </Form.Group>
                  </Col>

                  <Col xs="12" sm="6" md="6" lg="6">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="website">website</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="website"
                          placeholder="website"
                          name="website"
                          value={lawyerData.website}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>

                  <Col xs="12" sm="6" md="6" lg="6">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="geo_location_long">
                        optimization_level
                      </Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="number"
                          id="optimization_level"
                          placeholder="optimization_level"
                          name="optimization_level"
                          value={lawyerData.optimization_level}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>

                  <Col xs="12" sm="6" md="6" lg="6">
                    <Form.Group className="form-group">
                      <Form.Check
                        type="checkbox"
                        id="complete_later"
                        name="complete_later"
                        checked={lawyerData.complete_later}
                        label="complete_later"
                        onChange={handleCheck}
                      />
                    </Form.Group>
                  </Col>

                  <Col xs="12" sm="12" md="12" lg="12">
                    <Button variant="primary" type="submit">
                      {loading ? "Cargando..." : submitButtonText}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Card.Body>
        </Card>
      </Block>
    </Layout>
  )
}

export default LawyerForm
