import { Card, Button, Dropdown, Badge } from "react-bootstrap"
import { Link, useNavigate } from "react-router-dom"
import {
  Icon,
  CustomDropdownToggle,
  CustomDropdownMenu,
  LinkList,
} from "../../../components/index.js"
import Layout from "../../../layout/default/index.js"
import Block from "../../../components/Block/Block.js"
import DataTable from "../../../components/DataTable/DataTable.js"
import React, { useEffect, useState } from "react"
import campaignCustomService from "../services/campaignCustomService.js"

const CampaignCustomList = () => {
  const navigate = useNavigate()
  const [campaignData, setCampaign] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    fetchCampaignList()
  }, [])

  const fetchCampaignList = async () => {
    try {
      setLoading(true)
      const response = await campaignCustomService.getCampaignListCrm()
      setCampaign(response)
    } catch (error) {
      console.log("Error fetching campaign list:", error)
    } finally {
      setLoading(false)
    }
  }

  const handleDeleteCampaign = async (campaignId) => {
    try {
      setLoading(true)
      await campaignCustomService.deleteCampaign(campaignId)
      const data = campaignData.filter((rows) => rows.id != campaignId)
      setCampaign(data)
      setLoading(false)
    } catch (error) {
      console.log("Error deleting campaign:", error)
      setLoading(false)
    }
  }

  const handleCreateCampaign = () => {
    navigate("/campaign-custom/new")
  }

  const campaignColumns = [
    {
      name: "id",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Abogado",
      selector: (row) => row?.lawyer?.business_name,
      sortable: true,
      cell: (row) => row?.lawyer?.business_name,
    },
    {
      name: "ID Abogado",
      selector: (row) => row?.lawyer?.id,
      sortable: true,
      cell: (row) => row?.lawyer?.id,
    },
    {
      name: "specialty",
      selector: (row) =>
        row?.specialties?.map(specialty => specialty.name).join(", "),
      sortable: true,
      cell: (row) =>
        row?.specialties?.map(specialty => specialty.name).join(", "),
    },
    {
      name: "province",
      selector: (row) =>
        row?.province?.length > 0
          ? row.province.map(province => province.name).join(", ")
          : "Todo España",
      sortable: true,
      cell: (row) =>
        row?.province?.length > 0
          ? row.province.map(province => province.name).join(", ")
          : "Todo España",
    },
    {
      name:"Link de pago",
      selector: (row) => row.payment_link,
      sortable: true,
      cell: (row) => <a href={row.payment_link} target="_blank" rel="noopener noreferrer">
      Enlace de pago
    </a>,
    },

    {
      name: "Leads estimados",
      selector: (row) => row?.lead_amount,
      sortable: true,
      cell: (row) => row?.lead_amount,
    },
    {
      name: "Leads asignados",
      selector: (row) => row?.assigned_leads,
      sortable: true,
      cell: (row) => row?.assigned_leads,
    },
    {
      name: "Leads pendientes",
      selector: (row) => row?.remaining_leads,
      sortable: true,
      cell: (row) => row?.remaining_leads,
    },
    {
      name: "Presupuesto mensual",
      selector: (row) => row?.total_price,
      sortable: true,
      cell: (row) => row?.total_price,
    },
    {
      name: "Presupuesto consumido",
      selector: (row) => row?.consumed_budget,
      sortable: true,
      cell: (row) => row?.consumed_budget,
    },
    {
      name: "Presupuesto disponible",
      selector: (row) => row?.remaining_budget,
      sortable: true,
      cell: (row) => row?.remaining_budget,
    },
    {
      name: "Inicio",
      selector: (row) => row.start_date,
      sortable: true,
    },
    {
      name: "actions",
      cell: (row) => (
        <div className="text-end w-100">
          <Dropdown>
            <Dropdown.Toggle
              size="sm"
              as={CustomDropdownToggle}
              className="btn btn-sm btn-icon btn-zoom me-n1"
            >
              <Icon name="more-v" />
            </Dropdown.Toggle>
            <Dropdown.Menu
              className="dropdown-menu-sm"
              as={CustomDropdownMenu}
              align="end"
            >
              <div className="dropdown-content py-1">
                <LinkList className="link-list-hover-bg-primary link-list-md">
                  <Link
                    to={`/campaign-custom/edit/${row.id}`}
                    className="dropdown-item"
                  >
                    <Icon name="edit" />
                    <span>Edit</span>
                  </Link>
                  <button
                    className="dropdown-item"
                    onClick={() => handleDeleteCampaign(row.id)}
                  >
                    <Icon name="trash" />
                    <span>Delete</span>
                  </button>
                </LinkList>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ),
      sortable: false,
    },
  ]

  return (
    <Layout title="Campañas personalizadas" content="container" loading={loading}>
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            <Block.Title tag="h2">Campañas personalizadas</Block.Title>
            <nav>
              <ol className="breadcrumb breadcrumb-arrow mb-0">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Campañas personalizadas
                </li>
              </ol>
            </nav>
          </Block.HeadContent>
          <Block.HeadContent>
            <ul className="d-flex">
              <li>
                <Button
                  className="d-md-none"
                  size="md"
                  variant="primary"
                  onClick={handleCreateCampaign}
                >
                  <Icon name="plus" />
                  <span>Añadir</span>
                </Button>
              </li>
              <li>
                <Button
                  className="d-none d-md-inline-flex"
                  variant="primary"
                  onClick={handleCreateCampaign}
                >
                  <Icon name="plus" />
                  <span>Añadir Campaña</span>
                </Button>
              </li>
            </ul>
          </Block.HeadContent>
        </Block.HeadBetween>
      </Block.Head>

      <Block>
        <Card>
          {!loading && (
            <DataTable
              tableClassName="data-table-head-light table-responsive"
              data={campaignData}
              columns={campaignColumns}
              expandableRows
              actions
              searchByList={[
                "id",
                ["specialty", "child_specialty", []],
                ["province", "name", []],
                ["lawyer", "business_name", []],
              ]}
            />
          )}
        </Card>
      </Block>
    </Layout>
  )
}

export default CampaignCustomList
