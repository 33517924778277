import { useState, useEffect } from "react"
import { Card, Button, Row, Col, Form } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import DatePicker from "../../../components/Form/DatePicker"
import languageService from "../services/languageService"
import Layout from "../../../layout/default"
import Block from "../../../components/Block/Block"

function LanguageForm() {
  const navigate = useNavigate()
  const { id } = useParams()

  const [languageData, setLanguageData] = useState({
    name: "",
    lastname: "",
  })

  useEffect(() => {
    if (id) {
      fetchLanguage()
    }
  }, [id])

  const fetchLanguage = async () => {
    try {
      const language = await languageService.getLanguageDetails(id)
      setLanguageData(language)
    } catch (error) {
      console.log("Error fetching language:", error)
    }
  }

  const handleChange = (event) => {
    setLanguageData({
      ...languageData,
      [event.target.name]: event.target.value,
    })
  }

  const handleCreateOrUpdateLanguage = async (event) => {
    event.preventDefault()

    try {
      if (id) {
        await languageService.updateLanguage(id, languageData)
      } else {
        await languageService.createLanguage(languageData)
      }
      navigate("/language")
    } catch (error) {
      console.log("Error creating/updating language:", error)
    }
  }

  const pageTitle = id ? "Edit Language" : "Add Language"
  const submitButtonText = id ? "Update" : "Save"

  return (
    <Layout title={pageTitle} content="container">
      <Block>
        <Card className="card-gutter-md">
          <Card.Body>
            <div className="bio-block">
              <h4 className="bio-block-title mb-4">{pageTitle}</h4>
              <Form onSubmit={handleCreateOrUpdateLanguage}>
                <Row className="g-3">
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="name">name</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="name"
                          placeholder="name"
                          name="name"
                          value={languageData.name}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Button variant="primary" type="submit">
                      {submitButtonText}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Card.Body>
        </Card>
      </Block>
    </Layout>
  )
}

export default LanguageForm
