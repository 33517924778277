import { useState, useEffect } from "react"
import { Card, Button, Row, Col, Form } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import DatePicker from "../../../components/Form/DatePicker"
import locationService from "../services/locationService"
import Layout from "../../../layout/default"
import Block from "../../../components/Block/Block"
import provinceService from "../../province/services/provinceService"

function LocationForm() {
  const navigate = useNavigate()
  const { id } = useParams()
  const [locationData, setLocationData] = useState()
  const [provinceList, setProvinceList] = useState([])

  useEffect(() => {
    if (id) {
      fetchLocation()
    }
    fetchProvinces()
  }, [id])

  const fetchLocation = async () => {
    try {
      const location = await locationService.getLocationDetails(id)
      setLocationData(location)
    } catch (error) {
      console.error("Error fetching location:", error)
    }
  }

  const fetchProvinces = async () => {
    try {
      const location = await provinceService.getProvinceList()
      setProvinceList(location)
    } catch (error) {
      console.error("Error fetching provinces:", error)
    }
  }

  const handleChange = (event) => {
    setLocationData({
      ...locationData,
      [event.target.name]: event.target.value,
    })
  }

  const handleCreateOrUpdateLocation = async (event) => {
    event.preventDefault()

    try {
      if (id) {
        await locationService.updateLocation(id, locationData)
      } else {
        await locationService.createLocation(locationData)
      }
      navigate("/location")
    } catch (error) {
      console.log("Error creating/updating location:", error)
    }
  }

  const pageTitle = id ? "Edit Location" : "Add Location"
  const submitButtonText = id ? "Update" : "Save"

  return (
    <Layout title={pageTitle} content="container">
      <Block>
        <Card className="card-gutter-md">
          <Card.Body>
            <div className="bio-block">
              <h4 className="bio-block-title mb-4">{pageTitle}</h4>
              <Form onSubmit={handleCreateOrUpdateLocation}>
                <Row className="g-3">
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="province">Province</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Select
                          id="province"
                          name="province"
                          value={locationData?.province}
                          onChange={handleChange}
                        >
                          <option value="">Select Province</option>
                          {provinceList?.map((province, idx) => {
                            return (
                              <option key={idx} value={province.id}>
                                {province.name}
                              </option>
                            )
                          })}
                        </Form.Select>
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="locality">locality</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="locality"
                          placeholder="locality"
                          name="locality"
                          value={locationData?.locality}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="zip_code">zip_code</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="zip_code"
                          placeholder="zip_code"
                          name="zip_code"
                          value={locationData?.zip_code}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Button variant="primary" type="submit">
                      {submitButtonText}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Card.Body>
        </Card>
      </Block>
    </Layout>
  )
}

export default LocationForm
