import { useState, useEffect } from "react"
import { Card, Button, Row, Col, Form } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import DatePicker from "../../../components/Form/DatePicker"
import invoiceService from "../services/invoiceService"
import Layout from "../../../layout/default"
import Block from "../../../components/Block/Block"

function InvoiceForm() {
  const navigate = useNavigate()
  const { id } = useParams()

  const [invoiceData, setInvoiceData] = useState({
    invoices_number: "",
    date: "",
    state: "",
  })
  const [UserList, setUserList] = useState([])

  useEffect(() => {
    if (id) {
      fetchInvoice()
    }
    fetchUser()
  }, [id])

  const fetchInvoice = async () => {
    try {
      const invoice = await invoiceService.getInvoiceDetails(id)
      setInvoiceData(invoice)
    } catch (error) {
      console.log("Error fetching invoice:", error)
    }
  }

  const handleChange = (event) => {
    setInvoiceData({ ...invoiceData, [event.target.name]: event.target.value })
  }

  const handleCreateOrUpdateInvoice = async (event) => {
    event.preventDefault()

    try {
      if (id) {
        await invoiceService.updateInvoice(id, invoiceData)
      } else {
        await invoiceService.createInvoice(invoiceData)
      }
      navigate("/invoice")
    } catch (error) {
      console.log("Error creating/updating invoice:", error)
    }
  }

  const fetchUser = async () => {
    try {
      const dataUser = await invoiceService.getUserList()
      setUserList(dataUser)
    } catch (error) {
      console.error("Error fetching User:", error)
    }
  }

  const pageTitle = id ? "Edit Invoice" : "Add Invoice"
  const submitButtonText = id ? "Update" : "Save"

  return (
    <Layout title={pageTitle} content="container">
      <Block>
        <Card className="card-gutter-md">
          <Card.Body>
            <div className="bio-block">
              <h4 className="bio-block-title mb-4">{pageTitle}</h4>
              <Form onSubmit={handleCreateOrUpdateInvoice}>
                <Row className="g-3">
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="invoices_number">
                        invoices_number
                      </Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="invoices_number"
                          placeholder="invoices_number"
                          name="invoices_number"
                          value={invoiceData.invoices_number}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="date">date</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="date"
                          name="date"
                          placeholder="date"
                          value={invoiceData.date}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="state">state</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="state"
                          placeholder="state"
                          name="state"
                          value={invoiceData.state}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="User">User</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Select
                          id="owner"
                          name="owner"
                          value={invoiceData.owner}
                          onChange={handleChange}
                          className="custom-select"
                        >
                          <option value="">Select User</option>
                          {UserList?.map((user, idx) => {
                            return (
                              <option key={idx} value={user.id}>
                                {user.email}
                              </option>
                            )
                          })}
                        </Form.Select>
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Button variant="primary" type="submit">
                      {submitButtonText}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Card.Body>
        </Card>
      </Block>
    </Layout>
  )
}

export default InvoiceForm
