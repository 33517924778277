import { useState, useEffect } from "react"
import { Card, Button, Row, Col, Form } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import notificationService from "../services/notificationService"
import Layout from "../../../layout/default"
import Block from "../../../components/Block/Block"

function NotificationForm() {
  const navigate = useNavigate()
  const { id } = useParams()

  const [notificationData, setNotificationData] = useState({
    message: "",
    read: false,
  })
  const [UserList, setUserList] = useState([])

  useEffect(() => {
    if (id) {
      fetchNotification()
    }
    fetchUser()
  }, [id])

  const fetchNotification = async () => {
    try {
      const notification = await notificationService.getNotificationDetails(id)
      setNotificationData(notification)
    } catch (error) {
      console.log("Error fetching notification:", error)
    }
  }

  const handleChange = (event) => {
    setNotificationData({
      ...notificationData,
      [event.target.name]: event.target.value,
    })
  }
  const checkChange = (event) => {
    setNotificationData({
      ...notificationData,
      [event.target.name]: event.target.checked,
    })
  }
  const handleCreateOrUpdateNotification = async (event) => {
    event.preventDefault()

    try {
      if (id) {
        await notificationService.updateNotification(id, notificationData)
      } else {
        await notificationService.createNotification(notificationData)
      }
      navigate("/notification")
    } catch (error) {
      console.log("Error creating/updating notification:", error)
    }
  }

  const fetchUser = async () => {
    try {
      const dataUser = await notificationService.getUserList()
      setUserList(dataUser)
    } catch (error) {
      console.error("Error fetching User:", error)
    }
  }

  const pageTitle = id ? "Edit Notification" : "Add Notification"
  const submitButtonText = id ? "Update" : "Save"

  return (
    <Layout title={pageTitle} content="container">
      <Block>
        <Card className="card-gutter-md">
          <Card.Body>
            <div className="bio-block">
              <h4 className="bio-block-title mb-4">{pageTitle}</h4>
              <Form onSubmit={handleCreateOrUpdateNotification}>
                <Row className="g-3">
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="message">message</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="message"
                          placeholder="message"
                          name="message"
                          value={notificationData.message}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Check
                        type="checkbox"
                        id="read"
                        name="read"
                        value={notificationData.read}
                        onChange={checkChange}
                        label="read"
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="User">User</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Select
                          id="user"
                          name="user"
                          value={notificationData.user}
                          onChange={handleChange}
                          className="custom-select"
                        >
                          <option value="">Select User</option>
                          {UserList?.map((user, idx) => {
                            return (
                              <option key={idx} value={user.id}>
                                {user.name}
                              </option>
                            )
                          })}
                        </Form.Select>
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Button variant="primary" type="submit">
                      {submitButtonText}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Card.Body>
        </Card>
      </Block>
    </Layout>
  )
}

export default NotificationForm
