import { useState, useEffect } from "react"
import { Card, Button, Row, Col, Form } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import lawyerSchoolService from "../services/lawyer_schoolService"
import Layout from "../../../layout/default"
import Block from "../../../components/Block/Block"

function Lawyer_schoolForm() {
  const navigate = useNavigate()
  const { id } = useParams()

  const [lawyer_schoolData, setLawyer_schoolData] = useState({
    name: "",
    lastname: "",
  })

  useEffect(() => {
    if (id) {
      fetchLawyer_school()
    }
  }, [id])

  const fetchLawyer_school = async () => {
    try {
      const lawyer_school = await lawyerSchoolService.getLawyer_schoolDetails(
        id
      )
      setLawyer_schoolData(lawyer_school)
    } catch (error) {
      console.log("Error fetching lawyer_school:", error)
    }
  }

  const handleChange = (event) => {
    setLawyer_schoolData({
      ...lawyer_schoolData,
      [event.target.name]: event.target.value,
    })
  }

  const handleCreateOrUpdateLawyer_school = async (event) => {
    event.preventDefault()

    try {
      if (id) {
        await lawyerSchoolService.updateLawyer_school(id, lawyer_schoolData)
      } else {
        await lawyerSchoolService.createLawyer_school(lawyer_schoolData)
      }
      navigate("/lawyer_school")
    } catch (error) {
      console.log("Error creating/updating lawyer_school:", error)
    }
  }

  const pageTitle = id ? "Edit Lawyer_school" : "Add Lawyer_school"
  const submitButtonText = id ? "Update" : "Save"

  return (
    <Layout title={pageTitle} content="container">
      <Block>
        <Card className="card-gutter-md">
          <Card.Body>
            <div className="bio-block">
              <h4 className="bio-block-title mb-4">{pageTitle}</h4>
              <Form onSubmit={handleCreateOrUpdateLawyer_school}>
                <Row className="g-3">
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="name">name</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="name"
                          placeholder="name"
                          name="name"
                          value={lawyer_schoolData.name}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="location">location</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="location"
                          placeholder="location"
                          name="location"
                          value={lawyer_schoolData.location}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="acronym">acronym</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="acronym"
                          placeholder="acronym"
                          name="acronym"
                          value={lawyer_schoolData.acronym}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Button variant="primary" type="submit">
                      {submitButtonText}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Card.Body>
        </Card>
      </Block>
    </Layout>
  )
}

export default Lawyer_schoolForm
