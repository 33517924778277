import React, { createContext, useState } from "react"
import { useEffect } from "react"
import authService from "../services/auth.service"

const AuthContext = createContext()
function AuthProviderWrapper(props) {
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [user, setUser] = useState(null)

  const storeToken = (token) => {
    localStorage.setItem("Token", token)
  }

  const removeToken = async () => {
    localStorage.removeItem("Token")
    
    if (user) refreshPage()
  }

  const getToken = () => {
    return localStorage.getItem("Token")
  }

  const refreshPage = () => {
    window.location.reload()
  }

  const authenticateUser = () => {
    const storedToken = getToken()

    if (!storedToken) {
      logOutUser()
    } else {
      authService
        .verify(storedToken)
        .then(({ data }) => {
          const user = data
          setIsLoggedIn(true)
          setIsLoading(false)
          setUser(user)
        })
        .catch(() => logOutUser())
    }
  }

  const logOutUser = () => {
    removeToken()
    setIsLoggedIn(false)
    setIsLoading(false)
    setUser(null)
  }
  useEffect(() => {
    authenticateUser()
  }, [])

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        setIsLoggedIn,
        isLoading,
        user,
        storeToken,
        authenticateUser,
        logOutUser,
        getToken,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  )
}

export { AuthContext, AuthProviderWrapper }
