import { Card, Button, Dropdown } from "react-bootstrap"
import { Link, useNavigate } from "react-router-dom"
import {
  Icon,
  CustomDropdownToggle,
  CustomDropdownMenu,
  LinkList,
} from "../../../components"
import Layout from "../../../layout/default"
import Block from "../../../components/Block/Block"
import DataTable from "../../../components/DataTable/DataTable.js"
import React, { useEffect, useState } from "react"
import languageService from "../services/languageService"

const LanguageList = () => {
  const navigate = useNavigate()
  const [languageData, setLanguage] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    fetchLanguageList()
  }, [])

  const fetchLanguageList = async () => {
    try {
      const response = await languageService.getLanguageList()
      setLanguage(response)
      setLoading(false)
    } catch (error) {
      console.log("Error fetching language list:", error)
      setLoading(false)
    }
  }

  const handleDeleteLanguage = async (languageId) => {
    try {
      setLoading(true)
      await languageService.deleteLanguage(languageId)
      const data = languageData.filter((rows) => rows.id != languageId)
      setLanguage(data)
      setLoading(false)
    } catch (error) {
      console.log("Error deleting language:", error)
      setLoading(false)
    }
  }

  const handleCreateLanguage = () => {
    navigate("/language/new")
  }

  const languageColumns = [
    {
      name: "id",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "actions",
      cell: (row) => (
        <div className="text-end w-100">
          <Dropdown>
            <Dropdown.Toggle
              size="sm"
              as={CustomDropdownToggle}
              className="btn btn-sm btn-icon btn-zoom me-n1"
            >
              <Icon name="more-v" />
            </Dropdown.Toggle>
            <Dropdown.Menu
              className="dropdown-menu-sm"
              as={CustomDropdownMenu}
              align="end"
            >
              <div className="dropdown-content py-1">
                <LinkList className="link-list-hover-bg-primary link-list-md">
                  <Link
                    to={`/language/edit/${row.id}`}
                    className="dropdown-item"
                  >
                    <Icon name="edit" />
                    <span>Edit</span>
                  </Link>
                  <button
                    className="dropdown-item"
                    onClick={() => handleDeleteLanguage(row.id)}
                  >
                    <Icon name="trash" />
                    <span>Delete</span>
                  </button>
                </LinkList>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ),
      sortable: false,
    },
  ]

  return (
    <Layout title="Language List" content="container">
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            <Block.Title tag="h2">Language List</Block.Title>
            <nav>
              <ol className="breadcrumb breadcrumb-arrow mb-0">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Language
                </li>
              </ol>
            </nav>
          </Block.HeadContent>
          <Block.HeadContent>
            <ul className="d-flex">
              <li>
                <Button
                  className="d-md-none"
                  size="md"
                  variant="primary"
                  onClick={handleCreateLanguage}
                >
                  <Icon name="plus" />
                  <span>Add</span>
                </Button>
              </li>
              <li>
                <Button
                  className="d-none d-md-inline-flex"
                  variant="primary"
                  onClick={handleCreateLanguage}
                >
                  <Icon name="plus" />
                  <span>Add Language</span>
                </Button>
              </li>
            </ul>
          </Block.HeadContent>
        </Block.HeadBetween>
      </Block.Head>

      <Block>
        <Card>
          {!loading ? (
            <DataTable
              tableClassName="data-table-head-light table-responsive"
              data={languageData}
              columns={languageColumns}
              expandableRows
              actions
            />
          ) : (
            <p>Loading...</p>
          )}
        </Card>
      </Block>
    </Layout>
  )
}

export default LanguageList
