import { useState, useEffect } from "react"
import { Card, Button, Row, Col, Form } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import mainSpecialtyService from "../services/main_specialtyService"
import Layout from "../../../layout/default"
import Block from "../../../components/Block/Block"

function Main_specialtyForm() {
  const navigate = useNavigate()
  const { id } = useParams()

  const [main_specialtyData, setMain_specialtyData] = useState({
    name: "",
    lastname: "",
  })

  useEffect(() => {
    if (id) {
      fetchMain_specialty()
    }
  }, [id])

  const fetchMain_specialty = async () => {
    try {
      const main_specialty = await mainSpecialtyService.getMain_specialtyDetails(id)
      setMain_specialtyData(main_specialty)
    } catch (error) {
      console.log("Error fetching main_specialty:", error)
    }
  }

  const handleChange = (event) => {
    setMain_specialtyData({
      ...main_specialtyData,
      [event.target.name]: event.target.value,
    })
  }

  const handleCreateOrUpdateMain_specialty = async (event) => {
    event.preventDefault()

    try {
      if (id) {
        await mainSpecialtyService.updateMain_specialty(id, main_specialtyData)
      } else {
        await mainSpecialtyService.createMain_specialty(main_specialtyData)
      }
      navigate("/main_specialty")
    } catch (error) {
      console.log("Error creating/updating main_specialty:", error)
    }
  }

  const pageTitle = id ? "Edit Main_specialty" : "Add Main_specialty"
  const submitButtonText = id ? "Update" : "Save"

  return (
    <Layout title={pageTitle} content="container">
      <Block>
        <Card className="card-gutter-md">
          <Card.Body>
            <div className="bio-block">
              <h4 className="bio-block-title mb-4">{pageTitle}</h4>
              <Form onSubmit={handleCreateOrUpdateMain_specialty}>
                <Row className="g-3">
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="name">name</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="name"
                          placeholder="name"
                          name="name"
                          value={main_specialtyData.name}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Button variant="primary" type="submit">
                      {submitButtonText}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Card.Body>
        </Card>
      </Block>
    </Layout>
  )
}

export default Main_specialtyForm
