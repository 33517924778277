import { useState, useEffect } from "react"
import { Button, Row, Col, Form, Modal } from "react-bootstrap"
import campaignService from "../../campaign/services/campaignService"
import lawyerService from "../../lawyer/services/lawyerService"
import lead_assignmentService from "../../Lead_assignment/services/Lead_assignmentService"
import { message, Spin } from "antd"

function LeadAssignmentForm({ show, handleClose, lead, updateLead }) {
  const [loading, setLoading] = useState(false)
  const [initialLoading, setInitialLoading] = useState(false)
  const [leadData, setLeadData] = useState({
    lead: lead ? lead.id : null,
    lawyer: null,
    assignment_order: 1,
    campaign: null,
  })
  const [campaign, setCampaign] = useState([])
  const [lawyer, setLawyer] = useState([])

  useEffect(() => {
    fetchAll()
  }, [])

  useEffect(() => {
    if (lead) {
      setLeadData((prevData) => ({ ...prevData, lead: lead.id }))
    }
  }, [lead])

  const fetchAll = async () => {
    try {
      setInitialLoading(true)
      await Promise.all([fetchCampaignList(), fetchLawyer()])
    } catch (error) {
      console.error("Error fetching lead list:", error)
    } finally {
      setInitialLoading(false)
    }
  }

  const fetchLawyer = async () => {
    const response = await lawyerService.getRealLawyerList()
    setLawyer(response)
  }

  const fetchCampaignList = async () => {
    const response = await campaignService.getCampaignList()
    setCampaign(response)
  }

  const handleChange = (event) => {
    setLeadData({ ...leadData, [event.target.name]: event.target.value })
  }

  const handleCreateOrUpdateLead = async (event) => {
    event.preventDefault()
    try {
      setLoading(true)
      const data = await lead_assignmentService.createLead_assignment(leadData)
      // modificar estado actual
      updateLead(leadData.lead, data.lawyer)
      handleClose() // Close the modal on success
    } catch (error) {
      console.log("Error creating/updating lead:", error)
      message.error("Error asignando lead", 4)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Asignar Lead</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Spin spinning={initialLoading} size="large">
          <Form onSubmit={handleCreateOrUpdateLead}>
            <Row className="g-3">
              <Col lg="12">
                <Form.Group className="form-group">
                  <Form.Label htmlFor="lead">Lead</Form.Label>
                  <div className="form-control-wrap">
                    <Form.Control
                      type="text"
                      id="lead"
                      name="lead"
                      value={lead?.name || ""}
                      readOnly
                      className="custom-select"
                    />
                  </div>
                </Form.Group>
              </Col>

              <Col lg="12">
                <Form.Group className="form-group">
                  <Form.Label htmlFor="lawyer">Lawyer</Form.Label>
                  <div className="form-control-wrap">
                    <Form.Select
                      id="lawyer"
                      name="lawyer"
                      value={leadData.lawyer}
                      onChange={handleChange}
                      className="custom-select"
                    >
                      <option value="">Select lawyer</option>
                      {lawyer?.map((l, idx) => (
                        <option key={idx} value={l.id}>
                          {l.id} | {l.name} | {l.last_name} | {l.email}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                </Form.Group>
              </Col>

              <Col lg="12">
                <Form.Group className="form-group">
                  <Form.Label htmlFor="assignment_order">
                    Assignment Order
                  </Form.Label>
                  <div className="form-control-wrap">
                    <Form.Control
                      type="number"
                      id="assignment_order"
                      name="assignment_order"
                      value={leadData.assignment_order}
                      onChange={handleChange}
                    />
                  </div>
                </Form.Group>
              </Col>

              <Col lg="12">
                <Form.Group className="form-group">
                  <Form.Label htmlFor="campaign">
                    Campaign (Opcional)
                  </Form.Label>
                  <div className="form-control-wrap">
                    <Form.Select
                      id="campaign"
                      name="campaign"
                      value={leadData.campaign}
                      onChange={handleChange}
                      className="custom-select"
                    >
                      <option value="">Select campaign</option>
                      {campaign?.map((camp, idx) => (
                        <option key={idx} value={camp.id}>
                          {camp.specialty?.child_specialty} | {camp.province?.name || "Toda España"} |  Abogado:{" "}{camp.lawyer}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                </Form.Group>
              </Col>

              <Col lg="12">
                <Button variant="primary" type="submit">
                  {loading ? "loading..." : "Asignar"}
                </Button>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal.Body>
    </Modal>
  )
}

export default LeadAssignmentForm
