import axios from "axios"

class LawyerService {
  api
  constructor() {
    this.api = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}`,
    })
    this.api.interceptors.request.use((config) => {
      const token = localStorage.getItem("Token")

      if (token) {
        config.headers = {
          Authorization: `Token ${token}`,
        }
      }

      return config
    })
  }
  getLawyerList = async () => {
    try {
      const response = await this.api.get(`/lawyer/`)
      return response.data
    } catch (error) {
      throw error
    }
  }
  getRealLawyerList = async () => {
    try {
      const response = await this.api.get(`/lawyer/real-lawyer/`)
      return response.data
    } catch (error) {
      throw error
    }
  }

  getLawyerListPaginated = async (page = 1, filters = {}) => {
    try {
      let query = `page=${page}`

      // Add filters to the query
      if (filters.name) {
        query += `&name=${filters.name}`
      }
      if (filters.last_name) {
        query += `&last_name=${filters.last_name}`
      }
      if (filters.email) {
        query += `&email=${filters.email}`
      }
      if (filters.phone_number) {
        query += `&phone_number=${filters.phone_number}`
      }
      if (filters.nif) {
        query += `&nif=${filters.nif}`
      }
      if (filters.business_name) {
        query += `&business_name=${filters.business_name}`
      }

      const response = await this.api.get(`/directory/?${query}`)
      return response.data
    } catch (error) {
      throw error
    }
  }

  getLawyerDetails = async (lawyerId) => {
    try {
      const response = await this.api.get(`/lawyer/${lawyerId}/`)
      return response.data
    } catch (error) {
      throw error
    }
  }
  getAllLawyerDetails = async (lawyerId) => {
    try {
      const response = await this.api.get(`/lawyer/${lawyerId}/details/`)
      return response.data
    } catch (error) {
      throw error
    }
  }

  createLawyer = async (lawyerData) => {
    try {
      const response = await this.api.post(`/lawyer/`, lawyerData)
      return response.data
    } catch (error) {
      throw error
    }
  }

  updateLawyer = async (lawyerId, lawyerData) => {
    try {
      const response = await this.api.patch(`/lawyer/${lawyerId}/`, lawyerData)
      return response.data
    } catch (error) {
      throw error
    }
  }

  deleteLawyer = async (lawyerId) => {
    try {
      const response = await this.api.delete(`/lawyer/${lawyerId}/`)
      return response.data
    } catch (error) {
      throw error
    }
  }

  getUserList = async () => {
    try {
      const response = await this.api.get("/user/")
      return response.data
    } catch (error) {
      throw error
    }
  }

  getLawyer_schoolList = async () => {
    try {
      const response = await this.api.get("/lawyer-school/")
      return response.data
    } catch (error) {
      throw error
    }
  }

  getProvinceList = async () => {
    try {
      const response = await this.api.get("/province/")
      return response.data
    } catch (error) {
      throw error
    }
  }

  getLocationList = async () => {
    try {
      const response = await this.api.get("/location/")
      return response.data
    } catch (error) {
      throw error
    }
  }

  getPosts_planList = async () => {
    try {
      const response = await this.api.get("/posts-plan/")
      return response.data
    } catch (error) {
      throw error
    }
  }

  getVip_planList = async () => {
    try {
      const response = await this.api.get("/vip-plan/")
      return response.data
    } catch (error) {
      throw error
    }
  }

  getLawyerExcel = async () => {
    try {
      const response = await this.api.get("/lawyer-crm-excel/get-excel/", {
        responseType: "blob", // Make sure response is treated as binary
      })
      return response.data
    } catch (error) {
      throw error
    }
  }
}

const lawyerService = new LawyerService()

export default lawyerService
