import axios from "axios"

class LeadService {
  api
  constructor() {
    this.api = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}`,
    })
    this.api.interceptors.request.use((config) => {
      const token = localStorage.getItem("Token")

      if (token) {
        config.headers = {
          Authorization: `Token ${token}`,
          withCredentials: false,
          "Content-Type": "application/json; charset=UTF-8",
        }
      }

      return config
    })
  }

  getLeadList = async () => {
    try {
      const response = await this.api.get(`/lead/`)
      return response.data
    } catch (error) {
      throw error
    }
  }

  getLeadListPaginate = async (page = 1, filters = {}, limit = 0) => {
    try {
      let query = `page=${page}`

      // Añadir filtros a la query
      if (filters.name) {
        query += `&name=${filters.name}`
      }
      if (filters.last_name) {
        query += `&last_name=${filters.last_name}`
      }
      if (filters.email) {
        query += `&email=${filters.email}`
      }
      if (filters.phone_number) {
        query += `&phone_number=${filters.phone_number}`
      }
      if (limit > 0) {
        query += `&default_limit=${limit}`
      }

      const response = await this.api.get(`/lead/filter/?${query}`)
      return response.data
    } catch (error) {
      throw error
    }
  }
  getLeadListPaginateByLawyer = async (page = 1, filters = {}, limit = 0) => {
    try {
      let query = `page=${page}`

      // Añadir filtros a la query
      if (filters.lawyer) {
        query += `&lawyer=${filters.lawyer}`
      }
      if (filters.name) {
        query += `&name=${filters.name}`
      }
      if (filters.last_name) {
        query += `&last_name=${filters.last_name}`
      }
      if (filters.email) {
        query += `&email=${filters.email}`
      }
      if (filters.phone_number) {
        query += `&phone_number=${filters.phone_number}`
      }
      if (limit > 0) {
        query += `&default_limit=${limit}`
      }

      const response = await this.api.get(`/lead/filter-lawyer/?${query}`)
      return response.data
    } catch (error) {
      throw error
    }
  }

  getLeadDetails = async (leadId) => {
    try {
      const response = await this.api.get(`/lead/${leadId}/`)
      return response.data
    } catch (error) {
      throw error
    }
  }

  createLead = async (leadData) => {
    try {
      const response = await this.api.post(`/lead/`, leadData)
      return response.data
    } catch (error) {
      throw error
    }
  }

  updateLead = async (leadId, leadData) => {
    try {
      const response = await this.api.put(`/lead/${leadId}/`, leadData)
      return response.data
    } catch (error) {
      throw error
    }
  }

  deleteLead = async (leadId) => {
    try {
      const response = await this.api.delete(`/lead/${leadId}/`)
      return response.data
    } catch (error) {
      throw error
    }
  }

  exportLead = async (is_assigned) => {
    try {
      let url = `/lead/admin-excel/`
      if (is_assigned) {
        url = `/lead/admin-excel/?is_assigned=true`
      }
      return await this.api.get(url, { responseType: "blob" })
    } catch (error) {
      throw error
    }
  }

  importLead = async (formData) => {
    try {
      const token = localStorage.getItem("Token")
      return await axios.post(
        `${process.env.REACT_APP_API_URL}` + "/lead/import-excel/",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`,
            withCredentials: false,
          },
        }
      )
    } catch (error) {
      throw error
    }
  }

  getSpecialtyList = async () => {
    try {
      const response = await this.api.get("/specialty/")
      return response.data
    } catch (error) {
      throw error
    }
  }

  getLocationList = async () => {
    try {
      const response = await this.api.get("/location/")
      return response.data
    } catch (error) {
      throw error
    }
  }

  getProvinceList = async () => {
    try {
      const response = await this.api.get("/province/")
      return response.data
    } catch (error) {
      throw error
    }
  }

  getLead_stateList = async () => {
    try {
      const response = await this.api.get("/lead-state/")
      return response.data
    } catch (error) {
      throw error
    }
  }

  filterLocality = async (loc) => {
    try {
      return await this.api.get(`/location/filter_locality/?locality=${loc}`)
    } catch (error) {
      throw error
    }
  }
}

const leadService = new LeadService()

export default leadService
