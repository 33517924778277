import { Card, Button, Dropdown } from "react-bootstrap"
import { Link, useNavigate } from "react-router-dom"
import {
  Icon,
  CustomDropdownToggle,
  CustomDropdownMenu,
  LinkList,
} from "../../../components"
import Layout from "../../../layout/default"
import Block from "../../../components/Block/Block"
import DataTable from "../../../components/DataTable/DataTable.js"

import React, { useEffect, useState } from "react"
import lawyerCampaignService from "../services/lawyer_campaignService"

const Lawyer_campaignList = () => {
  const navigate = useNavigate()
  const [lawyer_campaignData, setLawyer_campaign] = useState([])
  const [loading, setLoading] = useState(false)
  const [lawyerData, setLawyer] = useState([])

  useEffect(() => {
    setLoading(true)
    fetchLawyer_campaignList()
    fetchLawyerList()
  }, [])

  const fetchLawyerList = async () => {
    try {
      const lawyer = await lawyerCampaignService.getLawyerList()
      setLawyer(lawyer)
    } catch (error) {
      console.log("Error fetching lawyer list:", error)
    }
  }

  const fetchLawyer_campaignList = async () => {
    try {
      const response = await lawyerCampaignService.getLawyer_campaignList()
      setLawyer_campaign(response)
      setLoading(false)
    } catch (error) {
      console.log("Error fetching lawyer_campaign list:", error)
      setLoading(false)
    }
  }

  const handleDeleteLawyer_campaign = async (lawyer_campaignId) => {
    try {
      setLoading(true)
      await lawyerCampaignService.deleteLawyer_campaign(lawyer_campaignId)
      const data = lawyer_campaignData.filter(
        (rows) => rows.id != lawyer_campaignId
      )
      setLawyer_campaign(data)
      setLoading(false)
    } catch (error) {
      console.log("Error deleting lawyer_campaign:", error)
      setLoading(false)
    }
  }

  const handleCreateLawyer_campaign = () => {
    navigate("/lawyer_campaign/new")
  }

  const lawyer_campaignColumns = [
    {
      name: "id",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "lawyer",
      selector: (row) => {
        const lawyer = lawyerData.find((lawyer) => lawyer.id === row.lawyer)
        return lawyer ? lawyer.email : ""
      },
      sortable: true,
      cell: (row) => {
        const lawyer = lawyerData.find((lawyer) => lawyer.id === row.lawyer)
        return lawyer ? lawyer.email : ""
      },
    },
    {
      name: "total",
      selector: (row) => row.total,
      sortable: true,
    },
    {
      name: "actions",
      cell: (row) => (
        <div className="text-end w-100">
          <Dropdown>
            <Dropdown.Toggle
              size="sm"
              as={CustomDropdownToggle}
              className="btn btn-sm btn-icon btn-zoom me-n1"
            >
              <Icon name="more-v" />
            </Dropdown.Toggle>
            <Dropdown.Menu
              className="dropdown-menu-sm"
              as={CustomDropdownMenu}
              align="end"
            >
              <div className="dropdown-content py-1">
                <LinkList className="link-list-hover-bg-primary link-list-md">
                  <Link
                    to={`/lawyer_campaign/edit/${row.id}`}
                    className="dropdown-item"
                  >
                    <Icon name="edit" />
                    <span>Edit</span>
                  </Link>
                  <button
                    className="dropdown-item"
                    onClick={() => handleDeleteLawyer_campaign(row.id)}
                  >
                    <Icon name="trash" />
                    <span>Delete</span>
                  </button>
                </LinkList>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ),
      sortable: false,
    },
  ]

  return (
    <Layout title="Lawyer_campaign List" content="container">
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            <Block.Title tag="h2">Lawyer_campaign List</Block.Title>
            <nav>
              <ol className="breadcrumb breadcrumb-arrow mb-0">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Lawyer_campaign
                </li>
              </ol>
            </nav>
          </Block.HeadContent>
          <Block.HeadContent>
            <ul className="d-flex">
              <li>
                <Button
                  className="d-md-none"
                  size="md"
                  variant="primary"
                  onClick={handleCreateLawyer_campaign}
                >
                  <Icon name="plus" />
                  <span>Add</span>
                </Button>
              </li>
              <li>
                <Button
                  className="d-none d-md-inline-flex"
                  variant="primary"
                  onClick={handleCreateLawyer_campaign}
                >
                  <Icon name="plus" />
                  <span>Add Lawyer_campaign</span>
                </Button>
              </li>
            </ul>
          </Block.HeadContent>
        </Block.HeadBetween>
      </Block.Head>

      <Block>
        <Card>
          {!loading ? (
            <DataTable
              tableClassName="data-table-head-light table-responsive"
              data={lawyer_campaignData}
              columns={lawyer_campaignColumns}
              expandableRows
              actions
            />
          ) : (
            <p>Loading...</p>
          )}
        </Card>
      </Block>
    </Layout>
  )
}

export default Lawyer_campaignList
