import axios from "axios"

class SpecialtyService {
  api
  constructor() {
    this.api = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}`,
    })
    this.api.interceptors.request.use((config) => {
      const token = localStorage.getItem("Token")

      if (token) {
        config.headers = {
          Authorization: `Token ${token}`,
          withCredentials: false,
          "Content-Type": "application/json; charset=UTF-8",
        }
      }

      return config
    })
  }

  getSpecialtyList = async () => {
    try {
      const response = await this.api.get(`/specialty/`)
      return response.data
    } catch (error) {
      throw error
    }
  }

  getSpecialtyDetails = async (specialtyId) => {
    try {
      const response = await this.api.get(`/specialty/${specialtyId}/`)
      return response.data
    } catch (error) {
      throw error
    }
  }

  createSpecialty = async (specialtyData) => {
    try {
      const response = await this.api.post(`/specialty/`, specialtyData)
      return response.data
    } catch (error) {
      throw error
    }
  }
  
  createSpecialtyCpl = async (specialtyData) => {
    try {
      const response = await this.api.post(`/specialty/create-cpl/`, specialtyData)
      return response.data
    } catch (error) {
      throw error
    }
  }
  updateSpecialty = async (specialtyId, specialtyData) => {
    try {
      const response = await this.api.put(
        `/specialty/${specialtyId}/`,
        specialtyData
      )
      return response.data
    } catch (error) {
      throw error
    }
  }

  deleteSpecialty = async (specialtyId) => {
    try {
      const response = await this.api.delete(`/specialty/${specialtyId}/`)
      return response.data
    } catch (error) {
      throw error
    }
  }

  getMain_specialtyList = async () => {
    try {
      const response = await this.api.get("/main-specialty/")
      return response.data
    } catch (error) {
      throw error
    }
  }
}

const specialtyService = new SpecialtyService()

export default specialtyService
