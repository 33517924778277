import { useState, useEffect } from "react"
import { Card, Button, Row, Col, Form } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import DatePicker from "../../../components/Form/DatePicker"
import billingService from "../services/billingService"
import Layout from "../../../layout/default"
import Block from "../../../components/Block/Block"

function BillingForm() {
  const navigate = useNavigate()
  const { id } = useParams()

  const [billingData, setBillingData] = useState({
    name: "",
    lastname: "",
  })

  useEffect(() => {
    if (id) {
      fetchBilling()
    }
  }, [id])

  const fetchBilling = async () => {
    try {
      const billing = await billingService.getBillingDetails(id)
      setBillingData(billing)
    } catch (error) {
      console.log("Error fetching billing:", error)
    }
  }

  const handleChange = (event) => {
    setBillingData({ ...billingData, [event.target.name]: event.target.value })
  }

  const handleCreateOrUpdateBilling = async (event) => {
    event.preventDefault()

    try {
      if (id) {
        await billingService.updateBilling(id, billingData)
      } else {
        await billingService.createBilling(billingData)
      }
      navigate("/billing")
    } catch (error) {
      console.log("Error creating/updating billing:", error)
    }
  }

  const pageTitle = id ? "Edit Billing" : "Add Billing"
  const submitButtonText = id ? "Update" : "Save"

  return (
    <Layout title={pageTitle} content="container">
      <Block>
        <Card className="card-gutter-md">
          <Card.Body>
            <div className="bio-block">
              <h4 className="bio-block-title mb-4">{pageTitle}</h4>
              <Form onSubmit={handleCreateOrUpdateBilling}>
                <Row className="g-3">
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="name">name</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="name"
                          placeholder="name"
                          name="name"
                          value={billingData.name}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="nif">nif</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="nif"
                          placeholder="nif"
                          name="nif"
                          value={billingData.nif}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="billing_direction">
                        billing_direction
                      </Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="billing_direction"
                          placeholder="billing_direction"
                          name="billing_direction"
                          value={billingData.billing_direction}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="zip_code">zip_code</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="zip_code"
                          placeholder="zip_code"
                          name="zip_code"
                          value={billingData.zip_code}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="email">email</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="email"
                          placeholder="email"
                          name="email"
                          value={billingData.email}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="phone_number">
                        phone_number
                      </Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="phone_number"
                          placeholder="phone_number"
                          name="phone_number"
                          value={billingData.phone_number}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Button variant="primary" type="submit">
                      {submitButtonText}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Card.Body>
        </Card>
      </Block>
    </Layout>
  )
}

export default BillingForm
