import axios from "axios"

class UserService {
  api
  constructor() {
    this.api = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}`,
    })
    this.api.interceptors.request.use((config) => {
      const token = localStorage.getItem("Token")

      if (token) {
        config.headers = {
          Authorization: `Token ${token}`,
        }
      }

      return config
    })
  }

  getUserList = async () => {
    try {
      const response = await this.api.get(`/user/`)
      return response.data
    } catch (error) {
      throw error
    }
  }

  getUserDetails = async (userId) => {
    try {
      const response = await this.api.get(`/user/${userId}/`)
      return response.data
    } catch (error) {
      throw error
    }
  }

  createUser = async (userData) => {
    try {
      const response = await this.api.post(`/user/`, userData)
      return response.data
    } catch (error) {
      throw error
    }
  }

  updateUser = async (userId, userData) => {
    try {
      const response = await this.api.put(`/user/${userId}/`, userData)
      return response.data
    } catch (error) {
      throw error
    }
  }

  deleteUser = async (userId) => {
    try {
      const response = await this.api.delete(`/user/${userId}/`)
      return response.data
    } catch (error) {
      throw error
    }
  }
  getBillingList = async () => {
    try {
      const response = await this.api.get("/billing/")
      return response.data
    } catch (error) {
      throw error
    }
  }
}

const userService = new UserService()

export default userService
