import { useState, useEffect } from "react"
import { Card, Button, Row, Col, Form } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import Layout from "../../../layout/default"
import Block from "../../../components/Block/Block"
import lead_assignmentService from "../services/Lead_assignmentService"
import campaignService from "../../campaign/services/campaignService"
import leadService from "../../lead/services/leadService"
import lawyerService from "../../lawyer/services/lawyerService"

function Lead_assignmentForm() {
  const [loading, setLoading] = useState(false)
  const [initialLoading, setInitialLoading] = useState(false)
  const navigate = useNavigate()
  const { id } = useParams()
  const [leadData, setLeadData] = useState({
    lead: null,
    lawyer: null,
    assignment_order: 1,
    campaign: null,
  })
  const [campaign, setCampaign] = useState([])
  const [lawyer, setLawyer] = useState([])
  const [LeadList, setLeadList] = useState([])

  useEffect(() => {
    if (id) {
      fetchLead()
    }

    fetchAll()
  }, [id])

  const fetchAll = async () => {
    try {
      setInitialLoading(true)
      await Promise.all([fetchCampaignList(), fetchLeadList(), fetchLawyer()])
    } catch (error) {
      console.error("Error fetching lead list:", error)
    } finally {
      setInitialLoading(false)
    }
  }
  const fetchLawyer = async () => {
    const response = await lawyerService.getRealLawyerList()
    setLawyer(response)
  }

  const fetchCampaignList = async () => {
    const specialty = await campaignService.getCampaignList()
    setCampaign(specialty)
  }

  const fetchLeadList = async () => {
    const response = await leadService.getLeadList()
    setLeadList(response)
  }

  const fetchLead = async () => {
    try {
      const lead = await lead_assignmentService.getLead_assignmentDetails(id)
      setLeadData(lead)
    } catch (error) {
      console.log("Error fetching lead:", error)
    }
  }

  const handleChange = (event) => {
    setLeadData({ ...leadData, [event.target.name]: event.target.value })
  }

  const handleCreateOrUpdateLead = async (event) => {
    event.preventDefault()

    try {
      setLoading(true)
      if (id) {
        await lead_assignmentService.updateLead_assignment(id, leadData)
      } else {
        await lead_assignmentService.createLead_assignment(leadData)
      }
      navigate("/lead-assignment")
    } catch (error) {
      console.log("Error creating/updating lead:", error)
    } finally {
      setLoading(false)
    }
  }

  const pageTitle = id ? "Editar Asignación" : "Añadir Asignación"
  const submitButtonText = id ? "Actualizar" : "Guardar"

  return (
    <Layout title={pageTitle} content="container" loading={initialLoading}>
      <Block>
        <Card className="card-gutter-md">
          <Card.Body>
            <div className="bio-block">
              <h4 className="bio-block-title mb-4">{pageTitle}</h4>

              <Form onSubmit={handleCreateOrUpdateLead}>
                <Row className="g-3">
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="lead">lead</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Select
                          id="lead"
                          name="lead"
                          value={leadData.lead}
                          onChange={handleChange}
                          className="custom-select"
                        >
                          <option value="">Select lead</option>
                          {LeadList?.map((l, idx) => {
                            return (
                              <option key={idx} value={l.id}>
                                {l.id}, {l.name}, {l.last_name}, {l.email}
                              </option>
                            )
                          })}
                        </Form.Select>
                      </div>
                    </Form.Group>
                  </Col>

                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="lawyer">lawyer</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Select
                          id="lawyer"
                          name="lawyer"
                          value={leadData.lawyer}
                          onChange={handleChange}
                          className="custom-select"
                        >
                          <option value="">Select lawyer</option>
                          {lawyer?.map((l, idx) => {
                            return (
                              <option key={idx} value={l.id}>
                                {l.id}, {l.name}, {l.last_name}, {l.email}
                              </option>
                            )
                          })}
                        </Form.Select>
                      </div>
                    </Form.Group>
                  </Col>

                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="email">assignment_order</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="number"
                          id="assignment_order"
                          placeholder="assignment_order"
                          name="assignment_order"
                          value={leadData.assignment_order}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>

                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="campaign">
                        campaign (Opcional)
                      </Form.Label>
                      <div className="form-control-wrap">
                        <Form.Select
                          id="campaign"
                          name="campaign"
                          value={leadData.campaign}
                          onChange={handleChange}
                          className="custom-select"
                        >
                          <option value="">Select campaign</option>
                          {campaign?.map((camp, idx) => {
                            return (
                              <option key={idx} value={camp?.id}>
                                {camp?.id}, {camp?.specialty?.child_specialty},{" "}
                                {camp?.province?.name
                                  ? camp.province.name
                                  : "toda España"}
                                , Abogado: <strong>{camp?.lawyer}</strong>
                              </option>
                            )
                          })}
                        </Form.Select>
                      </div>
                    </Form.Group>
                  </Col>

                  <Col lg="12">
                    <Button variant="primary" type="submit">
                      {loading ? "loading..." : submitButtonText}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Card.Body>
        </Card>
      </Block>
    </Layout>
  )
}

export default Lead_assignmentForm
