import { useState, useEffect } from "react"
import { Card, Button, Row, Col, Form } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import lawyerCampaignService from "../services/lawyer_campaignService"
import Layout from "../../../layout/default"
import Block from "../../../components/Block/Block"

function Lawyer_campaignForm() {
  const navigate = useNavigate()
  const { id } = useParams()

  const [lawyer_campaignData, setLawyer_campaignData] = useState({
    total: "",
  })
  const [LawyerList, setLawyerList] = useState([])

  useEffect(() => {
    if (id) {
      fetchLawyer_campaign()
    }
    fetchLawyer()
  }, [id])

  const fetchLawyer_campaign = async () => {
    try {
      const lawyer_campaign =
        await lawyerCampaignService.getLawyer_campaignDetails(id)
      setLawyer_campaignData(lawyer_campaign)
    } catch (error) {
      console.log("Error fetching lawyer_campaign:", error)
    }
  }

  const handleChange = (event) => {
    setLawyer_campaignData({
      ...lawyer_campaignData,
      [event.target.name]: event.target.value,
    })
  }

  const handleCreateOrUpdateLawyer_campaign = async (event) => {
    event.preventDefault()

    try {
      if (id) {
        await lawyerCampaignService.updateLawyer_campaign(
          id,
          lawyer_campaignData
        )
      } else {
        await lawyerCampaignService.createLawyer_campaign(lawyer_campaignData)
      }
      navigate("/lawyer_campaign")
    } catch (error) {
      console.log("Error creating/updating lawyer_campaign:", error)
    }
  }

  const fetchLawyer = async () => {
    try {
      const dataLawyer = await lawyerCampaignService.getLawyerList()
      setLawyerList(dataLawyer)
    } catch (error) {
      console.error("Error fetching Lawyer:", error)
    }
  }

  const pageTitle = id ? "Edit Lawyer_campaign" : "Add Lawyer_campaign"
  const submitButtonText = id ? "Update" : "Save"

  return (
    <Layout title={pageTitle} content="container">
      <Block>
        <Card className="card-gutter-md">
          <Card.Body>
            <div className="bio-block">
              <h4 className="bio-block-title mb-4">{pageTitle}</h4>
              <Form onSubmit={handleCreateOrUpdateLawyer_campaign}>
                <Row className="g-3">
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="Lawyer">Lawyer</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Select
                          id="lawyer"
                          name="lawyer"
                          value={lawyer_campaignData.lawyer}
                          onChange={handleChange}
                          className="custom-select"
                        >
                          <option value="">Select Lawyer</option>
                          {LawyerList?.map((lawyer, idx) => {
                            return (
                              <option key={idx} value={lawyer.id}>
                                {lawyer.email}
                              </option>
                            )
                          })}
                        </Form.Select>
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="total">total</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="total"
                          placeholder="total"
                          name="total"
                          value={lawyer_campaignData.total}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Button variant="primary" type="submit">
                      {submitButtonText}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Card.Body>
        </Card>
      </Block>
    </Layout>
  )
}

export default Lawyer_campaignForm
